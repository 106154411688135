import React, {useEffect, useRef, useState} from 'react';
import EmojiPicker from "emoji-picker-react";
import useOnClickOutside from "../../../../Hooks/useOnClickOutside";
import {useFilePicker} from "use-file-picker";
import {useMutation, useQueryClient} from "react-query";
import axiosClient from "../../../../Api/apiClient";
import CustomSpinner from "../../../../Utility/CustomSpinner";
import {useDropzone} from "react-dropzone";
import useUploadAttachment from "../../../../Hooks/useUploadAttachment";
import ReplayRectangle from "./ReplayRectangle";
import EditRectangle from "./EditRectangle";
import s from "./style.module.scss"
import classNames from "classnames";
import {uploadSingleFile} from "./uploadSingleFile";
const ChatInputBox = ({threadId,editContent,setEditContnet,setReplayContent,replayContent}) => {
    const text_input = useRef();
    const emojiPicker = useRef();
    const [textBoxValue,setTextBoxValue]=useState("")

    const updateValue =(e)=>{
        setTextBoxValue(e.target.innerText)
    }
    const [openFileSelector] = useFilePicker({
        accept: ['.json', '.pdf'],
    });

    const emojyAdd = (e)=>{
        let sym = e.unified.split("-");
        let codesArray = [];
        sym.forEach((el) => codesArray.push("0x" + el));
        let emoji = String.fromCodePoint(...codesArray);
        document.querySelector(".input").innerHTML += emoji
        setTextBoxValue(text_input.current.innerText )
    }

    useEffect(()=>{
        setTextBoxValue("")
        document.querySelector(".input").innerHTML = ""
    },[threadId])


    const [isEmojyPickerOpened,setIsEmojyPickerOpened]=useState(false)





    useOnClickOutside(emojiPicker,()=>setIsEmojyPickerOpened(false))



    const queryClient = useQueryClient();

    const editMutation = useMutation({
        mutationFn: (messageData)=>axiosClient.put(`/threads/${threadId}/messages/${editContent.id}`, messageData),
        onSuccess: async (res) => {
            setReplayContent(null)
            queryClient.invalidateQueries({ queryKey: [`/threads/${threadId}/messages`] });
            setTextBoxValue(null)
            text_input.current.innerText = ""
        },
    })

    const mutation = useMutation({
            mutationFn: (messageData)=>axiosClient.post(`/threads/${threadId}/messages/`, messageData),
            onSuccess: async (res) => {
                setReplayContent(null)
                queryClient.invalidateQueries({ queryKey: [`/threads/${threadId}/messages`] });
                setTextBoxValue(null)
                text_input.current.innerText = ""
            },
         })

    const [files,setFiles] = useState([])
    const { getRootProps:getRootFilesProps, getInputProps:getInputFileProps} = useDropzone({
        multiple:false,
        accept:{
            "application/msword":['.doc',".docx"],
            "application/pdf":['.pdf']
        },
        onDrop:async files => {
            setFiles(prev=>[...prev,...files])
        }
    });



    const [addedImages,setAddedImages] = useState([])
    const { getRootProps, getInputProps} = useDropzone({
        multiple:true,
        accept:{
            'image/jpeg': ['.jpeg', '.png']
        },
        onDrop:async files => {
            setAddedImages(prev=>[...prev,...files])
        }
    });

    const [btnLoading,setBtnLoading]=useState(false);
    const handleSubmit =async ()=>{
        if (!btnLoading){
            setBtnLoading(true)

            let errorInUpload = false
                let arr=[];
                for (const i of addedImages) {
                    await uploadSingleFile(i).then((res)=>arr.push(res)).catch((err)=>setBtnLoading(false))
                }
                for (const i of files) {
                    await uploadSingleFile(i).then((res)=>arr.push(res)).catch((err)=> {
                        setBtnLoading(false)
                        errorInUpload = true
                    })
                }
                const dataToSend={
                    "parent_id": replayContent?.id,
                    "text": textBoxValue,
                    "file_ids":arr
                }
                if (!errorInUpload){
                    if (editContent){
                        editMutation.mutateAsync(dataToSend)
                            .then((res)=> {
                                setEditContnet(null)
                                setAddedImages([])
                                setFiles([])
                            })
                            .catch((e)=>console.log(e))
                            .finally(()=>setBtnLoading(false))
                    }
                    else{
                        mutation.mutateAsync(dataToSend)
                            .then((res)=> {
                                setAddedImages([])
                                setFiles([])
                            })
                            .catch((e)=>console.log(e))
                            .finally(()=> {
                                setBtnLoading(false)
                            })
                    }
            }
        }
    }

    const images = addedImages?.map((file,index) => {
        const removeFile=()=>{
            setAddedImages( prevState=>
                [...prevState.slice(0,index), ...prevState.slice(index+1)]
            )
        }
        return   <li key={file.path} className={"relative w-fit h-fit"}>
            <div className={"relative w-12 h-12 rounded-8 border-1 border-brown-110"}>
                <img className={"w-full h-full object-cover"} src={URL.createObjectURL(file)} alt=""/>
            </div>
            <div className={"cursor-pointer -right-2 -top-2 absolute w-5 h-5 rounded-full bg-brown-90 grid place-items-center "} onClick={removeFile}>
                <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 3.54688L9 9.54688" stroke="#555555" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                    <path d="M9 3.54688L3 9.54688" stroke="#555555" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                </svg>
            </div>
        </li>

    });
    const filesPreview = files?.map((file,index) => {
        const removeFile=()=>{
            setFiles( prevState=>
                [...prevState.slice(0,index), ...prevState.slice(index+1)]
            )
        }
        return   <li key={file.path} className={"relative text-12 bg-brown-110 rounded-8 w-fit p-1 h-fit"}>
                        <div className={"cursor-pointer -right-2 -top-2 absolute w-5 h-5 rounded-full bg-brown-90 grid place-items-center "} onClick={removeFile}>
                            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 3.09375L9 9.09375" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                                <path d="M9 3.09375L3 9.09375" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    <div>
                <div>
                    {file.name}
                </div>
                <div>
                    {parseFloat(file.size/1000/1000).toFixed(2)}
                    MB
                </div>
            </div>
        </li>

    });


    useEffect(()=>{
        if (editContent){
            document.querySelector(".input").innerHTML = editContent.text
        }else{
            document.querySelector(".input").innerHTML =""
        }
    },[editContent])
    return (
        <div className={classNames("input-wrapper mt-auto z-[9] flex flex-col w-full bg-brown-80 px-3 pb-2 lg:pb-3 lg:px-3  ",
            s.container)}>
               <div className="chat-box">
              <div className={" relative"}>
                  {replayContent && <ReplayRectangle replayContent={replayContent} setReplayContent={setReplayContent}/>}
                  {editContent && <EditRectangle editContent={editContent} setEditContnet={setEditContnet}/>}
                <div className={" px-6 border-b-1  border-brown-110 w-full !rounded-b-[0]"}>
                    <div
                        className="input text-14  py-4 cursor-text w-full  text-gray-90 bg-brown-50"
                        role="textbox"
                        ref={text_input}
                        id={"chatInputBox"}
                        tabIndex={1}
                        onInput={updateValue}
                        data-placeholder="Write a message  ..."
                        data-focused-advice="Write a message  ..."
                        onKeyPress={(evt)=>{
                            if (window.innerWidth>992 &&  evt.key == "Enter" && !evt.shiftKey) {
                                evt.preventDefault()
                                handleSubmit()
                            }
                        }}
                        contentEditable
                    >
                    </div>
                    {images?.length ?
                        <div>
                            <ul className={"flex flex-row gap-4 mb-6 flex-wrap"}>{images}</ul>
                        </div>
                        :""
                    }
                    {filesPreview?.length ?
                        <div>
                            <ul className={"flex flex-row flex-wrap gap-4 mb-6"}>{filesPreview}</ul>
                        </div>
                        :""
                    }
                </div>


                <div  className={"inputBtnsWrapper flex items-center p-4 px-6 gap-3"}>
                    <div onClick={()=>{setIsEmojyPickerOpened(!isEmojyPickerOpened)}} id={"emojiPickerWrapper"}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 21C16.9709 21 21 16.9699 21 12C21 7.02908 16.9709 3 12 3C7.02908 3 3 7.02908 3 12C3 16.9699 7.02908 21 12 21Z" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M8.11719 9.80946C8.38184 9.46503 8.772 9.25 9.20497 9.25C9.63794 9.25 10.0281 9.46503 10.2928 9.80946" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M13.5859 9.80946C13.8506 9.46503 14.2407 9.25 14.6737 9.25C15.1067 9.25 15.4969 9.46503 15.7615 9.80946" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M9.12012 14.2891C9.82163 15.2017 10.854 15.7709 12.0001 15.7709C13.1463 15.7709 14.1796 15.2017 14.8801 14.2891" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    {isEmojyPickerOpened &&
                        <div id={"emojiPicker"} ref={emojiPicker} className={"absolute w-6 z-[2] left-0 bottom-10"}>
                            <EmojiPicker onEmojiClick={emojyAdd} theme={"dark"} className={"w-[300px]"}  size={10}/>
                        </div>
                    }

                    <svg width="1" height="16" viewBox="0 0 1 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="0.5" y1="2.18557e-08" x2="0.499999" y2="16" stroke="#554E4E"/>
                    </svg>

                    <div className="">
                        <div {...getRootProps({className: ''})}>
                            <input {...getInputProps()} />
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.303 0.75H5.651C2.639 0.75 0.75 2.884 0.75 5.904V14.05C0.75 17.07 2.631 19.204 5.651 19.204H14.298C17.323 19.204 19.203 17.07 19.203 14.05V5.904C19.207 2.884 17.326 0.75 14.303 0.75Z" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.70372 6.78545C8.70372 7.80545 7.87772 8.63145 6.85772 8.63145C5.83872 8.63145 5.01172 7.80545 5.01172 6.78545C5.01172 5.76545 5.83872 4.93945 6.85772 4.93945C7.87672 4.94045 8.70272 5.76645 8.70372 6.78545Z" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M19.207 12.951C18.284 12.001 16.509 10.082 14.579 10.082C12.648 10.082 11.535 14.315 9.678 14.315C7.821 14.315 6.134 12.401 4.646 13.628C3.158 14.854 1.75 17.361 1.75 17.361" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>



                    <div id={"atachFile"}>
                        <div {...getRootFilesProps({className: ''})}>
                            <input {...getInputFileProps()} />
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21 12.3955L14.6912 18.7043C12.0164 21.3781 7.67982 21.3781 5.00605 18.7043C2.33132 16.0296 2.33132 11.693 5.00605 9.01826L9.39909 4.6262C11.1758 2.84856 14.0558 2.84856 15.8325 4.6262C17.6091 6.4019 17.6091 9.28292 15.8325 11.0596L11.5474 15.3446C10.6503 16.2417 9.19671 16.2417 8.29961 15.3446C7.40252 14.4475 7.40252 12.9939 8.29961 12.0968L12.3433 8.05306" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>


                    <button onClick={handleSubmit} disabled={!(textBoxValue || images.length != 0 || files.length !=0)} className={"ml-auto w-24 h-12 flex gap-2 items-center font-medium px-3 py-2 rounded-8 border-1 bg-gray-50 text-brown-110 text-14 disabled:!border-brown-90 disabled:bg-transparent disabled:text-brown-110 "}>
                        {btnLoading ?
                            <div className={"w-full flex justify-center items-center"}>
                                <CustomSpinner/>
                            </div>
                            :
                            <>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.2299 13.3633L6.25674 20.4749C5.08076 21.1197 3.69929 20.0616 4.00845 18.7532L5.60476 12.0006L4.00845 5.24605C3.69929 3.9377 5.08076 2.88052 6.25674 3.52532L19.2299 10.6369C20.304 11.2264 20.304 12.7737 19.2299 13.3633Z" stroke="#524848" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M13.6418 11.9783L5.6416 12.0205" stroke="#524848" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                Send
                            </>
                        }

                    </button>

                </div>

            </div>

        </div>
        </div>
        );
};

export default ChatInputBox;
