import React from 'react';
import s from "./styles.module.scss"
import classNames from "classnames";
const LoadingPage = () => {
    return (
        <div className={classNames(" rounded-[28px] h-[80px] aspect-square w-fit p-4 bg-brown-40 flex justify-center items-center",s.container)}>
            <div className="loader loader--style3" title="2">
                <svg className={s.spinner} width="40" height="40" viewBox="0 0 66 66"
                     xmlns="http://www.w3.org/2000/svg">
                    <circle className={s.path} fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33"
                            r="30"></circle>
                </svg>
            </div>
        </div>
    );
};

export default LoadingPage;

export  const FullPageLoading =()=>{
    return(
        <div className={"w-full h-full grid place-items-center"}><LoadingPage/></div>
    )
}
