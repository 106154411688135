import React from 'react';
import classNames from "classnames";
import checkIcon from "./check-circle.svg";
const AlertTemplate = ({ style, options, message, close }) => {
    return (
        <div style={style} className={" flex flex-col gap-5 w-screen md:w-auto px-4 md:px-0 justify-center h-fit right-0 bottom-0 !mb-2   !m-0 md:!m-auto pb-4 md:pr-6 z-10"}>
            <div className={classNames("flex w-full mx-auto sm:w-[440px] items-center relative gap-5 py-4 px-4 rounded-12  bg-white pr-10")}>
                {options.type === 'error' &&
                    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.3">
                            <rect x="6" y="6" width="26" height="26" rx="13" stroke="#FF0000" stroke-width="2"/>
                        </g>
                        <g opacity="0.1">
                            <rect x="1" y="1" width="36" height="36" rx="18" stroke="#FF0000" stroke-width="2"/>
                        </g>
                        <g clip-path="url(#clip0_6908_68910)">
                            <path d="M18.9998 15.6665V18.9998M18.9998 22.3332H19.0082M27.3332 18.9998C27.3332 23.6022 23.6022 27.3332 18.9998 27.3332C14.3975 27.3332 10.6665 23.6022 10.6665 18.9998C10.6665 14.3975 14.3975 10.6665 18.9998 10.6665C23.6022 10.6665 27.3332 14.3975 27.3332 18.9998Z" stroke="#FF0000" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_6908_68910">
                                <rect width="20" height="20" fill="white" transform="translate(9 9)"/>
                            </clipPath>
                        </defs>
                    </svg>

                }
                {options.type === 'success' &&
                    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.3">
                            <rect x="6" y="6" width="26" height="26" rx="13" stroke="#079455" stroke-width="2"/>
                        </g>
                        <g opacity="0.1">
                            <rect x="1" y="1" width="36" height="36" rx="18" stroke="#079455" stroke-width="2"/>
                        </g>
                        <g clip-path="url(#clip0_6908_68770)">
                            <path d="M15.2498 18.9998L17.7498 21.4998L22.7498 16.4998M27.3332 18.9998C27.3332 23.6022 23.6022 27.3332 18.9998 27.3332C14.3975 27.3332 10.6665 23.6022 10.6665 18.9998C10.6665 14.3975 14.3975 10.6665 18.9998 10.6665C23.6022 10.6665 27.3332 14.3975 27.3332 18.9998Z" stroke="#079455" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_6908_68770">
                                <rect width="20" height="20" fill="white" transform="translate(9 9)"/>
                            </clipPath>
                        </defs>
                    </svg>
                }
                {options.type === 'info' &&
                        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.3">
                                <rect x="6" y="6" width="26" height="26" rx="13" stroke="#DC6803" stroke-width="2"/>
                            </g>
                            <g opacity="0.1">
                                <rect x="1" y="1" width="36" height="36" rx="18" stroke="#DC6803" stroke-width="2"/>
                            </g>
                            <g clip-path="url(#clip0_6908_68845)">
                                <path d="M18.9998 15.6665V18.9998M18.9998 22.3332H19.0082M27.3332 18.9998C27.3332 23.6022 23.6022 27.3332 18.9998 27.3332C14.3975 27.3332 10.6665 23.6022 10.6665 18.9998C10.6665 14.3975 14.3975 10.6665 18.9998 10.6665C23.6022 10.6665 27.3332 14.3975 27.3332 18.9998Z" stroke="#DC6803" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_6908_68845">
                                    <rect width="20" height="20" fill="white" transform="translate(9 9)"/>
                                </clipPath>
                            </defs>
                        </svg>
                }
                <div className="middle">
                    <div className={`title mb-1`} style={{color:options.type == "success" ? "#2BAC76" :
                    options.type == "info" ? "#D78423" : options.type =="error" ? "#F04438": "yellow"}}>
                        {message}
                    </div>
                    {/*<div className="desc text-14 text-[#475467]">*/}
                    {/*    {desc}*/}
                    {/*</div>*/}
                </div>
                <div onClick={close} className={"absolute right-4 top-0 items-center flex bottom-0 my-auto"}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 5L5 15M5 5L15 15" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default AlertTemplate;
