import React, { useState} from 'react';
import LoginLayout from "../LoginLayout";
import PasswordInput from "../../../Utility/PasswordInput";
import {useMutation} from "react-query";
import axiosClient from "../../../Api/apiClient";
import CustomSpinner from "../../../Utility/CustomSpinner";
import {Link, useNavigate} from "react-router-dom";
import {useAtom} from "jotai";
import {tokenAtom} from "../../../App";
import PageTitle from "../../../Components/PageTitle/PageTitle";
import {useAlert} from "react-alert";

const LoginPage = () => {
    PageTitle("Login")
    const alert = useAlert()

    const [email , setEmail] = useState("")
    const [password , setPassword]=useState("")
    const [errorMessage,setErrorMessage] = useState("")

    const navigate = useNavigate()
    const loginMutation = useMutation({
        mutationFn: (data)=>axiosClient.post('/auth/login', data),
        onSuccess: (res) => {
            alert.success("Logged in successfully!")
            SetToken(res.data.resulting_data)
            setTimeout(()=>{
                navigate("/")
            },1000)
        },
        onError:(err)=>{
           alert.error(Object.values(err.response.data.error)[0])
        }
    })

    const [token,setToken]=useAtom(tokenAtom)
    const SetToken =(object)=>{
        localStorage.setItem("token",object.access_token)
        setToken(object.access_token)
    }

    const SubmitHandler = (e) =>{
        if (email.length < 5) {
            setErrorMessage("Email should be at least 5 charcters long")
            return
        }else {
            if (email.split("").filter((x) => x === "@").length !== 1) {
                setErrorMessage("Email should contain a @");
                return;
            }else{
                if (email.indexOf(".") === -1) {
                    setErrorMessage("Email should contain at least one dot");
                    return;
                }else   {
                    if (password.length < 8) {
                        setErrorMessage("Password should be at least 8 characters long");
                        return;
                    }
                    else{
                        setErrorMessage("")
                    }
                }
            }
        }

        const dataToPost ={
            "email": email,
            "password": password,
        }
        loginMutation.mutate(dataToPost)
    }

    return (
        <LoginLayout  imgPath={"3dIcons/19.png"}>
            <div>
                <h2 className={" whitespace-nowrap md:text-center mb-2.5 text-[56px] font-medium lg:mb-5"}>Login</h2>
                <p className="w-full  md:text-center mb-6 text-[#D9D9D9]">Welcome back! Please enter your details.</p>
                <div className="form">
                    <div className="input-box">
                        <div className="label" > Email</div>
                        <input  value={email} onChange={(e)=>setEmail(e.target.value)} placeholder={"Enter Your email"} type="text"/>
                    </div>
                    <div className="input-box">
                        <div className="label"> Password</div>
                        <PasswordInput  value={password} onChange={(e)=>setPassword(e.target.value)} placeholder={"Enter Your password"} />
                    </div>
                    <div className="remember text-14 items-center font-medium flex justify-between  ">
                        {/*<div className="check-box items-center flex select-none gap-2.5 cursor-pointer" onClick={()=>setRememberMe(!rememberMe)}>*/}
                        {/*    <div className={`${rememberMe ?"bg-white" : " bg-transparent"} grid place-items-center box w-6 h-6 border-1 rounded-[7px] text-gray-90  border-gray-90 border-[1px]`}>*/}
                        {/*        {rememberMe &&*/}
                        {/*            <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                        {/*                <path d="M15 1.1875L5.375 10.8125L1 6.4375" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>*/}
                        {/*            </svg>*/}
                        {/*        }*/}
                        {/*    </div>*/}
                        {/*    <h6>Remember me</h6>*/}
                        {/*</div>*/}
                        <div></div>
                        <Link to={"/forgot-password"}>
                            <h6 className={"text-14"}> Forgot password</h6>
                        </Link>
                    </div>
                    <div className={"text-red-secondary"}>
                        {errorMessage}
                    </div>
                    <button className={"w-full submit-btn cursor-pointer"} onClick={SubmitHandler} >
                        {loginMutation.isLoading?
                            <div>
                                <CustomSpinner size={9}/>
                            </div>
                            :
                                "Login"
                        }
                        </button>

                    <div className="flex gap-4 items-baseline justify-center">
                        <p>
                            Don’t have an account?
                        </p>
                        <Link to={"/pre-register"}>
                            <h6 className=" cursor-pointer text-14">
                                Pre register
                            </h6>
                        </Link>
                    </div>
                </div>
            </div>
        </LoginLayout>
    );
};

export default LoginPage;
