import React from 'react';
const PinnedBox = ({isActive,name,noSvg,number,onClick}) => {
    return (
        <div onClick={onClick} className={`cursor-pointer leading-[14px] pin-box first:ml-6 last:mr-6 whitespace-nowrap text-gray-90 gap-2.5 rounded-8 items-center bg-brown-100 font-medium flex px-2 py-[8px] border-brown-90 border-2   ${isActive && "bg-white border-white !text-gray-100"}`}>
            {name}
            {noSvg? "" :
                isActive?
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.7041 0.933653C11.0888 0.54835 11.7131 0.548129 12.0982 0.93316L19.0673 7.90226C19.4523 8.28733 19.4522 8.91168 19.067 9.29662C18.682 9.68136 18.058 9.68125 17.6732 9.29637L13.4923 13.4772L12.8854 16.5126C12.8268 16.8058 12.6826 17.0751 12.4711 17.2864C11.88 17.8771 10.922 17.8769 10.3311 17.2859L7.22001 14.174L2.03815 19.3559L0.644531 17.9623L5.82638 12.7804L2.7145 9.66928C2.12353 9.07845 2.12331 8.12045 2.714 7.52934C2.92536 7.31783 3.19463 7.17367 3.48784 7.11507L6.52418 6.50814L10.705 2.32727C10.3204 1.94263 10.3197 1.31857 10.7041 0.933653ZM12.0987 3.7209L7.49498 8.3236L4.71365 8.88045L11.12 15.2868L11.6759 12.5054L16.2795 7.90275L12.0987 3.7209Z" fill="#A6A6A6"/>
                        </svg>
                        :
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.7041 0.933653C11.0888 0.54835 11.7131 0.548129 12.0982 0.93316L19.0673 7.90226C19.4523 8.28733 19.4522 8.91168 19.067 9.29662C18.682 9.68136 18.058 9.68125 17.6732 9.29637L13.4923 13.4772L12.8854 16.5126C12.8268 16.8058 12.6826 17.0751 12.4711 17.2864C11.88 17.8771 10.922 17.8769 10.3311 17.2859L7.22001 14.174L2.03815 19.3559L0.644531 17.9623L5.82638 12.7804L2.7145 9.66928C2.12353 9.07845 2.12331 8.12045 2.714 7.52934C2.92536 7.31783 3.19463 7.17367 3.48784 7.11507L6.52418 6.50814L10.705 2.32727C10.3204 1.94263 10.3197 1.31857 10.7041 0.933653ZM12.0987 3.7209L7.49498 8.3236L4.71365 8.88045L11.12 15.2868L11.6759 12.5054L16.2795 7.90275L12.0987 3.7209Z" fill="#A6A6A6"/>
                        </svg>

            }
            {
                number &&
                <div className={"gap-2 bg-[#524848] px-2 py-1 rounded-8"}>
                    {number}
                </div>
            }
        </div>
    );
};

export default PinnedBox;
