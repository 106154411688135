import React from 'react';
import CustomSpinner from "../../Utility/CustomSpinner";

const BtnTextForMutation = ({mutation,defaultText,errorText=defaultText,successText=defaultText,spinnerSize=9}) => {
    return (
        <>
            {mutation.isLoading?
                <div><CustomSpinner size={spinnerSize}/></div>
                :
                mutation.isError?
                    errorText
                    :
                    mutation.isSuccess?
                        successText
                        :
                        defaultText
            }
        </>
    );
};

export default BtnTextForMutation;
