import React, {useState, Suspense, useEffect, useRef} from 'react';
import classNames from "classnames";
import s from "./styles.module.scss";
import MobilePinnedMainHeader from "../../Sections/MobilePinnedMainHeader";
import 'swiper/css';
import AdminSidebar from "./AdminSidebar";
import SuperAdminCanView from "../../Components/ViewLimit/SuperAdminCanView";
import {format} from "date-fns";
import useOnClickOutside from "../../Hooks/useOnClickOutside";
import {DayPicker} from "react-day-picker";
import EventsContainer from "./EventsContainer";
import {FullPageLoading} from "../../Components/LoadingPage";
import NoteContainer from "./NoteContainer";
import PageTitle from "../../Components/PageTitle/PageTitle";

const Calender = () => {
    PageTitle("Calendar and ideas")

    const [isCalenderTabActive,setIsCalenderTabActive]=useState(true)

    const [isAddNewNoteOpened,setIsAddNewNoteOpened]=useState(true)
    const [isAdminSideOpen,setAdminSideOpen]=useState(false)


    const today = new Date();
    const [selectedDate, setSelectedDate] = useState(today);
    const [isDayPickerOpened,setIsDayPickerOpened]=useState(false)
    const dayPickerWrapper = useRef();
    useOnClickOutside(dayPickerWrapper, () => setIsDayPickerOpened(false));


    return (
        <div className={classNames(s.container,"h-full overflow-hidden flex flex-col")}>
            <MobilePinnedMainHeader title={"Calendar and ideas"}>
                <SuperAdminCanView>
                    <button onClick={() => setAdminSideOpen(true)} className="!px-2 lg:!px-6 btn grey-50">
                    Add new event
                    </button>
                </SuperAdminCanView>
            </MobilePinnedMainHeader>
            <div className="time-wrapper relative flex justify-center p-6  text-18 font-medium">

                <div onClick={()=>setIsDayPickerOpened(true)} className={"flex items-center cursor-pointer lg:items-center gap-3"}>
                    <span className={"flex gap-2"}>
                       {selectedDate &&  format(selectedDate,"dd LLLL yyyy")}
                    </span>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.25 6.375L9 11.625L3.75 6.375" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                    </svg>
                </div>
                {isDayPickerOpened &&
                    <div ref={dayPickerWrapper} className={"absolute right-0 left-0 top-full mx-auto w-fit z-20  border-1 border-brown-110 bg-brown-80 rounded-8"}>
                        <DayPicker
                            showOutsideDays
                            mode="single"
                            selected={selectedDate}
                            onSelect={setSelectedDate}
                            required
                        />
                    </div>
                }
                {/*<div onClick={setNextDay}>*/}
                {/*    next day*/}
                {/*</div>*/}
            </div>

            <div className="main-calender flex-grow bg-brown-80 overflow-auto lg:overflow-hidden pad-layout py-8  lg:pb-0 flex flex-col  lg:grid lg:grid-cols-2 gap-3 ">
                <div className={"tabs p-2 lg:hidden h-fit flex bg-brown-40 rounded-8 font-semibold"}>
                    <button onClick={()=>setIsCalenderTabActive(true)} className={classNames("tab",isCalenderTabActive && "active")}>
                        Calendar
                    </button>
                    <button onClick={()=>setIsCalenderTabActive(false)} className={classNames("tab",!isCalenderTabActive && "active")}>
                        Notes
                    </button>
                </div>
                <div className={classNames("box lg:flex h-full",isCalenderTabActive?"flex":"hidden")}>
                    <Suspense fallback={<FullPageLoading/>}>
                        <EventsContainer selectedDate={format(selectedDate,"yyyy-LL-dd")}/>
                    </Suspense>
                </div>
                <div className={classNames("box lg:flex",!isCalenderTabActive?"flex":"hidden")}>
                    <div className={"flex justify-between mb-6 h-16 flex-shrink-0 items-center"}>
                        <div className="title">
                            Make a note
                        </div>
                        <div onClick={()=>{
                            setIsAddNewNoteOpened(true)
                        }} className={"btn normal  !px-3 w-fit"}>
                            <svg className={"lg:block hidden"} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 1V11" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                                <path d="M11 6H1" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                            </svg>
                            Add new note
                        </div>
                    </div>
                    <Suspense fallback={<FullPageLoading/>}>
                        <NoteContainer setIsAddNewNoteOpened={setIsAddNewNoteOpened} isAddNewNoteOpened={isAddNewNoteOpened}/>
                    </Suspense>

                </div>
            </div>

            <SuperAdminCanView>
                        <AdminSidebar isOpened={isAdminSideOpen} setIsOpened={setAdminSideOpen}/>
            </SuperAdminCanView>
        </div>
    );
};

export default Calender;
