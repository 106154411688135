import React, {useEffect, useState,Suspense} from 'react';
import PinnedBox from "../../Sections/MobilePinnedMainHeader/PinnedBox";
import s from "./style.module.scss"
import ChatArea from "./ChatArea";
import {Link, useLocation} from "react-router-dom";
import ThreadPopup from "./AddThread/ThreadPopup";
import FilterButton from "./Components/FilterButton";
import {FiltersList} from "./FiltersList";
import SearchThreadInput from "./Components/SearchThreadInput";
import {MainThreadsName} from "./MainThreadsName";
import { ScrollContainer } from 'react-indiana-drag-scroll';
import 'react-indiana-drag-scroll/dist/style.css'
import ThreadsList from "./ThreadsList";
import ThreadsLoading from "./Loadings/ThreadsLoading";
import AddThread from "./AddThread";
import ChatLoading from "./Loadings/ChatLoading";
import {useQuery} from "react-query";
import PageTitle from "../../Components/PageTitle/PageTitle";



const Chat = () => {
    PageTitle("Laguire chats")
    const [searchThreadKeyword,setSearchThreadKeyword]=useState("")
    const [activeFilter,setActiveFilter]=useState(FiltersList[0])
    const [activeCategory,setActiveCategory]=useState("All")


    const location = useLocation()


    const currentPath = location.pathname.split("/")[2]



    const ThreadName = ()=> {
        const threadTitle = useQuery([`/threads/${currentPath}`],{
            staleTime:100000,
        })?.data?.resulting_data?.title
        return <div>{threadTitle}</div>
    }



    return (
            <div className={`h-full flex flex-col w-full ${s.chatPageWrapper}`}>
                <div className={`hidden lg:flex bg-brown-50  g-page-title pl-6 py-6 !border-none`}>
                    Threads  list
                </div>

                <div className={`hidden lg:flex bg-brown-50 px-8 items-center text-gray-50  text-18  font-medium `}>
                    {currentPath &&

                        <Suspense fallback={"Thread Loading..."}>
                            <ThreadName/>
                        </Suspense>
                    }
                </div>
                <div className={`${ window.location.pathname === "/chats" || window.location.pathname == "/" ? "lg:flex" : "hidden lg:flex"} left-side flex w-full flex-col bg-brown-50 flex-grow overflow-hidden lg:border-none  lg:border-r-2 border-brown-90 `}>
                    <div className={"flex-grow-0"}>
                        <div className="flex relative px-6 py-4 justify-between">
                            <FilterButton activeFilter={activeFilter} setActiveFilter={setActiveFilter} FiltersList={FiltersList}/>

                            <div className="flex gap-3 items-center">
                                <SearchThreadInput setValue={setSearchThreadKeyword} value={searchThreadKeyword}/>
                                <AddThread/>
                            </div>
                        </div>

                        <ScrollContainer className={s.pinnedTabs}>
                            <PinnedBox onClick={()=>setActiveCategory("All")} noSvg name={"All"} isActive={"All" == activeCategory}/>
                            {
                                MainThreadsName.map((item)=>{
                                    return(
                                        <PinnedBox onClick={()=>setActiveCategory(item)} noSvg name={item} isActive={item == activeCategory}/>
                                    )
                                })
                            }
                        </ScrollContainer>
                    </div>
                    <Suspense fallback={<ThreadsLoading/>}>
                        <ThreadsList badge={activeFilter} category={activeCategory} searchThreadKeyword={searchThreadKeyword}/>
                    </Suspense>
                </div>
                <div className={`${ (window.location.pathname === "/chats" || window.location.pathname == "/")? "hidden" : "flex lg:hidden"} h-[68px]  px-8 items-center bg-brown-40 gap-4 text-18 font-sans`}>
                    <Link to={"/chats"}>
                        <div className={"p-2"}>
                            <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.5 16L1.5 9L8.5 2" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                            </svg>
                        </div>
                    </Link>

                    {window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)}
                </div>
                <div className={`${ window.location.pathname === "/chats" || window.location.pathname == "/"? "hidden lg:block" : "block"} h-full overflow-hidden`}>
                    {
                        window.location.pathname === "/chats" || window.location.pathname=="/" ?
                            <div className={"h-full hidden lg:flex justify-center items-center flex-col bg-brown-50"}>
                                <h2 className={"text-24 mb-3"}>
                                    It's nice to chat with someone
                                </h2>
                                <p className={"font-medium text-gray-90"}>Pick a thread from left menu and start your conversation.</p>
                            </div>
                            :
                            <Suspense fallback={<ChatLoading/>}>
                                <ChatArea/>
                            </Suspense>
                    }

                </div>

                {/*<ToastNotification/>*/}
            </div>
    );
};

export default Chat;
