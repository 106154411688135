import React from 'react';
import s from "./styles.module.scss"
import classNames from "classnames";
import MobilePinnedMainHeader from "../../../Sections/MobilePinnedMainHeader";
import PageTitle from "../../../Components/PageTitle/PageTitle";
const TransactionList = () => {
    PageTitle("Transaction list")

    const DropDown=({title})=>{
        return(
            <div className={"drop-down items-center"}>
                {title}
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.25 6.375L9 11.625L3.75 6.375" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                </svg>

            </div>
        )
    }

    const TransactionItem =()=>{
        return(
            <div className={"transaction-grid bg-brown-80 items-center "}>
                <div className={"el flex gap-3"}>
                    <div className="circle w-10 h-10 rounded-full bg-white">

                    </div>
                    <div className="content ">
                        <div className="text-14 font-medium i">
                            Olivia Rhye
                        </div>
                        <div className={"text-12 text-gray-90"}>
                            olivia@gmail.com
                        </div>
                    </div>
                </div>
                <div className={"el flex gap-2 justify-between"}>
                    <div className={"break-words text-14"}>
                        0xfd75aba47932f8f8c3e4a1039695159e47d97e2e
                    </div>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.2852 5.74802C15.0308 4.10756 13.8495 3 12.0693 3H6.29465C4.28435 3 3.02148 4.42788 3.02148 6.44497V11.8837C3.02148 13.7213 4.07177 15.0754 5.79377 15.2889" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.706 8.67969H11.9333C9.92205 8.67969 8.66016 10.1037 8.66016 12.1198V17.5586C8.66016 19.5747 9.91526 20.9987 11.9333 20.9987H17.705C19.7241 20.9987 20.9792 19.5747 20.9792 17.5586V12.1198C20.9792 10.1037 19.7241 8.67969 17.706 8.67969Z" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                </div>
                <div className={"el text-14"}>
                    0.542344 Eth
                </div>
                <div className={"el flex items-center justify-between gap-3"}>
                    <div>3 Month</div>
                    <DropDown title={"Accept"}/>
                </div>
            </div>
        )
    }
    return (
        <div className={classNames(s.container,"flex flex-col h-full")}>
            <MobilePinnedMainHeader title={"Transactions"}/>
            <div className="page-wrapper overflow-auto flex-grow pad-layout py-8">
                <div className={"flex justify-between mb-8"}>
                    <h3 className="text-18 font-medium text-gray-50">
                        Latest Transactions
                    </h3>
                    <DropDown title={"Success"}/>
                </div>

                <div className="transactions-box rounded-8 overflow-hidden">
                    <div className={"transaction-grid  text-gray-90 text-14 font-medium bg-brown-40 items-center"}>
                        <div className="el">Username</div>
                        <div className="el">Transaction hash</div>
                        <div className="el">Balance due</div>
                        <div className="el">S-Interface</div>
                    </div>
                    <TransactionItem/>
                    <TransactionItem/>
                    <TransactionItem/>
                    <TransactionItem/>
                    <TransactionItem/>
                    <TransactionItem/>
                    <TransactionItem/>
                    <TransactionItem/>
                </div>
            </div>


        </div>
    );
};

export default TransactionList;
