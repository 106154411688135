import React, {useRef, useState} from 'react';
import classNames from "classnames";
import s from "./styles.module.scss";
import {useMutation, useQueryClient} from "react-query";
import axiosClient from "../../../Api/apiClient";
import BtnTextForMutation from "../../../Components/BtnTextForMutation";

const AddNoteContainer = ({setIsAddNewNoteOpened,number}) => {
    const textInput =useRef();
    const [text,setText]=useState(null)
    const queryClient = useQueryClient()
    const postNoteMutation = useMutation({
        mutationFn: (note)=>axiosClient.post(`/notes`, note),
        onSuccess:()=>{
            queryClient.invalidateQueries("/notes")
        }
    })

    const handleSubmit =()=>{
    postNoteMutation.mutateAsync({
        "note":text
    }).then(()=>{
        setIsAddNewNoteOpened(false)
        setTimeout(()=>{
            postNoteMutation.reset()
        },2000)
    }).catch((e)=>console.log(e))
    }
    return (
        <div className={classNames("note_container !bg-[#FFDE9F]  py-2  px-3 rounded-12   bg-white aspect-square relative",
            s.container)}>
            <div className={"flex justify-between items-center"}>
                <div className="text-14 !text-gray-100">
                    Note {number}
                </div>
                <div onClick={()=>setIsAddNewNoteOpened(false)}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 6L18 18" stroke="#0D0807" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                        <path d="M18 6L6 18" stroke="#0D0807" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                    </svg>
                </div>


            </div>

            <textarea value={text} onChange={(e)=>{setText(e.target.value)}} ref={textInput} placeholder={"type here..."} className={"textarea mt-4 h-full"}>
            </textarea>

            <button disabled={!text} onClick={handleSubmit} className={"w-20 border-1 border-brown-50 text-brown-50 text-14 font-medium px-3 py-2 rounded-8  ml-auto absolute bottom-4 right-4 bg-transparent"}>
               <BtnTextForMutation defaultText={"add"} mutation={postNoteMutation} errorText={"Error"} successText={"Added!"} spinnerSize={7}/>
            </button>
            <div className={"fliped-corner bg-gray-90 z-20 absolute w-[10%] h-[10%] bg-black left-0 -bottom-0"}>

            </div>
        </div>
    );
};

export default AddNoteContainer;
