import React from 'react';
import s from "./styles.module.scss"
import {format} from "date-fns";
import MoreBtn from "../../../Sections/MoreBtn";
import {useUpdatePost} from "../../../Hooks/useUpdatePost";
import {Link} from "react-router-dom";
import BookmarkIcon from "../../../Components/BookmarkIcon";
const TradePostBox = ({isBookmarked,id,profileImg,title,desc,cover,name,role,date}) => {
    const{handleDelete,handleEdit,deleteMutation} = useUpdatePost(id,"/trading-strategies")
    return (
        <div className={s.container}>
            <Link className={"w-full"} to={`/trading-strategies/${id}`}>
                <div className="img w-full h-52 mb-3 overflow-hidden rounded-8">
                    <img src={cover} className={"w-full h-full object-cover"} alt=""/>
                </div>
                <div className={"font-medium text-18 "}>
                    {title}
                </div>
            </Link>
            <div className={"my-2 text-gray-90 text-12"}>
                {format(new Date(date), 'eeee,yyyy dd MMMM')}
            </div>
            <div className={"text-gray-90 text-14 font-medium"}>
                {desc}
            </div>
            <div className={"h-[1px] w-full bg-brown-110 my-3"}>

            </div>
            <div className={"w-full flex flex-wrap gap-4 mt-auto justify-between items-center"}>
                <div className={"flex gap-3 items-center "}>
                    <div className="circle w-12 h-12 overflow-hidden rounded-full">
                        <img src={profileImg?profileImg:"/media/defaultAvatar.png"} className={"w-full h-full object-cover"} alt=""/>
                    </div>
                    <div className="content">
                        <div className="name font-medium ">
                            {name}
                        </div>
                        <div className="text-gray-50 text-12 ">
                            {role}
                        </div>
                    </div>
                </div>
                <div className={"flex gap-1 items-center"}>
                   <BookmarkIcon isBookmarked={isBookmarked} bookmarkKey={"TradingStrategy"} bookmarkableId={id} query={"/trading-strategies"}/>
                   <div>
                       <MoreBtn options={[
                           {name: "Delete", fn: handleDelete, mutation: deleteMutation},
                           {name: "Edit", fn: handleEdit},
                       ]}/>
                   </div>
                </div>

            </div>

        </div>
    );
};

export default TradePostBox;
