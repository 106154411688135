import React from 'react';
import Navbar from "../Navbar";
import s from "./styles.module.scss"
import {useGetProfile} from "../../Hooks/useGetProfile";
import {useAlert} from "react-alert";
const Layout = (props) => {
    // const  isPremiumUser = useGetProfile().subscribed
    return (
        <div className={s.container} >

            {/*<div className={"lg:hidden "}>*/}
            {/*    <HeadBar/>*/}
            {/*</div>*/}
            <div className="main h-full bg-brown-50 overflow-hidden w-full  ">
                {props.children}
            </div>
            <div className={`${props.NoMobileNavigator && "hidden"} lg:block lg:col-start-1 lg:row-start-1  `}>
                <Navbar/>
            </div>
        </div>
    );
};

export default Layout;






