import React from 'react';
import classNames from "classnames";
import s from "./styles.module.scss"
const Tabbar = (props) => {
    return (
        <div className={classNames("tab-bar px-8  border-t-1 border-b-1 border-brown-90",s.container)}>
            <div className="tabs h-full flex gap-4">
                {props.children}
            </div>
        </div>

    );
};

export default Tabbar;
