import React, {useState} from 'react';
import LoginLayout from "../LoginLayout";
import PasswordInput from "../../../Utility/PasswordInput";
import CustomSpinner from "../../../Utility/CustomSpinner";
import {useMutation} from "react-query";
import axiosClient from "../../../Api/apiClient";
import {useAlert} from "react-alert";
import {Link, useNavigate} from "react-router-dom";

const ForgotPassword = () => {
    const [email , setEmail] = useState("")
    const [errorMessage,setErrorMessage] = useState("")
    const alert = useAlert()
    const navigate = useNavigate()

    const mutation = useMutation({
        mutationFn: (data)=>axiosClient.post('/user/forget-password', data),
        onSuccess: (res) => {
            alert.success("sent successfully!")
            setTimeout(()=>{
                navigate("/login")
            },2000)
        },
        onError:(err)=>{
            alert.error(Object.values(err.response.data.error)[0])
        }
    })


    const SubmitHandler = (e) =>{

        if (email.length < 5) {
            setErrorMessage("Email should be at least 5 charcters long")
            return
        }else {
            if (email.split("").filter((x) => x === "@").length !== 1) {
                setErrorMessage("Email should contain a @");
                return;
            }else{
                if (email.indexOf(".") === -1) {
                    setErrorMessage("Email should contain at least one dot");
                    return;
                }else   {
                        setErrorMessage("")
                }
            }
        }

        const dataToPost ={
            "email": email,
        }
        mutation.mutate(dataToPost)

    }
    return (
        <LoginLayout>
            <div>
                <h2 className={" whitespace-nowrap md:text-center mb-2.5 text-[56px] font-medium lg:mb-5"}>Forgot Password</h2>
                <p className="w-full  md:text-center mb-6 text-[#D9D9D9]">Please enter your email to recover your password.</p>
                <div className="form">
                    <div className="input-box">
                        <div className="label" >Email</div>
                        <input  value={email} onChange={(e)=>setEmail(e.target.value)} placeholder={"Enter Your email"} type="text"/>
                    </div>

                    <div className={"text-red-secondary"}>
                        {errorMessage}
                    </div>

                    <button className={"w-full submit-btn cursor-pointer"} onClick={SubmitHandler} >
                        {mutation.isLoading?
                            <div>
                                <CustomSpinner size={9}/>
                            </div>
                            :
                            "Submit"
                        }
                    </button>
                    <div className="flex gap-4 items-baseline justify-center">
                        <p>
                            Want to get back?
                        </p>
                        <Link to={"/login"}>
                            <h6 className=" cursor-pointer text-14">
                                Login
                            </h6>
                        </Link>
                    </div>
                </div>
            </div>

        </LoginLayout>
    );
};

export default ForgotPassword;
