import React from 'react';
import {useAtom} from "jotai";
import {tokenAtom} from "../../App";
import {useGetProfile} from "../../Hooks/useGetProfile";
import {useMutation} from "react-query";
import axiosClient from "../../Api/apiClient";
import {NavLink} from "react-router-dom";
import MoreBtn from "../MoreBtn";

const ProfileHolder=()=>{
    const [token,setToken]=useAtom(tokenAtom)

    const user = useGetProfile()

    const signOutMutation = useMutation({
        mutationFn: ()=>axiosClient.post(`/auth/logout`)
    })
    const handeSignOut =()=>{
        signOutMutation.mutateAsync().then(()=>{
            setToken(null)
        }).catch(()=>{
            console.log("error")
        })
    }
    return(
        <div className="profile h-20 p-2 border-1 bg-brown-40 lg:bg-transparent border-brown-110 rounded-8 w-full flex items-center mt-auto ">
            <NavLink to={"/profile"}  className={"rounded-8 overflow-hidden shrink-0 w-8 h-9 "}>
                <img className={"-8 bg-black w-full h-full object-cover"} src={user.profile_image_url ? user.profile_image_url : "/media/defaultAvatar.png"} alt=""/>
            </NavLink>
            <NavLink to={"/profile"}  className="text ml-2 flex-grow overflow-hidden font-medium">
                <div className="name text-gray-50 whitespace-nowrap text-ellipsis  overflow-hidden flex-grow leading-5">
                    {user.name}
                    {/*seyed mohammad mousavi khosroshah*/}
                </div>
                <div className="email text-12 text-gray-90 leading-4 break-all whitespace-nowrap text-ellipsis  overflow-hidden flex-grow leading-5">
                    {user.email}
                </div>
            </NavLink>
            <div className="exit ml-auto">
                <MoreBtn isTop options={[
                    {name: "Sign out", fn: handeSignOut, mutation: signOutMutation},
                ]}/>
            </div>
        </div>
    )
}

export default ProfileHolder;
