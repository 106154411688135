import React, {useRef} from 'react';
import s from "./styles.module.scss"
import useOnClickOutside from "../../Hooks/useOnClickOutside";
const Index = ({children,setIsDropOpen,className}) => {


    const dropdown = useRef()
    useOnClickOutside(dropdown, () => setIsDropOpen(false));
    return (
        <div ref={dropdown} className={s.dropdown_filter + " " + className}>
            {children}
        </div>
    );
};

export default Index;
