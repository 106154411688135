import React from 'react';
import classNames from "classnames";
import s from "./styles.module.scss"
import MobilePinnedMainHeader from "../../../Sections/MobilePinnedMainHeader";
import PageTitle from "../../../Components/PageTitle/PageTitle";
const SessionSetAvailability = () => {
    PageTitle("Sessions")

    const TimeBox =({noTimeSet,isTimeActive,isMultiple})=>{
        return(
            <div className={"time-box border-brown-110 border-b-1 py-3 flex "}>
                <div className={isMultiple ?"mt-4":"self-center"}>
                    <div className={"flex items-center gap-2 font-medium"}>
                        <div className={classNames("sqr grid place-items-center w-[14px] aspect-square rounded-[4px]  border-brown-110 border-1")}>
                            {isTimeActive &&
                                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 6L5.66713 8L9 4" stroke="white" stroke-width="1.71429" stroke-linecap="square"/>
                                </svg>
                            }
                        </div>
                        Sun
                    </div>
                </div>
                {noTimeSet?
                    <div className={"text-gray-90 font-medium h-full grid place-items-center self-center ml-10"}>
                        No time has been set for this day
                    </div>
                    :
                    <div  className={"ml-10 flex gap-3 flex-col"}>
                        <div className={"flex gap-3 items-center"}>
                            <div className="time-wrapper border-1 border-brown-110 rounded-8 h-12 w-[130px] grid place-items-center text-gray-90 font-medium text-18">
                                09:00
                            </div>
                            <div className="time-wrapper border-1 border-brown-110 rounded-8 h-12 w-[130px] grid place-items-center text-gray-90 font-medium text-18">
                                09:00
                            </div>
                            <svg className={"ml-5"} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.69531 6.32617H19.8261L18.4654 20.5333H6.05602L4.69531 6.32617Z" stroke="#A6A6A6" stroke-width="1.44" stroke-linecap="square"/>
                                <path d="M16.5571 5.8904L15.5312 2.77295H8.98887L7.96289 5.8904" stroke="#A6A6A6" stroke-width="1.44" stroke-linecap="square"/>
                                <path d="M12.2617 11.0422V15.8162" stroke="#A6A6A6" stroke-width="1.44" stroke-linecap="square"/>
                            </svg>

                        </div>
                        {isMultiple &&
                            <>
                                <div className={"flex gap-3 items-center"}>
                                <div
                                    className="time-wrapper border-1 border-brown-110 rounded-8 h-12 w-[130px] grid place-items-center text-gray-90 font-medium text-18">
                                    09:00
                                </div>
                                <div
                                    className="time-wrapper border-1 border-brown-110 rounded-8 h-12 w-[130px] grid place-items-center text-gray-90 font-medium text-18">
                                    09:00
                                </div>
                                <svg className={"ml-5"} width="25" height="24" viewBox="0 0 25 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.69531 6.32617H19.8261L18.4654 20.5333H6.05602L4.69531 6.32617Z"
                                          stroke="#A6A6A6" stroke-width="1.44" stroke-linecap="square"/>
                                    <path d="M16.5571 5.8904L15.5312 2.77295H8.98887L7.96289 5.8904" stroke="#A6A6A6"
                                          stroke-width="1.44" stroke-linecap="square"/>
                                    <path d="M12.2617 11.0422V15.8162" stroke="#A6A6A6" stroke-width="1.44"
                                          stroke-linecap="square"/>
                                </svg>

                            </div>
                                <div className={"flex gap-3 items-center"}>
                            <div className="time-wrapper border-1 border-brown-110 rounded-8 h-12 w-[130px] grid place-items-center text-gray-90 font-medium text-18">
                            09:00
                            </div>
                            <div className="time-wrapper border-1 border-brown-110 rounded-8 h-12 w-[130px] grid place-items-center text-gray-90 font-medium text-18">
                            09:00
                            </div>
                            <svg className={"ml-5"} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.69531 6.32617H19.8261L18.4654 20.5333H6.05602L4.69531 6.32617Z" stroke="#A6A6A6" stroke-width="1.44" stroke-linecap="square"/>
                            <path d="M16.5571 5.8904L15.5312 2.77295H8.98887L7.96289 5.8904" stroke="#A6A6A6" stroke-width="1.44" stroke-linecap="square"/>
                            <path d="M12.2617 11.0422V15.8162" stroke="#A6A6A6" stroke-width="1.44" stroke-linecap="square"/>
                            </svg>

                            </div>
                            </>
                        }
                    </div>
                }
                <div className={"h-11 group hover:bg-white cursor-pointer  hover:text-brown-40 text-gray-90 aspect-square ml-auto rounded-8 bg-brown-40 grid place-items-center"}>
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.5 7V17" stroke="currentColor" stroke-width="1.5" stroke-linecap="square"/>
                        <path d="M17.5 12H7.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="square"/>
                    </svg>
                </div>

            </div>
        )
    }
    return (
        <div className={classNames("flex flex-col h-full",s.container)}>
            <MobilePinnedMainHeader title={"Set Availability"} hasBackBtn={true}/>
            <div className={"flex-grow overflow-auto pad-layout py-8"}>
                <div className="text-24 font-semibold mb-3">
                    Set your weekly hours
                </div>
                <div className={"text-gray-90 font-medium"}>
                    2:03 PM local time
                </div>
                <div className={"times-container mt-6"}>
                    <TimeBox isMultiple/>
                    <TimeBox isTimeActive/>
                    <TimeBox noTimeSet/>
                    <TimeBox/>
                    <TimeBox />
                    <TimeBox isTimeActive noTimeSet/>
                    <TimeBox isTimeActive/>

                </div>
                <div className={"mt-4"}>
                    <div className="btn ml-auto grey-50 big">
                        Save
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SessionSetAvailability;
