import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import classNames from "classnames";
import checkIcon from "./Utility/ToastNotification/check-circle.svg";
import AlertTemplate from "./Utility/ToastNotification";

const root = ReactDOM.createRoot(document.getElementById('root'));
const options = {
    position: positions.BOTTOM_RIGHT,
    timeout: 6000,
    transition: transitions.SCALE,
}



root.render(
  <React.StrictMode>
      <AlertProvider template={AlertTemplate} {...options}>
         <App />
      </AlertProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
