import React from 'react';
import s from "./styles.module.scss"
import {format} from "date-fns";
import LinkPreview from "../../../Utility/LinkPreview";
import MoreBtn from "../../../Sections/MoreBtn";
import {useUpdatePost} from "../../../Hooks/useUpdatePost";
import Comments from "../Comments";
import {useAtom} from "jotai";
import {permissionAtom} from "../../../App";
import AdminCanView from "../../../Components/ViewLimit/AdminCanView";
import LikeIcon from "../../../Components/LikeIcon";
import BookmarkIcon from "../../../Components/BookmarkIcon";
import {Link} from "react-router-dom";
const NewsBox = ({isBookmarked,title,url,id,comments,date,author,likes,isLiked}) => {

    const {handleDelete,handleEdit,deleteMutation} = useUpdatePost(id,"/news-and-trends")
    if (!/^https?:\/\//i.test(url)) {
        url = 'http://' + url;
    }
    return (

        <div className={`${s.container} w-full py-6 px-4`}>

           <div className={"flex justify-between"}>
               <div className="profile mb-1 relative  pb-4 gap-4 flex">
                   <div className="avatar-wrapper circle w-12 h-12 overflow-hidden rounded-full bg-white">
                       <img src={author.profile_image_url?author.profile_image_url : "/media/defaultAvatar.png"} className={"w-full h-full object-cover"} alt=""/>
                   </div>
                   <div className="content">
                       <div className="name text-18 font-medium">
                           {author.name}
                       </div>
                       <div className="my-2 text-gray-90 text-12 ">
                           {format(new Date(date), 'MMMM dd, yyyy  hh:mm aaaa')}
                       </div>
                   </div>

               </div>
                   <div className={"flex gap-2 items-start"}>
                       <BookmarkIcon isBookmarked={isBookmarked} bookmarkKey={"NewsAndTrend"} bookmarkableId={id} query={"/news-and-trends"}/>
                       <AdminCanView>
                           <MoreBtn options={[
                               {name: "Delete", fn: handleDelete, mutation: deleteMutation},
                               {name: "Edit", fn: handleEdit},
                           ]}/>
                       </AdminCanView>

                   </div>
           </div>
            <div className={"text-gray-90 font-medium"}>
                {title}

            </div>

            <a href={url} target="_blank" >
                <LinkPreview url={url}/>
            </a>

           <LikeIcon isLiked={isLiked} likes={likes} likeableId={id} query={"/news-and-trends"}/>
            <Comments newsId={id} comments={comments}/>
        </div>
    );
};

export default NewsBox;
