import React from 'react';
import ThreadBox from "../ThreadBox";

import s from "../ThreadBox/styles.module.scss";
import DropdownContainer from "../../../Utility/DropdownContainer";
import {Link} from "react-router-dom";
import CustomSkeleton from "../../../Utility/CustomSkeleton";


const ThreadsLoading = () => {
    const LoadingThreadBox =()=>{
        return(
            <div className={` h-[160px] relative px-6 py-4 bg-brown-40 `}>
                <div className={"flex gap-3 items-center"}>
                    <CustomSkeleton height={58} width={58}/>
                    <div className={"flex flex-col"}>
                        <CustomSkeleton height={14} width={200}/>
                        <CustomSkeleton height={14} width={150}/>
                    </div>
                </div>
                <div className={"mt-4 flex justify-between"}>
                    <CustomSkeleton height={24} width={40}/>
                    <CustomSkeleton height={24} width={130}/>
                </div>
                <div className={"mt-1 flex justify-between"}>
                    <CustomSkeleton height={12} width={100}/>
                    <CustomSkeleton height={12} width={60}/>
                </div>
            </div>
        )
    }
    return (
        <div className="main bg-brown-80  flex-grow  overflow-hidden   grid  lg:gap-3 ">
            <div className="grid overflow-visible max-h-full h-fit gap-[2px] bg-brown-80">
                <LoadingThreadBox/>
                <LoadingThreadBox/>
                <LoadingThreadBox/>
                <LoadingThreadBox/>
                <LoadingThreadBox/>
                <LoadingThreadBox/>
            </div>
        </div>
    );
};

export default ThreadsLoading;
