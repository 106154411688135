import React from 'react';
import {BeatLoader} from "react-spinners";

const CustomSpinner = ({color="#A6A6A6",size=12}) => {
    return (
        <BeatLoader speedMultiplier={.8} size={size} color={color}  />
    );
};

export default CustomSpinner;
