import {useQuery} from "react-query";
import {useAtom} from "jotai";
import {meAtom, permissionAtom} from "../App";

export const useGetProfile =()=>{
    const [me,setMe]=useAtom(meAtom)
    const {data}= useQuery('/auth/me',{
        refetchOnWindowFocus:false,
        cacheTime:Infinity,
        staleTime:Infinity
    })
    const user = data.resulting_data
    const userWithRole = {...user ,
        isOwner :user.roles.includes("super_admin") ,
        isAdmin : user.roles.includes("admin") || !user.roles.includes("super_admin"),
        isUser : user.roles.includes("customer") || !user.roles.includes("admin") || !me.roles.includes("super_admin") ,
    }
    setMe(user)

    return userWithRole;
}
