import React, {useEffect, useState} from 'react';
import {NavLink, useLocation} from "react-router-dom";
import MoreOptions from "./MoreOptions";
import s from "./../styles.module.scss"
import classNames from "classnames";
import ReactSwipeEvents from 'react-swipe-events'

const MobileNav = () => {
    const [isMoreOpen,setMoreOpen]=useState(false)
    const location = useLocation()

    return (
        <div className={` relative ${s.container}`}>

            <ul className="flex z-50 relative border-t-2  pad-layout border-t-brown-100   py-4 bg-brown-40 overflow-hidden justify-around">
                <NavLink to={"/chats"}
                         className={({ isActive }) =>
                             isActive ? "navActiveLinkMobile" : ""
                         }
                >
                    <li className={"nav-item "}>
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.52006 18.432L9.57545 19.4874C10.0041 19.916 10.699 19.916 11.1278 19.4874L12.1831 18.432C12.4839 18.1312 12.8911 17.9631 13.3161 17.9631H14.1099C15.7248 17.9631 17.0343 16.6537 17.0343 15.0379V9.90284C17.0343 8.28796 15.7248 6.97852 14.1099 6.97852H6.59418C4.97839 6.97852 3.66895 8.28796 3.66895 9.90284V15.0379C3.66895 16.6537 4.97839 17.9631 6.59418 17.9631H7.38713C7.81209 17.9631 8.21931 18.1312 8.52006 18.432Z" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7.58398 6.97878V6.2316C7.58398 4.61767 8.89155 3.31104 10.512 3.31104H18.0278C19.6417 3.31104 20.9493 4.61767 20.9493 6.2316V11.3675C20.9493 12.988 19.6417 14.2947 18.0278 14.2947H17.2349C17.1676 14.2947 17.1012 14.3022 17.034 14.3087" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12.4563 12.5961V12.6631M12.7279 12.6105C12.7279 12.7613 12.6055 12.8836 12.4547 12.8836C12.3038 12.8836 12.1816 12.7613 12.1816 12.6105C12.1816 12.4596 12.3038 12.3374 12.4547 12.3374C12.6055 12.3374 12.7279 12.4596 12.7279 12.6105Z" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7.95831 12.5961V12.6631M8.22985 12.6105C8.22985 12.7613 8.10748 12.8836 7.95665 12.8836C7.8058 12.8836 7.68359 12.7613 7.68359 12.6105C7.68359 12.4596 7.8058 12.3374 7.95665 12.3374C8.10748 12.3374 8.22985 12.4596 8.22985 12.6105Z" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        Chats
                    </li>
                </NavLink>

                <NavLink to={"/activities"}
                         className={({ isActive }) =>
                             isActive ? "navActiveLinkMobile" : ""
                         }
                >
                    <li className={"nav-item  "}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3066 3.4124L4.61163 12.6341C4.13391 13.2919 4.60385 14.2133 5.41627 14.2133H10.8931V20.0033C10.8931 20.9666 12.1278 21.3684 12.694 20.5871L19.389 11.3664C19.8667 10.7086 19.3968 9.78627 18.5843 9.78627H13.1066V3.99715C13.1066 3.03295 11.8728 2.63209 11.3066 3.4124Z" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        Activities

                    </li>
                </NavLink>
                <NavLink to={"/calendar-and-ideas"}
                         className={({ isActive }) =>
                             isActive ? "navActiveLinkMobile" : ""
                         }
                >
                    <li className={"nav-item  "}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.84955 9.72827H19.6773" stroke="#666363" stroke-width="1.7" stroke-linecap="square"/>
                            <path d="M15.7037 13.1963H15.7122" stroke="#666363" stroke-width="1.7" stroke-linecap="square"/>
                            <path d="M11.7636 13.1963H11.7722" stroke="#666363" stroke-width="1.7" stroke-linecap="square"/>
                            <path d="M7.81481 13.1963H7.82337" stroke="#666363" stroke-width="1.7" stroke-linecap="square"/>
                            <path d="M15.7037 16.6475H15.7122" stroke="#666363" stroke-width="1.7" stroke-linecap="square"/>
                            <path d="M11.7636 16.6475H11.7722" stroke="#666363" stroke-width="1.7" stroke-linecap="square"/>
                            <path d="M7.81481 16.6475H7.82337" stroke="#666363" stroke-width="1.7" stroke-linecap="square"/>
                            <path d="M15.3506 3.15332V6.07554" stroke="#666363" stroke-width="1.7" stroke-linecap="square"/>
                            <path d="M8.17705 3.15332V6.07554" stroke="#666363" stroke-width="1.7" stroke-linecap="square"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.752 5.55566C19.752 5.00338 19.3043 4.55566 18.752 4.55566H4.76799C4.21571 4.55566 3.76799 5.00338 3.76799 5.55566V19.9134C3.76799 20.4657 4.21571 20.9134 4.76799 20.9134H18.752C19.3043 20.9134 19.752 20.4657 19.752 19.9134V5.55566Z" stroke="#666363" stroke-width="1.7" stroke-linecap="square"/>
                        </svg>

                        Calendar

                    </li>
                </NavLink>

                <NavLink to={"/session"}
                         className={({ isActive }) =>
                             isActive ? "navActiveLinkMobile fill" : ""
                         }
                >
                    <li className={"nav-item"}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13 20V22H11V20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V5H22V19C22 19.2652 21.8946 19.5196 21.7071 19.7071C21.5196 19.8946 21.2652 20 21 20H13ZM4 18H20V7H4V18ZM13 9H18V11H13V9ZM13 13H18V15H13V13ZM9 9V12H12C12 12.5933 11.8241 13.1734 11.4944 13.6667C11.1648 14.1601 10.6962 14.5446 10.1481 14.7716C9.59987 14.9987 8.99667 15.0581 8.41473 14.9424C7.83279 14.8266 7.29824 14.5409 6.87868 14.1213C6.45912 13.7018 6.1734 13.1672 6.05764 12.5853C5.94189 12.0033 6.0013 11.4001 6.22836 10.8519C6.45542 10.3038 6.83994 9.83524 7.33329 9.50559C7.82664 9.17595 8.40666 9 9 9ZM2 2H22V4H2V2Z" fill="#666363"/>
                        </svg>

                        Sessions
                    </li>
                </NavLink>

                <div className={classNames(
                    location.pathname == "/courses" || location.pathname == "/videos" ||
                    location.pathname == "/news-and-trends"|| location.pathname == "/cryptocurrencies"||
                    location.pathname == "/faq-and-resources"|| location.pathname == "/mentors" ||location.pathname == "/profile"
                        ?
                        "navActiveLinkMobile fill":"",
                )}>
                    <li onClick={()=>setMoreOpen(true)} className={"nav-item cursor-pointer"}>
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.4902 3.89737C5.47468 2.84117 6.87669 2.25 8.53216 2.25H16.9669C18.6262 2.25 20.0288 2.84084 21.0129 3.89765C21.9913 4.94841 22.5 6.39889 22.5 8.02638V15.9736C22.5 17.6011 21.9913 19.0516 21.0127 20.1024C20.0285 21.1592 18.6256 21.75 16.9659 21.75H8.53216C6.8728 21.75 5.47038 21.1591 4.48657 20.1023C3.50842 19.0515 3 17.601 3 15.9736V8.02638C3 6.39806 3.51117 4.94771 4.4902 3.89737ZM5.58745 4.92012C4.91024 5.64666 4.5 6.70951 4.5 8.02638V15.9736C4.5 17.2914 4.90861 18.3541 5.58451 19.0802C6.25475 19.8003 7.24341 20.25 8.53216 20.25H16.9659C18.2552 20.25 19.2444 19.8002 19.915 19.0801C20.5912 18.354 21 17.2913 21 15.9736V8.02638C21 6.70868 20.5912 5.64597 19.9151 4.91984C19.2446 4.19975 18.2556 3.75 16.9669 3.75H8.53216C7.24828 3.75 6.25921 4.19943 5.58745 4.92012Z" fill="#666363"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.38672 9.39453C8.38672 8.98032 8.72251 8.64453 9.13672 8.64453H16.3659C16.7801 8.64453 17.1159 8.98032 17.1159 9.39453C17.1159 9.80874 16.7801 10.1445 16.3659 10.1445H9.13672C8.72251 10.1445 8.38672 9.80874 8.38672 9.39453ZM8.38672 14.6034C8.38672 14.1891 8.72251 13.8534 9.13672 13.8534H16.3659C16.7801 13.8534 17.1159 14.1891 17.1159 14.6034C17.1159 15.0176 16.7801 15.3534 16.3659 15.3534H9.13672C8.72251 15.3534 8.38672 15.0176 8.38672 14.6034Z" fill="#666363"/>
                        </svg>
                        More
                    </li>
                </div>



            </ul>

            <ReactSwipeEvents onSwipedDown={()=>setMoreOpen(false)}>
                <MoreOptions isMoreOpen={!isMoreOpen} setMoreOpen={setMoreOpen}/>
            </ReactSwipeEvents>


        </div>

    );
};

export default MobileNav;
