import React from 'react';
import s from './styles.module.scss'
import classNames from "classnames";
import {useNavigate} from "react-router-dom";
import MoreBtn from "../../../Sections/MoreBtn";
import {useMutation, useQueryClient} from "react-query";
import axiosClient from "../../../Api/apiClient";
import CustomSpinner from "../../../Utility/CustomSpinner";
import AdminCanView from "../../../Components/ViewLimit/AdminCanView";
import BookmarkIcon from "../../../Components/BookmarkIcon";
import BtnTextForMutation from "../../../Components/BtnTextForMutation";
import {useGetProfile} from "../../../Hooks/useGetProfile";
const CourseBox = ({data}) => {
    let navigate = useNavigate();
    const {is_bookmarked:isBookmarked ,cover_file_id:coverId,author,id,cover_file_url:coursePic,title,content:desc,status} = data
    const {name,profile_image_url:profilePic,occupation:role} = author
    const isDraft = status == "draft"

    const handleViewCourse =()=>{
        navigate(`/courses/${id}`)
    }
    const queryClient = useQueryClient();
    const deleteMutation =useMutation(
        (id) => axiosClient.delete(`/courses/${id}`),
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries("/courses")
                navigate("/courses")
            },
        })
    const deleteVideo =()=>{
        deleteMutation.mutate(id)
    }
    const editVideo =()=>{
        navigate(`/courses/edit/${id}`)
    }

    const publishCourseMutation = useMutation({
        mutationFn: (course)=>axiosClient.put(`/courses/${id}`, course),
        onSuccess: (data) => {
            queryClient.invalidateQueries("/courses")
        },
    })
    const handlePublish =()=>{
        const  data ={
            "title": title,
            "content": desc,
            "cover_file_id" : coverId,
            "status" : "published"
        }
        publishCourseMutation.mutateAsync(data).then(()=>{
            navigate("/courses")
        })
    }

    const me = useGetProfile()
    const isMine = me.id == author.id

    return (
        <div className={s.container} style={{cursor:isDraft?"pointer":""}} onClick={()=>{
            if (isDraft && isMine) {
                editVideo()
            }
        }}>
            <div className={classNames("image-container overflow-hidden")}>
                <img src={coursePic} className={"w-full h-full object-cover"} alt=""/>
            </div>
            <div className={"title text-18 font-medium mt-6 mb-3"}>
                {title}
            </div>
            {/*<div className="desc text-gray-50 text-12 pb-6 border-b-1 border-brown-110">*/}
            {/*    {desc}*/}
            {/*</div>*/}
            <div className="profile items-center flex gap-4 my-6">
                <div className="avatar shrink-0 w-12 h-12 aspect-square bg-white overflow-hidden rounded-full">
                        <img src={profilePic?profilePic : "/media/defaultAvatar.png"} className={"w-full h-full object-cover"} alt=""/>

                </div>
                <div className="text">
                    <div className="name mb-2 font-bold">
                        {name}
                    </div>
                    {role &&
                        <div className="role text-gray-50 text-12 h-6 px-3 bg-brown-50 w-fit">
                            {role}
                        </div>
                    }
                </div>
            </div>
            {isDraft?
                <div className={"flex gap-4 w-full"}>
                    <button onClick={deleteVideo} className="btn red cta">
                        <BtnTextForMutation defaultText={"Delete"} successText={"Deleted!"} mutation={deleteMutation} errorText={"error"}/>
                    </button>
                    <button onClick={handlePublish} className="cta btn grey-50 flex-grow">
                        <BtnTextForMutation defaultText={"Publish"} mutation={publishCourseMutation} successText={"Published!"}/>
                    </button>
                </div>
                :
                <div className={"flex justify-between items-center "}>
                    <button onClick={handleViewCourse} className="btn  cta">
                        View course
                    </button>
                    <div className={"items-center flex gap-2"}>
                        <BookmarkIcon isBookmarked={isBookmarked} bookmarkKey={"Course"} bookmarkableId={id} query={"/courses"}/>
                        <AdminCanView>
                            <MoreBtn options={[
                                {name: "Delete", fn: deleteVideo, mutation: deleteMutation,isHidden:!(isMine||me.isOwner)},
                                {name: "Edit", fn: editVideo , isHidden: !isMine},
                            ]}/>
                        </AdminCanView>
                    </div>


                </div>
            }

        </div>
    );
};

export default CourseBox;
