import React, {useState} from 'react';
import LoginLayout from "../LoginLayout";
import PasswordInput from "../../../Utility/PasswordInput";
import {useMutation} from "react-query";
import axiosClient from "../../../Api/apiClient";
import CustomSpinner from "../../../Utility/CustomSpinner";
import {Link, useNavigate} from "react-router-dom";
import PageTitle from "../../../Components/PageTitle/PageTitle";

const RegisterPage = () => {
    PageTitle("Pre register")

    const [formData,setFormData]=useState({
        username:"",
        email:"",
        phone_number:"",
        name: "",
        occupation:"",
        bio:""
    })
    const handleChangeInput =(e)=>{
        const {value,name} = e.target
        setFormData(prev=>({...prev,[name]:value}))
    }
    const [errorMessage,setErrorMessage] = useState("")
    const navigate = useNavigate()
    const registerMutation = useMutation({
        mutationFn: (data)=>axiosClient.post('/pre-register', data),
        onSuccess: async (res) => {
            setErrorMessage("")
        },
        onError:(err)=>{
            setErrorMessage(err.response.data.error.error)
        }
    })
    const SubmitHandler =()=>{

        registerMutation.mutateAsync(formData).then(res=>{
            setTimeout(()=>{
                navigate("/thanks-for-registering")
            },2000)
        }).catch(err=>console.log(err))
    }
    return (
        <LoginLayout  imgPath={"3dIcons/19.png"}>
            <div className={"flex flex-col items-center"}>
                <h2 className={" whitespace-nowrap md:text-center mb-2.5 text-[56px] font-medium lg:mb-5"}>Request an invite</h2>
                <p className="w-full  md:text-center text-14 mb-6 text-[#D9D9D9]">For questions about the approval process of our services
                    and specific orders, email us at info@laguire.ae</p>
                <div className="form w-full">
                    <div className="input-box">
                        <div className="label" > Email</div>
                        <input  value={formData.email} onChange={handleChangeInput} name={"email"} placeholder={""} type="email"/>
                    </div>
                    <div className="input-box">
                        <div className="label" >Full name</div>
                        <input  value={formData.name}  onChange={handleChangeInput} name={"name"} placeholder={""} type="text"/>
                    </div>
                    <div className="input-box">
                        <div className="label" >Username</div>
                        <input  value={formData.username}  onChange={handleChangeInput} name={"username"} placeholder={""} type="text"/>
                    </div>
                    <div className="input-box">
                        <div className="label" >Phone number</div>
                        <input  value={formData.phone_number} onChange={handleChangeInput} name={"phone_number"} placeholder={"ex. +1  (234) 567 8900"} type="number"/>
                    </div>

                    <div className="input-box">
                        <div className="label"> Occupation</div>
                        <input  value={formData.occupation} onChange={handleChangeInput} name={"occupation"} placeholder={""} type="text"/>
                    </div>
                    <div className="input-box">
                        <div className="label"> Bio</div>
                        <textarea className={"h-[140px]"} value={formData.bio} onChange={handleChangeInput} name={"bio"} placeholder={""} type="text"/>
                    </div>
                    {!registerMutation.isLoading && errorMessage &&
                        <div className={"text-red-secondary"}>
                            {errorMessage}
                        </div>
                    }
                    <button className={"w-full submit-btn cursor-pointer mt-2 btn grey-90"} onClick={SubmitHandler} >
                        {registerMutation.isLoading?
                            <div>
                                <CustomSpinner size={9}/>
                            </div>
                        :
                            registerMutation.isSuccess?
                                "Request Sent"
                                :
                                "Pre register"
                        }
                    </button>

                    <div className="flex gap-4 items-baseline justify-center">
                        <p>
                            Already have an account?
                        </p>
                        <Link to={"/login"}>
                            <h6 className=" cursor-pointer text-14">
                               Login
                            </h6>
                        </Link>
                    </div>
                </div>
            </div>
        </LoginLayout>

    );
};

export default RegisterPage;
