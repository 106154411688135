import React, {useEffect, useState} from 'react';
import ThreadBox from "../ThreadBox";
import {useInfiniteQuery, useQuery} from "react-query";
import axiosClient from "../../../Api/apiClient";
import {useInView} from "react-intersection-observer";
import CustomSpinner from "../../../Utility/CustomSpinner";
import {BarLoader, BeatLoader} from "react-spinners";
import {active} from "sortablejs";
import {useAlert} from "react-alert";


const ThreadsList = ({badge,category,searchThreadKeyword}) => {
    const alert = useAlert();
    const { data : threadsData ,fetchNextPage,hasNextPage,isFetchingNextPage} = useInfiniteQuery({
        queryKey:[`/threads`,searchThreadKeyword,badge,category],
        cacheTime:0,
        staleTime:0,
        onError:()=>{
            alert.error("Error occurred")
        },
        queryFn: async ({ pageParam =1 }) => {
            const res = await axiosClient.get(`/threads?page=${pageParam}${searchThreadKeyword != ""?`&search=${searchThreadKeyword}`:""}${badge.toLowerCase()=="all"?"":`&badge=${badge}`}${category.toLowerCase()=="all"?"":`&category=${category}`}`);
            return {
                data: [...res.data.resulting_data.data] ,
                page:pageParam,
                last_page:  res.data.resulting_data.meta?.last_page
            }
        },
        getNextPageParam:(lastPage)=>{
            if (lastPage?.page < lastPage?.last_page){
                return lastPage?.page + 1
            }
        }
    })




    let threadsList = []

    threadsData.pages.map(i=>
        i.data?.map(item=>{
            threadsList.push(item)
        })
    )




    const { ref, inView } = useInView();
    useEffect(()=>{
        if(inView && hasNextPage){
            fetchNextPage()
        }
    },[inView,hasNextPage ])


    return (
            <div className="main bg-brown-80  flex-grow  overflow-auto   grid  lg:gap-3 ">
                <div className="grid    h-fit gap-[2px] bg-brown-80">
                    {threadsList?.length == 0 ?
                        <div
                            className="bg-brown-80 text-gray-50 font-medium text-18 h-full min-h-[500px] flex items-center justify-center flex-col gap-2 ">
                            <svg width="112" height="112" viewBox="0 0 112 112" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <circle cx="56" cy="56" r="56" fill="#342E2E"/>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M55.0984 22.7642C73.5219 22.7642 88.4611 37.6998 88.4611 56.127C88.4611 74.5504 73.5219 89.4897 55.0984 89.4897C36.6714 89.4897 21.7357 74.5504 21.7357 56.127C21.7357 37.6998 36.6714 22.7642 55.0984 22.7642Z"
                                      stroke="#A6A6A6" stroke-width="1.89831" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                                <path d="M55.0807 42.4346V58.373" stroke="#A6A6A6" stroke-width="1.89831"
                                      stroke-linecap="square" stroke-linejoin="round"/>
                                <path d="M55.0803 69.8174H55.1174" stroke="#A6A6A6" stroke-width="1.89831"
                                      stroke-linecap="square" stroke-linejoin="round"/>
                            </svg>
                            No matches found
                        </div>
                        :
                        <>

                                {threadsList?.map(item => {
                                        return (
                                            <ThreadBox data={item}/>
                                        )
                                })}
                            <div className={"h-[1px]"} ref={ref}>
                                {
                                    isFetchingNextPage &&
                                        <div className={"w-full  flex justify-center p-2 bg-brown-40 "}>
                                            <BeatLoader color={"#524848"}/>
                                        </div>
                                }
                            </div>

                        </>
                    }
                </div>
          </div>

    );
};

export default ThreadsList;
