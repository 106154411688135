import React, {useRef, useState} from 'react';
import useOnClickOutside from "../../../Hooks/useOnClickOutside";
import {FiltersList} from "../FiltersList";
import DropdownContainer from "../../../Utility/DropdownContainer";

const FilterButton = ({FiltersList,activeFilter,setActiveFilter}) => {
    const [isFilterOpen,setIsFilterOpen]=useState(false)


    return (
        <div className={"relative"}>
            <div onClick={()=>{
                setIsFilterOpen(true)
            }} className="filter-btn cursor-pointer flex items-center h-10 bg-brown-90 px-3  rounded-8 border-brown-90 border-2 flex gap-3">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.11523 7.54297H18.3716" stroke="#D9D9D9" stroke-width="1.44" stroke-linecap="square"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.13005 7.48546C9.13005 6.10106 7.99942 4.97852 6.60506 4.97852C5.21071 4.97852 4.08008 6.10106 4.08008 7.48546C4.08008 8.86985 5.21071 9.9924 6.60506 9.9924C7.99942 9.9924 9.13005 8.86985 9.13005 7.48546Z" stroke="#D9D9D9" stroke-width="1.44" stroke-linecap="square"/>
                    <path d="M14.4048 16.7715H5.14844" stroke="#D9D9D9" stroke-width="1.44" stroke-linecap="square"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3896 16.714C14.3896 15.3296 15.5203 14.207 16.9146 14.207C18.309 14.207 19.4396 15.3296 19.4396 16.714C19.4396 18.0984 18.309 19.2209 16.9146 19.2209C15.5203 19.2209 14.3896 18.0984 14.3896 16.714Z" stroke="#D9D9D9" stroke-width="1.44" stroke-linecap="square"/>
                </svg>
                Filters
            </div>
            {isFilterOpen &&
               <DropdownContainer className={"w-[150%]"} setIsDropOpen={setIsFilterOpen}>
                   {FiltersList.map(item=>{
                       return(
                           <button onClick={()=> {
                               setActiveFilter(item)
                               setIsFilterOpen(false)
                           }} className={`filter-item ${activeFilter == item ? "active":""}`}>{item}</button>
                       )})
                   }
               </DropdownContainer>
            }
        </div>
    );
};

export default FilterButton;
