import React, {useEffect, useState,Suspense} from 'react';
import s from "./styles.module.scss"
import classNames from "classnames";
import MobilePinnedMainHeader from "../../../Sections/MobilePinnedMainHeader";
import {useMutation, useQuery} from "react-query";
import axiosClient from "../../../Api/apiClient";
import CustomSpinner from "../../../Utility/CustomSpinner";
import {Link, useNavigate} from "react-router-dom";
import BtnTextForMutation from "../../../Components/BtnTextForMutation";
import PageTitle from "../../../Components/PageTitle/PageTitle";
import {useAlert} from "react-alert";
const CryptoCurrencyCreate = () => {
    // const [filteredList ,setFilteredList]=useState(null)
    PageTitle("Add cryptocurrency")

    const [titleValue,setTitleValue]=useState("")
    const [searchedCoin,setSearchedCoin]=useState("")
    const [coinsAdded,setCoinsAdded]=useState([])
    const alert = useAlert()

    const [titleLetters,setTitleLetters]=useState(0);
    const checkTitleLimit =(e)=>{
        setTitleLetters(e.target.value.length)
        setTitleValue(e.target.value)
    }




    const AddedCoinItem =({title,desc,id,img})=>{
        const removeCoin=()=>{
            setCoinsAdded(coinsAdded.filter((i,index)=>(i.id !== id)))
        }
        return(
            <div className={"coin bg-brown-50 px-2 flex justify-between items-center py-5 border-b-1 border-brown-110 "}>
                <div className={"flex gap-2 items-center"}>
                    <div className="sqr rounded-8  bg-brown-110 items-center overflow-hidden rounded-full flex justify-center w-fit mr-3">
                        <img src={img} className={"w-8 h-8 object-contain "} alt=""/>
                    </div>
                    <div className={"text-gray-50 mr-2 font-medium"}>
                        {title}
                    </div>
                    <div className={"text-12 text-gray-90 px-2 py-1  rounded-30 bg-brown-50"}>
                        {desc}
                    </div>
                </div>
                <div className={"flex gap-4 items-center"}>
                    <div onClick={removeCoin} className="remove cursor-pointer">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.5 4.5L13.5 13.5" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                            <path d="M13.5 4.5L4.5 13.5" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                        </svg>
                    </div>

                </div>

            </div>
        )
    }




    const CoinsList =()=>{



        const { data  } = useQuery({
            queryKey:"/coins",
        })
        const CoinItem =({title,desc,id,img})=>{
            const AddToCoins =()=>{
                if (coinsAdded.length < 5){
                    if (coinsAdded.filter((i,index) =>i.id === id).length === 0){
                        setCoinsAdded(prev=>[...prev,data.resulting_data.filter((item)=>item.id === id)[0]])
                    }
                }else{
                    alert.error("You can add only 5 coins to each bundle.")
                }

            }
            return(
                <div className={"coin flex justify-between items-center py-5 border-b-1 border-brown-110 "}>
                    <div className={"flex gap-2 items-center"}>
                        <div className="circle w-8 h-8 rounded-full overflow-hidden bg-green mr-3">
                            <img src={img} className={"w-full h-full object-contain "} alt=""/>
                        </div>
                        <div className={"text-gray-50 mr-2 font-medium"}>
                            {title}
                        </div>
                        <div className={"text-12 text-gray-90 px-2 py-1  rounded-30 bg-brown-50"}>
                            {desc}
                        </div>
                    </div>
                    <div onClick={AddToCoins} className={"flex cursor-pointer gap-2 items-center px-3 py-2 bg hover:bg-gray-50 rounded-8 border-1 border-brown-90 font-semibold text-gray-90 hover:text-brown-40"}>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 1V11" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                            <path d="M11 6H1" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        </svg>
                        Add
                    </div>

                </div>
            )
        }
        return(
            <div>
                {data.resulting_data.filter(coin=>coin?.title?.toLowerCase().includes(searchedCoin?.toLowerCase()))?.map((coin,index)=>
                    <div key={coin.id}>
                        <CoinItem img={coin.url} id={coin.id} title={coin.title} desc={coin.code}/>
                    </div>
                )}
            </div>
        )
    }

    const navigate = useNavigate()

    const mutation = useMutation({
        mutationFn: (data)=>axiosClient.post(`/bundles`, data),
        onSuccess: async (res) => {
            setTimeout(()=>{
                navigate("/cryptocurrencies")
            },2000)
        },
        onSettled:()=>{
            setTimeout(()=>{
                mutation.reset()
            },2000)
        }
    })

    const handleSubmit =()=>{
        if(!mutation.isLoading && !mutation.isSuccess) {

            const arrIds = [];
            coinsAdded.map(i => arrIds.push(i.id))
            mutation.mutate({
                title: titleValue,
                coin_ids: arrIds
            })
        }
    }
    return (
        <div className={classNames(s.container,"flex flex-col h-full")}>
            <MobilePinnedMainHeader title={"Cryptocurrencies"} hasBackBtn/>
            <div className="flex-grow overflow-hidden flex flex-col  p-8">
                <div className="title-line mb-6">
                    <h3 className={"text-24 font-semibold mb-2"}>Adding bundle</h3>
                    <div className={"font-medium text-gray-90"}>
                        This channel will be updated monthly with new investing indexes
                    </div>
                </div>
                <div className="box flex-grow overflow-hidden flex gap-10 p-8 pr-5 pb-0">
                    <div className="left w-[45%] h-full flex flex-col">
                        <div className={"text-18 text-gray-50 mb-8 font-medium"}>
                            Create a coin bundle
                        </div>
                        <div className="input-box mb-10 flex flex-col gap-3">
                            <label className="label">Title </label>
                            <div className={"relative w-full"}>
                                <input className={"w-full pr-20"} maxLength="300" value={titleValue} onInput={checkTitleLimit} placeholder={"Type here"} type="text"/>
                                <div className={"absolute right-4 top-0 bottom-0 flex items-center text-12 text-gray-90 m-auto z-20"}>
                                    {titleLetters}/300
                                </div>
                            </div>

                        </div>
                        <div className={"coins-wrapper flex-grow flex p-2 overflow-hidden mb-10 "}>
                            {coinsAdded.length === 0 ?
                            <div className="empty h-full w-full justify-center p-6 gap-3 text-gray-90 font-medium items-center flex flex-col">
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M42 24C42 14.0582 33.9418 6 24 6C14.0582 6 6 14.0582 6 24C6 33.9398 14.0582 42 24 42C33.9418 42 42 33.9398 42 24Z" stroke="#A6A6A6" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M23.9922 16.6133V25.2124M23.9998 31.4836V31.3882" stroke="#A6A6A6" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <div className={"max-w-[300px] text-center"}>
                                    Please choose the coins that you would like to post.
                                </div>
                            </div>
                            :
                            <div className="added-coins overflow-auto flex-grow px-4 ">
                                {/*<ReactSortable*/}
                                {/*    animation={200}*/}
                                {/*    delayOnTouchStart={true}*/}
                                {/*    delay={2}*/}
                                {/*    multiDrag*/}
                                {/*    list={coinsAdded} setList={setCoinsAdded}*/}
                                {/*>*/}

                                {coinsAdded?.map((item)=>{
                                   return(
                                       <div key={item.index}>
                                           <AddedCoinItem img={item.url} title={item.title} desc={item.code} id={item.id}/>
                                       </div>
                                   )
                                })}
                                {/*</ReactSortable>*/}
                            </div>
                            }
                        </div>
                    </div>
                    <div className="right flex overflow-hidden flex-col flex-grow">
                        <div className="first-line mb-8 pr-3  flex justify-between font-medium">
                            <span className={"text-18 text-gray-50"}>Select coin <span className={"text-12"}>(max 5)</span></span>
                            <span className={"text-gray-90"}>
                                {coinsAdded.length} coin added
                            </span>
                        </div>
                        <div className={"flex pr-3 w-full relative"}>
                            <div className="icon absolute my-auto bottom-0 top-0 left-4 h-fit">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.31131 14.0511C11.5701 14.0511 14.2119 11.4093 14.2119 8.15054C14.2119 4.89176 11.5701 2.25 8.31131 2.25C5.05253 2.25 2.41077 4.89176 2.41077 8.15054C2.41077 11.4093 5.05253 14.0511 8.31131 14.0511Z" stroke="#A6A6A6" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12.4252 12.5938L15.5893 15.7496" stroke="#A6A6A6" stroke-width="1.7" stroke-linecap="square" stroke-linejoin="round"/>
                                </svg>

                            </div>
                            <input value={searchedCoin} onInput={(e)=>{setSearchedCoin(e.target.value)}} className={"w-full pl-12"} placeholder={"Search in coins"} type="text"/>

                        </div>
                        <div className="coins flex flex-col flex-grow mt-10 overflow-auto pr-3 ">
                            <Suspense fallback={"loading"}>
                                <CoinsList/>
                            </Suspense>
                        </div>
                    </div>
                </div>
                <div className={"flex-col-reverse mt-8 w-full lg:w-fit lg:flex-row flex gap-4 ml-auto"}>
                    <Link to={"/cryptocurrencies"}  className="btn w-full lg:w-[170px]">Cancel</Link>
                    <button disabled={!titleValue || !coinsAdded.length} onClick={handleSubmit} className="btn grey-50 w-full lg:w-[170px]">
                      <BtnTextForMutation defaultText={"Publish"} successText={"Published!"} mutation={mutation}/>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CryptoCurrencyCreate;
