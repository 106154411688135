import React, {Suspense, useState} from 'react';
import useUploadAttachment from "../../../Hooks/useUploadAttachment";
import {useDropzone} from "react-dropzone";
import {useMutation, useQuery} from "react-query";
import axiosClient from "../../../Api/apiClient";
import s from "../AddNewCourse/style.module.scss";
import MobilePinnedMainHeader from "../../../Sections/MobilePinnedMainHeader";
import ReactQuill from "react-quill";
import CustomSpinner from "../../../Utility/CustomSpinner";
import {useEditGetAndPost} from "../../../Hooks/useEditGetAndPost";
import {Link} from "react-router-dom";
import {BeatLoader, CircleLoader, ClipLoader, PulseLoader} from "react-spinners";
import PageTitle from "../../../Components/PageTitle/PageTitle";
import BtnTextForMutation from "../../../Components/BtnTextForMutation";

const CourseEdit = () => {
    PageTitle("Edit")

    const BoxContent=()=>{
        const {id,mutation:uploadImgMutation} = useUploadAttachment()
        const uploadFile =(file)=>{
            return uploadImgMutation.mutateAsync({attach_type:"courses",file: file})
        }

        const MyProfileEl =()=>{
            const {data} = useQuery("/auth/me",{
                refetchOnWindowFocus:false,
                cacheTime:Infinity,
                staleTime:Infinity
            })
            const me = data.resulting_data
            return(
                <div className="profile items-center flex gap-4 my-6">
                    <div className="avatar shrink-0 w-12 h-12 aspect-square overflow-hidden bg-white rounded-full">
                        <img src={me.profile_image_url?me.profile_image_url:"/media/defaultAvatar.png"} className={"w-full h-full object-center object-cover"} alt="profile_img"/>
                    </div>
                    <div className="text">
                        <div className="name mb-2 font-bold">
                            {me.name}
                        </div>
                        <div className="role text-gray-50 text-12 h-6 px-3 bg-brown-50 w-fit">
                            {me.occupation}
                        </div>
                    </div>
                </div>
            )
        }



        const [fillStep,setFillStep]=useState(0)

        const [addedFile,setAddedFile] = useState(null)

        const { getRootProps, getInputProps} = useDropzone({
            multiple:false,
            onDrop: async files => {
                setAddedFile(files[0])
                await uploadFile(files[0])
            }
        });


        const {fetchedData,handleSubmitEdit,postMutation} = useEditGetAndPost("/courses")

        const [value, setValue] = useState(fetchedData.content);
        const [title,setTitle]=useState(fetchedData.title)


      const  handleSubmit=(status)=>{
           handleSubmitEdit({
               "title": title,
               "content": value,
               "cover_file_id" : id ? id : fetchedData.cover_file_id,
               "status" : status
           })
       }




        return(
            <>
                <div className={"editor-wrapper overflow-auto flex-grow gap-6 flex flex-col py-4 xl:py-10 px-8"}>
                    {fillStep === 0 ?
                        <>
                            <div className={"flex flex-col gap-3"}>
                                <label htmlFor="title" className={"label"}>Title *</label>
                                <input value={title} onInput={(e)=>setTitle(e.target.value)} type="text" name={"title"} placeholder={"Type title"}/>
                            </div>
                            <div className={"flex flex-col flex-grow gap-3 mt-6"}>
                                <label htmlFor="Content" className={"label"}>Content</label>
                                <ReactQuill theme="snow" value={value} onChange={setValue}
                                            className={"!h-3/5 "}
                                            modules={{
                                                toolbar: {
                                                    container: [
                                                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                                        ['bold', 'italic', 'underline'],
                                                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                        [{ 'align': [] }],
                                                        ['link', 'image'],
                                                        ['clean'],
                                                        [{ 'color': [] }]
                                                    ],
                                                    // handlers: {
                                                    //     image: this.imageHandler
                                                    // }
                                                },
                                            }}
                                />
                            </div>
                        </>
                        :
                        <div className={"h-full w-full flex gap-12 justify-around mb-20 xl:gap-20"}>
                            <div className=" w-1/2">
                                <div className={"box-title font-medium mb-6"}>Upload Cover</div>

                                <form className="mt-4">
                                    <div {...getRootProps({className: '   mb-4 dropzone'})}>
                                        <input {...getInputProps()} />
                                        <div className="upload-box">
                                            <div>
                                                <svg width="43" height="39" viewBox="0 0 43 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.5 27.5L21.5 19.5M21.5 19.5L29.5 27.5M21.5 19.5V37.5M37.5 28.9857C39.943 26.9681 41.5 23.9159 41.5 20.5C41.5 14.4249 36.5751 9.5 30.5 9.5C30.063 9.5 29.6541 9.27199 29.4322 8.89548C26.8241 4.46967 22.0089 1.5 16.5 1.5C8.21573 1.5 1.5 8.21573 1.5 16.5C1.5 20.6322 3.17089 24.3742 5.8739 27.0871" stroke="url(#paint0_linear_6438_5903)" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <defs>
                                                        <linearGradient id="paint0_linear_6438_5903" x1="21.5" y1="-18.8684" x2="21.5" y2="19.5" gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="white"/>
                                                            <stop offset="1" stop-color="#D9D9D9"/>
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                            </div>
                                            <div>
                                                Click to upload or drag and drop
                                            </div>

                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className={"h-full flex flex-col"}>
                                <div className="box-title">
                                    Preview


                                </div>
                                <div className="preview-box flex-grow flex flex-col">
                                    <div className="cover relative  !h-44 flex flex-grow justify-center items-center w-full ">
                                        <div className={"absolute top-3 left-3"}>
                                            {
                                                uploadImgMutation.isLoading &&
                                                    <div className={"rounded-full bg-brown-90 aspect-square w-12 scale-75 h-13 -translate-x-1/2 -translate-y-1/2 grid place-items-center"}>
                                                        <ClipLoader color={"#D9D9D9"}/>
                                                    </div>

                                            }
                                        </div>
                                        {!addedFile?
                                            <img className={"w-full h-full rounded-8 object-cover"} src={fetchedData.cover_file_url} alt=""/>
                                            :
                                            <img className={"w-full h-full rounded-8 object-cover"} src={URL.createObjectURL(addedFile)} alt=""/>
                                        }
                                    </div>
                                    <div className="prev-title  text-18 font-medium mt-5">
                                        {title}
                                    </div>
                                    {/*<div className="prev-desc text-14 whitespace-nowrap overflow-hidden text-clip mt-2 text-gray-50">*/}
                                    {/*    <div dangerouslySetInnerHTML={{__html: value}}></div>*/}
                                    {/*</div>*/}
                                    <Suspense>
                                        <MyProfileEl/>
                                    </Suspense>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="btn-box flex gap-2  ml-auto mt-8">
                    {
                        fillStep==0?
                            <>
                                <Link to={"/courses"}>
                                    <button   className="btn !bg-brown-100 ">
                                        cancel
                                    </button>
                                </Link>

                                <button disabled={!title || !value} onClick={()=>setFillStep(prev=>prev+1)}  className="btn w-[160px] !bg-gray-50 !text-black">
                                    Next
                                </button>
                            </>
                            :
                            <>

                                <button onClick={()=>setFillStep(0)}  className="btn !bg-brown-100 ">
                                    Back
                                </button>
                                <button onClick={()=> handleSubmit("draft")}  className="btn !bg-brown-100">
                                    <BtnTextForMutation errorText={"error"} mutation={postMutation} successText={"Saved!"} defaultText={"Save draft"}/>
                                </button>
                                <button onClick={()=> handleSubmit("published")}  className="btn w-[160px] !bg-gray-50 !text-black">
                                    <BtnTextForMutation errorText={"error"} mutation={postMutation} successText={"Published!"} defaultText={"Publish"}/>
                                </button>
                            </>


                    }

                </div>
            </>
        )
    }
    return (
        <div className={s.container}>
            <MobilePinnedMainHeader  title={"Edit"}/>
            <div className="plain flex h-full overflow-auto flex-grow flex-col overflow-hidden p-8">
                <div className="title text-24 mb-2 font-medium">
                    Edit <span className={"font-bold"}>Course</span>
                </div>
                <div className="desc text-14 mb-8 text-gray-90">
                    Edit your <span className={"font-bold"}>Courses</span>
                </div>
                <Suspense fallback={"Loading..."}>
                    <BoxContent/>
                </Suspense>
           </div>
        </div>
    );
};

export default CourseEdit;
