import React, {Suspense} from 'react';
import classNames from "classnames";
import MobilePinnedMainHeader from "../../../Sections/MobilePinnedMainHeader";
import s from "./styles.module.scss"
import {useLocation} from "react-router-dom";
import {useQuery} from "react-query";
import {FullPageLoading} from "../../../Components/LoadingPage";
import {format} from "date-fns";
import PageTitle from "../../../Components/PageTitle/PageTitle";
const CourseInfoPage = () => {
    PageTitle("Course")

    const location = useLocation()
    const CourseInfo =()=>{
        const {data} = useQuery(location.pathname)
        const courseData = data.resulting_data
        return(
            <>
                <div className={"py-8 border-b-2 border-brown-100 gap-3  flex flex-col md:flex-row md:items-center md:justify-between"}>
                    <div>
                        <div className="title text-32 font-bold mb-3 max-w-[800px]">
                            {courseData.title}
                        </div>
                        {/*<div className={"text-gray-50 font-medium"}>*/}
                        {/*    {courseData.content}*/}
                        {/*</div>*/}
                    </div>
                    <div className={"w-fit mt-2 md:mt-0"}>
                        <div className="flex items-center gap-2">
                            <div className="circle w-8 h-8 overflow-hidden rounded-full bg-white">
                                    <img src={courseData.author.profile_image_url?courseData.author.profile_image_url:"/media/defaultAvatar.png"} className={"w-full h-full object-center object-cover"} alt=""/>
                            </div>
                            <span className={"text-14"}>{courseData.author.name}</span>
                        </div>
                        <div className={"text-12 text-gray-90 bg-brown-50 px-3 py-2 rounded-8 mt-3"}>
                            {format(new Date(courseData.created_at),"LLLL dd ,yyyy")}
                        </div>
                    </div>
                </div>
                <div dangerouslySetInnerHTML={{__html:courseData.content}} className={"content pt-3 md:overflow-auto flex-grow"}>

                </div>
            </>
        )
    }
    return (
        <div className={classNames("w-full flex flex-col h-full ",s.container)}>
            <MobilePinnedMainHeader hasBackBtn title={"Courses"}/>
            <div className={"content-wrapper px-8 flex flex-col overflow-auto md:overflow-hidden bg-brown-40 flex-grow w-full"}>
                <Suspense fallback={<FullPageLoading/>}>
                    <CourseInfo/>
                </Suspense>
           </div>
        </div>
    );
};

export default CourseInfoPage;
