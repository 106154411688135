import './App.scss';
import React, {useEffect,Suspense} from "react";
import {QueryClient, QueryClientProvider, useQuery} from 'react-query'
import axiosClient from "./Api/apiClient";

import RoutesComponent from "./Pages/RoutesComponent";
import {atom, useAtom} from "jotai";
import {FullPageLoading} from "./Components/LoadingPage";

const defaultQueryFn = async ({ queryKey }) => {
    const { data } = await axiosClient.get(`${queryKey[0]}`)
    return data
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            queryFn: defaultQueryFn,
            suspense: true,
            refetchOnWindowFocus:false
        },
    },
})
export const permissionAtom = atom("admin")
export const tokenAtom = atom(localStorage.getItem("token"))
export const meAtom = atom(false)


function App() {
    const [token]=useAtom(tokenAtom)

    useEffect(()=>{
        if (token){
            localStorage.setItem("token",token)
        }else{
            localStorage.removeItem("token")
        }

        if (!token ){
            if (!(window.location.pathname ==  "/login" || window.location.pathname ==  "/pre-register" || window.location.pathname == "/new-password" || window.location.pathname == "/forgot_password"|| window.location.pathname == "/user-added"|| window.location.pathname == "/user-rejected")){
                window.location.pathname =  "/login"
            }
        }
    },[token,window.location])




  return (
      <QueryClientProvider client={queryClient}>
          <Suspense fallback={<div className={"w-full h-screen grid place-items-center bg-brown-80"}><FullPageLoading/></div>}>
              <RoutesComponent/>
          </Suspense>
      </QueryClientProvider>
  );
}

export default App;
