import React from 'react';
import {useAtom} from "jotai";
import {permissionAtom} from "../../App";
import {useGetProfile} from "../../Hooks/useGetProfile";

const
    SuperAdminCanView = ({children}) => {
    const me= useGetProfile()

    if ( me.roles.includes("super_admin")) return children
    else
        return null
};

export default SuperAdminCanView;
