import React from 'react';
import {useMutation, useQueryClient} from "react-query";
import axiosClient from "../../Api/apiClient";
import CustomSpinner from "../../Utility/CustomSpinner";

const LikeIcon = ({isLiked,query,likeableId,likes}) => {
    const queryClient = useQueryClient();

    const postLikeMutation = useMutation({
        mutationFn: (d) => axiosClient.post(`/like`, d),
        onSuccess: () => {
            queryClient.invalidateQueries(query)
        },
        onError: (err) => {
        }
    })
    const deleteLikeMutation = useMutation({
        mutationFn: (d) => axiosClient.delete(`/like`, {data: d}),
        onSuccess: () => {
            queryClient.invalidateQueries(query)
        },
        onError: (err) => {
        }
    })


    const submitLike = () => {
        postLikeMutation.mutate({
            "likeable_type": "App\\Models\\NewsAndTrend",
            "likeable_id": likeableId
        })
    }
    const submitDeleteLike = () => {
        deleteLikeMutation.mutate({
            "likeable_type": "App\\Models\\NewsAndTrend",
            "likeable_id": likeableId
        })
    }

    return(
        <div onClick={isLiked?submitDeleteLike:submitLike} className="like-wrapper w-fit h-8 flex justify-center gap-2 p-2 items-center py-1.5 my-3 md:my-6 text-12">
            {postLikeMutation.isLoading || deleteLikeMutation.isLoading ?
            <div>
                <CustomSpinner size={4}/>
            </div>
            :
                <div className={"flex gap-2"}>
                    {
                        isLiked?
                            <svg className={""} width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9362 8.96088C15.7554 8.60703 15.4644 8.31603 15.1183 8.143C14.7252 7.94642 14.2454 7.94642 13.2703 7.94642H11.8621C11.6464 7.94642 11.4948 7.73386 11.5583 7.52775C12.0846 5.82129 12.1252 4.50895 11.6738 3.71546C11.4457 3.30652 11.0919 3.047 10.6515 2.9605C10.3605 2.89759 9.99089 2.84254 9.69989 2.95264C9.25162 3.12565 9.15727 3.55818 9.07867 3.90421C8.82067 5.06878 8.12054 6.65476 7.06251 7.39957C6.94005 7.48577 6.8806 7.63957 6.91152 7.7861C6.99461 8.1799 6.99461 8.62825 6.99461 9.23613V13.7895C6.99461 14.3483 6.99461 14.7717 6.93461 15.1398C6.90919 15.2957 6.98564 15.4552 7.13136 15.5162L7.98554 15.8736C8.19787 15.9601 8.30009 16.0073 8.41019 16.0387C8.50454 16.0623 8.61464 16.0859 8.72474 16.1016C8.84272 16.1095 8.95282 16.1095 9.18089 16.1095H12.6412C13.1523 16.1095 13.404 16.1095 13.6477 16.0466C13.8758 15.9915 14.096 15.8893 14.2848 15.7556C14.4971 15.6141 14.6701 15.4096 14.9926 15.0321L15.3779 14.5917C15.6453 14.2771 15.7789 14.1277 15.8812 13.939C15.9756 13.7739 16.0463 13.5851 16.0857 13.3963C16.125 13.1919 16.125 12.9795 16.125 12.5628V10.809C16.125 9.8338 16.125 9.3541 15.9362 8.96088Z" fill="#A6A6A6"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.73096 14.7484C3.40066 14.7484 3.14114 14.4888 3.14114 14.1585V14.1507C3.14114 13.8282 3.40066 13.5609 3.73096 13.5609C4.05339 13.5609 4.32078 13.8282 4.32078 14.1507C4.32078 14.4732 4.05339 14.7484 3.73096 14.7484ZM5.66556 7.76494C5.53187 7.48969 5.30381 7.26949 5.03642 7.12794C4.73759 6.97852 4.34437 6.97852 3.55795 6.97852H3.37707C2.85803 6.97852 2.59851 6.97852 2.39404 7.08075C2.21316 7.17512 2.06375 7.32454 1.97723 7.50542C1.875 7.70203 1.875 7.96155 1.875 8.48846V14.5439C1.875 15.063 1.875 15.3303 1.97723 15.5269C2.06375 15.7078 2.21316 15.8572 2.39404 15.9516C2.59851 16.046 2.85803 16.046 3.37707 16.046H3.55795C4.34437 16.046 4.73759 16.046 5.03642 15.9044C5.30381 15.7629 5.53187 15.5427 5.66556 15.2674C5.71275 15.1731 5.74421 15.0708 5.7678 14.945C5.81498 14.6776 5.81498 14.3237 5.81498 13.7889V9.23554C5.81498 8.57494 5.81498 8.19746 5.72061 7.91436C5.70488 7.85931 5.68916 7.81213 5.66556 7.76494Z" fill="#A6A6A6"/>
                            </svg>
                            :
                            <svg className={"svgnormal"} width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.85797 7.08008H3.68498C3.18805 7.08008 2.93958 7.08008 2.7491 7.17525C2.57428 7.26261 2.43253 7.40436 2.34517 7.57918C2.25 7.76966 2.25 8.01815 2.25 8.5151V14.2941C2.25 14.791 2.25 15.0395 2.34517 15.23C2.43253 15.4048 2.57428 15.5466 2.7491 15.6339C2.93958 15.7291 3.18804 15.7291 3.68497 15.7291H3.85796C4.60336 15.7291 4.97608 15.7291 5.26179 15.5863C5.52403 15.4553 5.73665 15.2427 5.86768 14.9805C6.01045 14.6947 6.01045 14.322 6.01045 13.5766V9.23255C6.01045 8.48712 6.01045 8.11445 5.86768 7.82874C5.73665 7.56649 5.52403 7.35387 5.26179 7.22284C4.97608 7.08008 4.60337 7.08008 3.85797 7.08008Z" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M4.01813 13.9248V13.918" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M6.01019 14.6023L8.11502 15.4743C8.31002 15.5551 8.40744 15.5954 8.50817 15.6241C8.60139 15.6505 8.69664 15.6695 8.79294 15.6807C8.89689 15.6928 9.00242 15.6928 9.21347 15.6928H12.5192C12.997 15.6928 13.2359 15.6928 13.4583 15.6353C13.6637 15.5821 13.8582 15.4932 14.0327 15.3725C14.2217 15.2419 14.3779 15.0612 14.6904 14.6996L15.051 14.2825C15.3069 13.9864 15.4349 13.8384 15.5266 13.6715C15.6114 13.5171 15.6732 13.3512 15.71 13.1789C15.7497 12.9926 15.7497 12.7969 15.7497 12.4057V10.7275C15.7497 9.81643 15.7497 9.36088 15.5753 9.01168C15.4151 8.6912 15.1553 8.43133 14.8347 8.2712C14.4855 8.09668 14.03 8.09668 13.1189 8.09668H10.4227" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M6.01019 7.9843C7.03983 7.9843 8.60199 6.89814 9.21002 4.16399C9.28794 3.81346 9.37247 3.46687 9.74424 3.32625C9.99999 3.22951 10.3357 3.27969 10.598 3.33361C11.5989 3.53937 12.3716 4.78989 11.2372 8.05947" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                    }
                    {likes.length}
                </div>
            }
        </div>
    )


}
export default LikeIcon;
