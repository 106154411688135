import React, {useState} from 'react';
import MobilePinnedMainHeader from "../../../Sections/MobilePinnedMainHeader";
import  s from "./styles.module.scss"
import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import SwitchLogic from "../../../Utility/SwitchLogic/SwitchLogic";
import PageTitle from "../../../Components/PageTitle/PageTitle";


const Availability = () => {
    PageTitle("Courses")

    const [selected, setSelected] = useState();

    let footer = <p className={"mt-3 text-center"}>Please pick a day.</p>;
    if (selected) {
        footer = <p className={"mt-3 text-center"}>You picked {format(selected, 'PP')}.</p>;
    }


    const SelectedTimeLine =()=>{
        return(
            <div className={"flex gap-6 items-center"}>
                <div className="btn transparent">
                    09:00
                </div>
                <div className="btn transparent">
                    10:00
                </div>
                <div>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.19458 6.32617H19.3254L17.9646 20.5333H5.55528L4.19458 6.32617Z" stroke="#A6A6A6" stroke-width="1.44" stroke-linecap="square"/>
                        <path d="M16.0579 5.89088L15.0319 2.77344H8.4896L7.46362 5.89088" stroke="#A6A6A6" stroke-width="1.44" stroke-linecap="square"/>
                        <path d="M11.7611 11.043V15.817" stroke="#A6A6A6" stroke-width="1.44" stroke-linecap="square"/>
                    </svg>
                </div>
            </div>
        )
    }
    return (
        <div className={`${s.container} w-full h-full flex flex-col overflow-hidden`}>
            <MobilePinnedMainHeader title={"Availability"}/>
            <div className={"flex-grow flex flex-col overflow-hidden p-8"}>
                <div className="title text-24 font-semibold mb-2">
                    Set your availability time
                </div>
                <div className="desc text-14 mb-12 font-medium text-gray-90">
                    Choose a schedule below
                </div>
                <div className="choose-time-box items-center justify-between flex p-8 flex flex-grow  w-full">
                    <DayPicker
                        showOutsideDays
                        fixedWeeks
                        mode="single"
                        selected={selected}
                        onSelect={setSelected}
                        footer={footer}
                    />
                    <div className={"h-full flex flex-col justify-center"}>
                        <div className={"font-medium text-18 mb-4"}>Sat</div>
                        <div className={"text-gray-90 mb-2"}>
                            Iam on or off this day
                        </div>
                        <div>
                            <SwitchLogic/>
                        </div>

                    </div>

                    <div className={"flex gap-10"}>
                        <div className="selected-times flex flex-col gap-4">
                            <SelectedTimeLine/>
                            <SelectedTimeLine/>
                            <SelectedTimeLine/>
                            <SelectedTimeLine/>
                        </div>

                        <div className="add-btn rounded-8 w-10 h-10 bg-brown-40 grid place-items-center">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 7V17" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                                <path d="M17 12H7" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                            </svg>
                        </div>
                    </div>

                </div>
                <div className="btn grey-50 w-[160px] ml-auto mt-8">
                    Save
                </div>
            </div>
        </div>
    );
};

export default Availability;
