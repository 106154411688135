import React, {useRef, useState} from 'react';
import s from "./styles.module.scss"
import classNames from "classnames";
import {format, parseISO} from "date-fns";
import useOnClickOutside from "../../../../Hooks/useOnClickOutside";
import {DayPicker} from "react-day-picker";
import {useMutation} from "react-query";
import axiosClient from "../../../../Api/apiClient";
import {uploadSingleFile} from "../../../../Functions/UploadSingleFilePromise";
import CustomSpinner from "../../../../Utility/CustomSpinner";
import {useAlert} from "react-alert";
import PopupContainer from "../../../../Sections/PopupContainer";
const BookSessionBox = ({setIsOpenPop,id,setRequestSentData}) => {

    const [time,setTime]=useState(null)
    const [selectedDate, setSelectedDate] = useState();
    const [title,setTitle]=useState()
    const [desc,setDesc]=useState()
    const [btnLoading,setBtnLoading]=useState(false)
    const alert = useAlert()
    const sessionMutation = useMutation({
        mutationFn:(d)=>axiosClient.post("/sessions",d),
        onError:()=>{
            alert.error("An error occurred")
        },
        onSuccess:(res)=>{
            alert.success("Request sent!")
            setTime(null)
            setSelectedDate(null)
            setTitle(null)
            setDesc(null)
            setIsOpenPop(false)
            setRequestSentData(res.data.resulting_data)
        },
        onSettled:()=>{
            setBtnLoading(false)
        }
    })

    let footer = <p className={"mt-3 text-center"}>Please pick a day.</p>;
    if (selectedDate) {
        footer = <p className={"mt-3 text-center"}>You picked {format(selectedDate, 'PP')}.</p>;
    }

    const handleSetTime =(i)=>{
        setTime(i)
    }

    const dropdownTime =useRef();
    const [isDropdownTimeOpen,setIsDropdownTimeOpen]=useState(false)
    useOnClickOutside(dropdownTime,()=>setIsDropdownTimeOpen(false))

    const arr = ["00:00","01:00","02:00","03:00","04:00","05:00","06:00","07:00","08:00","09:00","10:00","11:00","12:00","13:00","14:00","16:00","17:00","18:00","19:00","20:00","21:00","22:00","23:00"]

    const handleSubmit = async ()=> {
        if (!title || !desc || !time || !selectedDate){
            alert.error("Please fill out all the fields")
            return
        }
        const date = parseISO(`${format(selectedDate, "yyyy-MM-dd")}T${time}:00`)
        const isoDate = date.toISOString();
        setBtnLoading(true)

        sessionMutation.mutate({
            "title": title,
            "description": desc,
            "session_date": isoDate,
            "duration": 30,
            "mentor_id": id,
            "file_ids": []
        })
    }

    return (
        <div className={classNames(s.container,"flex overflow-hidden mx-auto flex-grow md:flex-grow-0 flex-col md:flex-row")}>

            <div className="first-line flex mb-5 items-center justify-between">
                <div className="left flex items-center gap-3">
                    <div className="sqr">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.34668 13.5117H27.3297" stroke="white" stroke-width="2" stroke-linecap="square"/>
                            <path d="M21.8107 18.3281H21.8213" stroke="white" stroke-width="2" stroke-linecap="square"/>
                            <path d="M16.3383 18.3281H16.349" stroke="white" stroke-width="2" stroke-linecap="square"/>
                            <path d="M10.8539 18.3281H10.8646" stroke="white" stroke-width="2" stroke-linecap="square"/>
                            <path d="M21.8107 23.1216H21.8213" stroke="white" stroke-width="2" stroke-linecap="square"/>
                            <path d="M16.3383 23.1216H16.349" stroke="white" stroke-width="2" stroke-linecap="square"/>
                            <path d="M10.8539 23.1216H10.8646" stroke="white" stroke-width="2" stroke-linecap="square"/>
                            <path d="M21.3203 4.37939V8.43803" stroke="white" stroke-width="2" stroke-linecap="square"/>
                            <path d="M11.3569 4.37939V8.43803" stroke="white" stroke-width="2" stroke-linecap="square"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M27.4334 6.32666H5.2334V29.0458H27.4334V6.32666Z" stroke="white" stroke-width="2" stroke-linecap="square"/>
                        </svg>

                    </div>
                    <div className="title-box text-18 font-medium">
                        Book sessions
                    </div>
                </div>
                <div className="close cursor-pointer" onClick={()=>{setIsOpenPop(false)}}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 6L18 18" stroke="#A6A6A6" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
                        <path d="M18 6L6 18" stroke="#A6A6A6" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div className={"flex flex-col flex-grow overflow-auto pr-2"}>
                <div className="main-content flex-col md:flex-row  md:flex-grow flex-wrap flex gap-6 md:gap-10 ">
                    <div className="left flex flex-col " >
                        <div className="time md:mb-6 mb-4 flex gap-2 items-center text-gray-90 font-medium">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.64 12.0001C20.64 16.9048 16.6646 20.8801 11.76 20.8801C6.85536 20.8801 2.88 16.9048 2.88 12.0001C2.88 7.09548 6.85536 3.12012 11.76 3.12012C16.6646 3.12012 20.64 7.09548 20.64 12.0001Z" stroke="#A6A6A6" stroke-width="1.7" stroke-linecap="square"/>
                                <path d="M15.0549 14.8244L11.4357 12.6653V8.01221" stroke="#A6A6A6" stroke-width="1.7" stroke-linecap="square"/>
                            </svg>
                            1 hours meeting
                        </div>
                        <div className="input-box mb-4 md:mb-8">
                            <label htmlFor="">
                                Title *
                            </label>
                            <input type="text" value={title} onChange={(e)=>setTitle(e.target.value)} placeholder={"Type title"}/>
                        </div>
                        <div className="input-box  flex-grow">
                            <label htmlFor="">
                                Description *
                            </label>
                            <textarea placeholder={"description here..."} value={desc} onChange={(e)=>setDesc(e.target.value)} className={"resize-none h-full"} type="text"/>
                        </div>
                    </div>
                    <div className={"center"}>
                        <div className={"md:hidden text-24 font-semibold mb-6"}>
                            Select a Date & Time
                        </div>
                        <div className=" bg-brown-50 flex items-center justify-center w-full border-1 border-brown-110 rounded-20">
                            <DayPicker
                                showOutsideDays
                                fixedWeeks
                                mode="single"
                                className={""}
                                selected={selectedDate}
                                onSelect={setSelectedDate}
                                footer={footer}
                            />
                        </div>
                    </div>
                    <div className=" py-6 pb-0">
                        <div className="title text-18 mb-4" >
                            {selectedDate && format(selectedDate,"EEEE,LLLL d,yyyy")}
                        </div>

                        <div className="bg-brown-40 mb-4 rounded-12 text-12 font-normal w-fit px-2.5 py-2 flex gap-2 items-center">

                            <div className="circle w-3 h-3 rounded-full bg-green">

                            </div>
                            +GMT
                            <span className={"mx-1"}>
                                    {
                                        new Date().toLocaleTimeString() // displays date and time
                                    }
                                </span>
                            local time / Tehran
                        </div>
                        <div className={"relative"} onClick={()=>setIsDropdownTimeOpen(!isDropdownTimeOpen)} >
                            <div  className={"box p-3 h-12 items-center flex rounded-[4px] border-1 border-brown-110 bg-brown-50 text-gray-90 text-14 font-medium relative"}>
                                {time ?
                                    time.split(":")[0] > 12 ? `${(time.split(":")[0]-12)} pm`:`${time} am `
                                    :"Select time"}
                                <div className={classNames("absolute right-4 top-0 bottom-0 flex items-center",!isDropdownTimeOpen && "rotate-180")}>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4 11.25L9.25 6L14.5 11.25" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                                    </svg>
                                </div>
                            </div>
                            {isDropdownTimeOpen &&
                                <div ref={dropdownTime} className={"absolute z-20 right-0 top-[110%] max-h-[200px] overflow-auto text-12 w-[180px] border-brown-90 border-1 rounded-8 p-3 bg-brown-40"}>
                                    {arr.map((i) => {
                                        return (
                                            <div onClick={() => handleSetTime(i)}
                                                 className={classNames(" rounded-8 p-1 cursor-pointer w-full", time == i && "bg-brown-90")}>
                                                {i}
                                            </div>
                                        )
                                    })
                                    }
                                </div>}
                        </div>


                    </div>

                </div>
                <div className="btn-wrapper md:flex-row flex-col-reverse pt-8 flex gap-3 w-full justify-end">
                    <div onClick={()=>{setIsOpenPop(false)}} className="btn dark big">
                        Cancel
                    </div>
                    <button onClick={handleSubmit} className="btn grey-50 big md:!w-[220px]">
                        {sessionMutation.isLoading ?
                        <CustomSpinner/>
                        :
                        "Request a session"
                        }
                    </button>

                </div>
            </div>


        </div>
    );
};

export default BookSessionBox;
