import {useNavigate} from "react-router-dom";
import {useMutation, useQueryClient} from "react-query";
import axiosClient from "../Api/apiClient";
import {useState} from "react";

export const useUpdatePost =(id, query,customUrl=query,customInvalidateQuery=query)=>{
    const [isDone,setIsDone]=useState(false)
    const navigate=useNavigate()
    const queryClient = useQueryClient();
    const deleteMutation =useMutation(
        (id) => axiosClient.delete(`${query}/${id}`),
        {
            onSuccess: (data) => {
                setIsDone(true)
                queryClient.invalidateQueries(customInvalidateQuery)
                setTimeout(()=>{
                    deleteMutation.reset()
                },2000)
            },
        })
    const handleDelete =(func)=>{
        deleteMutation.mutateAsync(id).then(()=>func)
    }
    const handleEdit =()=>{
        navigate(`${customUrl}/edit/${id}`)
    }

    return {
        handleDelete,handleEdit,deleteMutation,isDone
    }
}
