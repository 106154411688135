import React, {useState} from 'react';
import MobilePinnedMainHeader from "../../../Sections/MobilePinnedMainHeader";
import s from "./styles.module.scss"
import ReactPlayer from "react-player";
import {useMutation} from "react-query";
import axiosClient from "../../../Api/apiClient";
import {Link, useNavigate} from "react-router-dom";
import CustomSpinner from "../../../Utility/CustomSpinner";
import PageTitle from "../../../Components/PageTitle/PageTitle";
const AddNewVideo = () => {
    PageTitle("Add Video")

    const [step,setStep]=useState(0)
    const navigate = useNavigate()
    const [dataToPost,setDataToPost]=useState({
        title:"",
        description:"",
        video_url:"",
        status: "published"
    })
    const handleChange =(e)=>{
        const { name, value } = e.target;
        setDataToPost((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    }
    const postMutation = useMutation({
        mutationFn: (data)=>axiosClient.post(`/videos`, data),
        onSuccess: async ()=>{
            navigate("/videos")
        }
    })
    const handleSubmit =(e)=>{
        if(!postMutation.isLoading && !postMutation.isSuccess) {
            postMutation.mutate(dataToPost)
        }
    }
    return (
        <div className={`${s.container} flex flex-col h-full`}>
            <MobilePinnedMainHeader title={"Videos"}/>
            {
                step === 0?
                    <div className={"flex-grow  overflow-auto lg:justify-around flex flex-col p-8 py-12"}>
                        <div className="title mb-10 text-24  font-semibold">
                            Adding video
                        </div>
                        <div className={"box "}>
                            <div className="left flex-grow-[2] flex flex-col justify-center">
                                <div className="input-box mb-10 flex flex-col gap-3">
                                    <label className="label">Title </label>
                                    <input placeholder={"Type here"} name={"title"} onChange={handleChange} value={dataToPost.title} type="text"/>
                                </div>
                                <div className="input-box flex flex-col gap-3">
                                    <label className="label">Description </label>
                                    <textarea className={"resize-none h-[200px]"} onChange={handleChange} value={dataToPost.description} placeholder={"Type here"} name={"description"} type="text"/>
                                </div>
                            </div>
                            <div className="seperator h-full w-[1px] bg-brown-90 "></div>
                            <div className="right flex flex-col justify-center flex-grow">
                                <div className={"text-18 font-medium mb-2"}>Video upload</div>
                                <div className={"text-14 font-medium text-gray-90"}>Upload your video</div>
                                <div className="input-box mt-10 flex flex-col gap-3">
                                    <label className="label">YouTube link</label>
                                    <div className={"flex w-full relative"}>
                                        <div className="icon absolute my-auto bottom-0 top-0 left-4 h-fit">
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_7156_1173)">
                                                    <path d="M11.75 4.5H2.5C1.94772 4.5 1.5 4.94772 1.5 5.5V13.25C1.5 13.8023 1.94772 14.25 2.5 14.25H11.75C12.3023 14.25 12.75 13.8023 12.75 13.25V5.5C12.75 4.94772 12.3023 4.5 11.75 4.5Z" stroke="#A6A6A6" stroke-width="1.44" stroke-linecap="square"/>
                                                    <path d="M13.125 7.5L16.5 5.25V14.25L13.125 11.625" stroke="#A6A6A6" stroke-width="1.44" stroke-linecap="square"/>
                                                    <path d="M8.25 7.5H10.5574" stroke="#A6A6A6" stroke-width="1.44" stroke-linecap="square"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_7156_1173">
                                                        <rect width="18" height="18" fill="white"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                        <input className={"w-full pl-12"} onChange={handleChange} value={dataToPost.video_url} name={"video_url"} placeholder={"Paste video link here"} type="text"/>
                                    </div>
                                </div>

                                {/*<div className={"mt-8 p-3 flex gap-5 items-center bg-brown-40 rounded-8"}>*/}
                                {/*    <div className="w-[108px] aspect-square rounded-8  bg-white/50"></div>*/}
                                {/*    <div className={"text-gray-50"} >*/}
                                {/*        <div className={"text-14 mb-3 "}>*/}
                                {/*            Turn Yourself Into a 3D Puppet - ft. @sotomonte_*/}
                                {/*        </div>*/}
                                {/*        <div className={"px-2 py-1 text-12 rounded-30  bg-brown-50 w-fit"}>*/}
                                {/*            12:25*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className={"flex flex-col-reverse w-full lg:w-fit lg:flex-row gap-4 ml-auto"}>
                            <Link to={"/videos"}  className="btn w-full lg:w-[170px]">Cancel</Link>
                            <button disabled={!dataToPost.title || !dataToPost.description || !dataToPost.video_url} onClick={()=>setStep(1)} className="btn grey-50 w-full  lg:w-[170px]">Next </button>
                        </div>
                    </div>
                    :
                    <div className={"flex-grow overflow-auto lg:justify-around flex flex-col pad-layout py-8 py-12"}>
                        <div className="title mb-4 lg:mb-10 text-24 font-semibold">
                            Preview
                        </div>
                        <div className={"box lg:!pb-10 "}>
                            <div className="left order-2 lg:order-1 w-full lg:pt-4 flex flex-col flex-shrink justify-center">
                              <div className={"text-gray-50 text-24 mb-3 lg:mb-6 mt-auto "}>
                                  {dataToPost.title}
                              </div>
                                <div className={"p-6 whitespace-pre-wrap rounded-8 w-full border-1 overflow-auto flex-grow font-medium mt-auto border-brown-110"}>
                                    {dataToPost.description}
                                </div>
                            </div>
                            <div className="right order-1 lg:order-2  flex  flex-col justify-center">
                                <div className={"h-full lg:h-[75%] aspect-[1.5] overflow-hidden mt-auto rounded-8 bg-white "}>
                                    <ReactPlayer controls width={"100%"} height={"100%"} url={dataToPost.video_url} />
                                </div>
                            </div>
                        </div>
                        <div className={"flex-col-reverse w-full lg:w-fit lg:flex-row flex gap-4 ml-auto"}>
                            <div onClick={()=>setStep(0)} className="btn w-full lg:w-[170px]">Back</div>
                            <div onClick={handleSubmit} className="btn grey-50 w-full lg:w-[170px]">
                                {postMutation.isLoading?
                                    <div>
                                        <CustomSpinner size={9}/>
                                    </div>
                                    :
                                    postMutation.isError?
                                        "error"
                                        :
                                        postMutation.isSuccess?
                                            "Posted!"
                                            :
                                            "Publish"
                                }
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
};

export default AddNewVideo;
