import React,{Suspense} from 'react';
import MobilePinnedMainHeader from "../../../Sections/MobilePinnedMainHeader";
import classNames from "classnames";
import s from "./styles.module.scss"
import ReactPlayer from "react-player";
import {useLocation} from "react-router-dom";
import {useQuery} from "react-query";
import {formatDistance} from "date-fns";
import Comments from "../Comments";
import PageTitle from "../../../Components/PageTitle/PageTitle";
const VideoPage = () => {
    PageTitle("Video")

    const location = useLocation()
    const VideoContent =()=>{
        const {data} = useQuery(location.pathname)
        const infoData = data.resulting_data
        return(
            <>
                <MobilePinnedMainHeader hasBackBtn title={infoData.title}/>
                <div className={"flex-grow  overflow-auto flex flex-col "}>
                    <div className={"flex-grow  pad-layout py-8"}>
                        <div className="video-box relative bg-white w-full overflow-hidden h-[350px]  lg:h-[unset] lg:aspect-[2.4] rounded-8">
                            <div className={"absolute z-[0] inset-0 m-auto text-black w-fit h-fit"}>
                                Loading...
                            </div>
                            <div className={"w-full relative h-full"}>
                                <ReactPlayer controls width={"100%"} height={"100%"} url={infoData.video_url} />
                            </div>
                        </div>
                        <div className={"bg-brown-40  whitespace-pre-wrap  mt-4 rounded-8 p-4 text-gray-50 font-medium"}>
                            {infoData.description}
                        </div>
                        <div className={"flex mt-4 items-center gap-4"}>
                            <div className="circle w-12 h-12 rounded-full overflow-hidden bg-white">
                                <img className={"w-full h-full object-cover"} src={infoData.owner.profile_image_url?infoData.owner.profile_image_url:"/media/defaultAvatar.png"} alt=""/>
                            </div>
                            <div>
                                <div className="font-medium text-18">
                                    {infoData.owner.name}
                                </div>
                                <div className={"text-12 text-gray-90"}>
                                    {formatDistance(new Date(infoData.created_at),new Date())} ago
                                </div>
                            </div>
                        </div>
                    </div>

                    <Comments comments={infoData.comments} id={infoData.id}/>
                </div>

            </>
        )
    }
    return (
        <div className={classNames ("flex flex-col h-full overflow-hidden",s.container)}>
            <Suspense fallback={<div className={"p-8"}>Loading...</div>}>
                <VideoContent/>
            </Suspense>
        </div>
    );
};

export default VideoPage;
