import React, {Suspense, useEffect, useState} from 'react';
import s from "./styles.module.scss"
import MobilePinnedMainHeader from "../../../Sections/MobilePinnedMainHeader";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useMutation, useQuery} from "react-query";
import axiosClient from "../../../Api/apiClient";
import PageTitle from "../../../Components/PageTitle/PageTitle";

const EditVideo = () => {
    PageTitle("Edit Video")

    const location = useLocation()
    const navigate = useNavigate()

    const PageContent =()=>{
        const {data} = useQuery(`/videos/${location.pathname.split("/")[3]}`)
        const infoData = data.resulting_data
        const [dataToPost,setDataToPost]=useState({
            title:infoData.title,
            description:infoData.description,
            video_url:infoData.video_url,
            status: "published"
        })
        const handleChange =(e)=>{
            const { name, value } = e.target;
            setDataToPost((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }

        const postMutation = useMutation({
            mutationFn: (data)=>axiosClient.put(`/videos/${location.pathname.split("/")[3]}`, data),
            onSuccess:()=>{
               navigate("/videos")
            }
        })
        const handleSubmit =(e)=>{
            postMutation.mutateAsync(dataToPost).then(res=>console.log(res)).catch(err=>console.log(err))
        }
        return(
            <>
                <MobilePinnedMainHeader hasBackBtn title={infoData.title}/>
                <div className={"flex-grow justify-around flex flex-col p-8 py-12"}>
                    <div className="title mb-10 text-24 font-semibold">
                        Edit video
                    </div>
                    <div className={"box "}>
                        <div className="left  flex-grow-[2] flex flex-col justify-center">
                            <div className="input-box mb-10 flex flex-col gap-3">
                                <label className="label">Title (required)</label>
                                <input onChange={handleChange} name={"title"} value={dataToPost.title} placeholder={"Type here"} type="text"/>
                            </div>
                            <div className="input-box flex flex-col gap-3">
                                <label className="label">Description (required)</label>
                                <textarea className={"resize-none h-[200px]"} placeholder={"Type here"} name={"description"} type="text"
                                    value={dataToPost.description}
                                    onInput={handleChange}
                                />
                            </div>
                        </div>
                        <div className="seperator h-full w-[1px] bg-brown-90 "></div>
                        <div className="right flex flex-col justify-center flex-grow">
                            <div className={"text-18 font-medium mb-2"}>Edit upload</div>
                            <div className={"text-14 font-medium text-gray-90"}>Edit your video</div>
                            <div className="input-box mt-10 flex flex-col gap-3">
                                <label className="label">Youtube link</label>
                                <div className={"flex w-full relative"}>
                                    <div className="icon absolute my-auto bottom-0 top-0 left-4 h-fit">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_7156_1173)">
                                                <path d="M11.75 4.5H2.5C1.94772 4.5 1.5 4.94772 1.5 5.5V13.25C1.5 13.8023 1.94772 14.25 2.5 14.25H11.75C12.3023 14.25 12.75 13.8023 12.75 13.25V5.5C12.75 4.94772 12.3023 4.5 11.75 4.5Z" stroke="#A6A6A6" stroke-width="1.44" stroke-linecap="square"/>
                                                <path d="M13.125 7.5L16.5 5.25V14.25L13.125 11.625" stroke="#A6A6A6" stroke-width="1.44" stroke-linecap="square"/>
                                                <path d="M8.25 7.5H10.5574" stroke="#A6A6A6" stroke-width="1.44" stroke-linecap="square"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_7156_1173">
                                                    <rect width="18" height="18" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                    <input  name={"video_url"} value={dataToPost.video_url} onChange={handleChange} className={"w-full pl-12"} placeholder={"Paste your link here"} type="text"/>
                                </div>
                            </div>

                            <div className={"mt-8 p-3 flex gap-5 items-center bg-brown-40 rounded-8"}>
                                <div className="w-[108px] aspect-square rounded-8  bg-white/50">
                                    <img className={"w-full h-full object-cover"} src={`https://img.youtube.com/vi/${dataToPost.video_url.split("=")[1]}/sddefault.jpg`} alt=""/>
                                </div>

                                <div className={"text-gray-50"} >
                                    <div className={"text-14 mb-3 "}>
                                       felan api nadaram
                                    </div>
                                    <div className={"px-2 py-1 text-12 rounded-30  bg-brown-50 w-fit"}>
                                        12:25 ( no api)
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"flex gap-4 ml-auto"}>
                        <Link to={"/videos"} className="btn w-[170px]">Cancel </Link>
                        <div onClick={handleSubmit} className="btn grey-50 w-[170px]">Save </div>
                    </div>
                </div>
            </>
        )
    }
    return (
        <div className={`${s.container} flex flex-col h-full`}>
            <Suspense fallback={<div className={"p-8"}>Loading...</div>}>
                <PageContent/>
            </Suspense>
        </div>

    );
};

export default EditVideo;
