import React from 'react';
import MoreOptionsDropdown from "../../../Sections/MoreBtn/MoreOptionsDropdown";
import {useUpdatePost} from "../../../Hooks/useUpdatePost";
import MoreBtn from "../../../Sections/MoreBtn";
import {format} from "date-fns";
import AdminCanView from "../../../Components/ViewLimit/AdminCanView";
import {useGetProfile} from "../../../Hooks/useGetProfile";

const CommentItem = ({content,author,authorPic,videoId,id,date}) => {
    const {handleDelete,deleteMutation} = useUpdatePost(id,`/videos/${videoId}/comments`,"/videos",`/videos/${videoId}`)

    const me = useGetProfile()
    const isMine = me.id == author.id
    return (
        <div className={"comment"}>
            <div className={"flex items-center gap-2 text-gray-90"}>
                <div className="profile w-10 h-10 rounded-full overflow-hidden bg-white">
                    <img src={authorPic?authorPic:"/media/defaultAvatar.png"} className={"w-full h-full object-cover"} alt=""/>
                </div>
                <div className="name ml-2">{author.name}</div>
                <div className={"text-12"}>
                    {format(new Date(date), 'MMMM dd, yyyy  hh:mm aaaa')}
                </div>
                <div>
                    <AdminCanView>
                        <MoreBtn options={[
                            {name: "Delete", fn: handleDelete, mutation: deleteMutation,isHidden:!(isMine||me.isOwner)},
                        ]}/>
                    </AdminCanView>
                </div>
            </div>
            <div className={"content whitespace-pre-wrap ml-14 mt-4"}>
                {content}
            </div>
        </div>
    );
};

export default CommentItem;
