import React, {useRef, useState} from 'react';
import EmojiPicker from "emoji-picker-react";
import useOnClickOutside from "../../../../Hooks/useOnClickOutside";
import {MainThreadsName} from "../../MainThreadsName";
import {useMutation, useQueryClient} from "react-query";
import axiosClient from "../../../../Api/apiClient";
import CustomSpinner from "../../../../Utility/CustomSpinner";
import {useAlert} from "react-alert";


const ThreadPopup = ({setCreateThreadOpen}) => {
    const titleInput = useRef()


    const [desc,setDesc]=useState(null)
    const [title,setTitle]=useState(null)
     const [tab,setTab]=useState(null);
    const Tab =({title,isActive,onClick})=>{
        return(
            <div onClick={onClick} className={`text-14 lg:text-16 whitespace-nowrap px-2 cursor-pointer py-1.5 bg-brown-100 border-brown-90 border-2 rounded-8 ${isActive && "text-gray-100 bg-gray-50"}`}>
                {title}
            </div>
        )
    }

    const [titleLetters,setTitleLetters]=useState(0);
    const checkTitleLimit =(e)=>{
        setTitleLetters(e.target.value.length)
        setTitle(e.target.value)
    }

    const [textLetters,setTextLetters]=useState(0);

    const checkTextLimit =(e)=>{
        setDesc(e.target.value)
        setTextLetters(e.target.value.length)
    }



    const [isEmojyAdded,setIsEmojyAdded]=useState(false)
    const emojiBox = useRef()
    const emojiPicker = useRef()
    const emojyAdd = (e)=>{
        // console.log(e)
        // let sym = e.unified.split("-");
        // let codesArray = [];
        // sym.forEach((el) => codesArray.push("0x" + el));
        // let emoji = String.fromCodePoint(...codesArray);
        emojiBox.current.innerHTML = e.emoji
        setIsEmojyPickerOpened(false)
        setIsEmojyAdded(true)
    }



    useOnClickOutside(emojiPicker,()=>{
        setIsEmojyPickerOpened(false)
    })


    const [isEmojyPickerOpened,setIsEmojyPickerOpened]=useState(false)


    const data ={
        title:title,
        description:desc,
        category:tab,
        emoji:emojiBox?.current?.innerHTML
    }

    const queryClient = useQueryClient();

    const alert = useAlert()
    const mutation = useMutation({
        mutationFn: (threadData)=>axiosClient.post('/threads', threadData),
        onSuccess: async (res) => {
            queryClient.invalidateQueries({ queryKey: ['/threads'] });
            setCreateThreadOpen(false)
            alert.success("Thread added successfully")
        },
        onError:(err)=>{
            alert.error(err.response.data.error.error)
        }
    })

    const handleSubmit =()=>{
        if(!mutation.isLoading && !mutation.isSuccess) {
            mutation.mutate(data)
        }
    }

    return (
        <div className={"bg-black/60 z-30 h-full pb-20 md:pb-0 fixed inset-0 flex"}>
            <div className="w-full overflow-auto h-full lg:h-fit lg:w-fit bg-brown-80 relative lg:rounded-12 px-6 py-8 lg:py-12 lg:px-8 m-auto">

                <div onClick={()=>setCreateThreadOpen(false)} className="close cursor-pointer absolute right-4 top-4">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 6L18 18" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                        <path d="M18 6L6 18" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                    </svg>
                </div>
                <h3 className={"text-24 mb-8 text-gray-50"}>
                    Adding thread
                </h3>
                <div className={"text-gray-90 text-18 font-medium mb-6"}>
                    Choose main threads
                </div>
                <div className="tabs flex-wrap flex gap-2 mb-8">
                    {MainThreadsName.map((item)=>
                        <Tab onClick={()=>setTab(item)} isActive={tab===item} title={item}/>
                    )}
                </div>
                <div className="input-box mb-8 ">
                    <div className={"hidden lg:block text-18 text-gray-90 mb-1"}>Title</div>

                    <div className={"flex flex-col lg:w-fit lg:flex-row gap-4 items-start lg:items-end"}>
                        <div className={"block lg:hidden text-18 text-gray-90 mb-1"}>Title</div>

                        <div className={"relative flex  w-full lg:w-fit gap-4  items-center"}>
                            <input value={title} onInput={checkTitleLimit} ref={titleInput} maxLength="50" placeholder={"Type here "}
                                   className={"w-full lg:w-[600px] pr-20"} type="text"/>

                            <div className={"absolute right-4 top-0 bottom-0 flex items-center text-12 text-gray-90 m-auto z-20"}>
                                {titleLetters}/50
                            </div>
                        </div>
                        <div  className={" block relative  -order-10 lg:order-1  h-[60px]"}>
                            {!isEmojyAdded &&
                                <div className={"absolute z-[4] m-auto inset-0 grid place-items-center pointer-events-none"}>
                                      <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M28.5002 16.3383C28.5002 22.9655 23.1281 28.3376 16.5009 28.3376C9.87238 28.3376 4.50024 22.9655 4.50024 16.3383C4.50024 9.70978 9.87238 4.33765 16.5009 4.33765"
                                        stroke="#D9D9D9" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                    <path
                                        d="M12.5928 13.678V13.6093M12.5321 13.4053C12.3667 13.4053 12.2321 13.5399 12.2334 13.7053C12.2334 13.8706 12.3681 14.0053 12.5334 14.0053C12.6987 14.0053 12.8334 13.8706 12.8334 13.7053C12.8334 13.5386 12.6987 13.4053 12.5321 13.4053Z"
                                        stroke="#D9D9D9" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                    <path
                                        d="M21.2078 13.678V13.6093M21.1988 13.4053C21.0335 13.4053 20.8988 13.5399 20.9002 13.7053C20.9002 13.8706 21.0348 14.0053 21.2002 14.0053C21.3655 14.0053 21.5002 13.8706 21.5002 13.7053C21.5002 13.5386 21.3655 13.4053 21.1988 13.4053Z"
                                        stroke="#D9D9D9" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                    <path
                                        d="M12.6604 19.3896C13.5946 20.606 14.9721 21.365 16.5004 21.365C18.0286 21.365 19.4061 20.606 20.3404 19.3896"
                                        stroke="#D9D9D9" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                    <path d="M28.7989 7.61763H23.1072M25.9536 10.4706V4.7699" stroke="#D9D9D9"
                                          stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                 </div>
                            }
                            <img className={"h-full "} src="/media/Frame 45024.png" alt=""/>
                            <div  ref={emojiBox}
                                  onClick={()=> {setIsEmojyPickerOpened(true)}}
                                  className={"p-2 text-[22px] text-center bg-brown-90 absolute inset-1.5 grid place-item-center rounded-8"}>
                                {""}
                            </div>
                            {isEmojyPickerOpened &&
                                <div ref={emojiPicker}  className={"absolute  left-0 lg:left-[unset] lg:right-0 lg:-bottom-40 z-30"}>
                                    <EmojiPicker onEmojiClick={emojyAdd} theme={"dark"}/>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="input-box mb-8">
                    <div className={"text-18 text-gray-90 mb-3"}>Description</div>
                    <div className={"relative flex w-full lg:w-fit"}>
                       <textarea value={desc} maxLength={"1200"} onInput={checkTextLimit} className={"resize-none max-w-full w-full lg:w-[800px] h-[280px] lg:h-[190px]"} placeholder={"Enter a description…"} type="text"/>
                        <div className={"absolute right-4 top-2 flex items-center text-12 text-gray-90  z-20"}>
                            {textLetters}/1200
                        </div>
                    </div>
                </div>
                <div className={"flex flex-col lg:flex-row justify-end gap-6"}>
                    <div onClick={()=>setCreateThreadOpen(false)} className={"cursor-pointer border-2 border-brown-110 rounded-8 w-full lg:w-[160px] grid place-items-center h-[48px]"}>
                        Cancel
                    </div>
                    <div onClick={handleSubmit} className={"cursor-pointer border-2 bg-brown-110 border-brown-110 rounded-8 w-full lg:w-[300px] flex justify-center items-center h-[48px]"}>
                        {mutation.isLoading?
                            <CustomSpinner size={11}/>
                            :
                            "Create thread"
                        }
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ThreadPopup;
