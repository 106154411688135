import React from 'react';
import LoginLayout from "../LoginLayout";
import CustomSpinner from "../../../Utility/CustomSpinner";
import {Link} from "react-router-dom";

const DeclinedUser = () => {
    return (
        <LoginLayout  imgPath={"3dIcons/19.png"}>
            <div className={"flex flex-col items-center"}>
                <h2 className={"leading-[130%] md:text-center mb-2.5 text-[56px] font-medium lg:mb-5 max-w-[500px]"}>User declined successfully.</h2>
                <p className="w-full  md:text-center text-18 mb-6 text-[#D9D9D9] ">
                    The user will be notified of their pre-registeration status.
                </p>
                <Link to={"/"} className={"btn font-medium grey-50"}>
                    Back to app
                </Link>
            </div>

        </LoginLayout>
    );
};

export default DeclinedUser;
