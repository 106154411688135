import React, {useState} from 'react';

const ImageComponentWithPreview = ({i,openPreview,setOpenPreview}) => {
    return (
        <div>
            <img onClick={()=>setOpenPreview(true)} className={"min-w-[110px] max-w-64  rounded-12"} src={i.url} alt=""/>

            {openPreview &&
            <div onClick={()=>{setOpenPreview(false)}} className={"fixed z-[100] flex items-center justify-center z-[11000] bg-black/80 w-full h-full inset-0"}>
                <img  className={"min-w-[500px] max-w-[90%]"} src={i.url} alt="preview image"/>
            </div>
            }
        </div>
    );
};

export default ImageComponentWithPreview;
