import React, {useState} from 'react';
import classNames from "classnames";
import s from "./styles.module.scss"
import MobilePinnedMainHeader from "../../../Sections/MobilePinnedMainHeader";
import {useMutation, useQueryClient} from "react-query";
import axiosClient from "../../../Api/apiClient";
import CustomSpinner from "../../../Utility/CustomSpinner";
import ToastNotification from "../../../Utility/ToastNotification";
import ToastSuccessNotification from "../../../Utility/ToastNotification/ToastSuccessNotification";
import {Link, useNavigate} from "react-router-dom";
import BtnTextForMutation from "../../../Components/BtnTextForMutation";
import PageTitle from "../../../Components/PageTitle/PageTitle";
import {useAlert} from "react-alert";
const AddNewFaq = () => {
    PageTitle("Add frequently asked questions")
    const alert = useAlert()
    const [titleValue,setTitleValue]=useState(null)
    const [descValue,setDescValue]=useState(null)
    const queryClient = useQueryClient();
    //
    const navigate = useNavigate()
    const mutation = useMutation({
        mutationFn: (data)=>axiosClient.post(`/posts`, data),
        onSuccess: async (res) => {
            navigate("/faq-and-resources")
            setTitleValue("")
            setDescValue("")
            alert.success("Faq added")
            queryClient.invalidateQueries({ queryKey: [`/post`] });
        },
        onError:(err)=>{
            err.response.data.error.error? alert.error(err.response.data.error.error) : alert.error("an error happened!")
        },
        onSettled:()=>{
            setTimeout(()=>{
                mutation.reset()
            },2000)
        }
    })
    const handleSubmit =()=>{
        if(!mutation.isLoading && !mutation.isSuccess) {
            mutation.mutate(data)
            }
    }
    const data = {
        title: titleValue,
        description:descValue
    }

    return (
        <div className={classNames("flex flex-col h-full",s.container)}>
            <MobilePinnedMainHeader title={"New post"} hasBackBtn/>
            <div className={"flex-grow flex p-8 flex-col font-medium justify-center items-center "}>
                <div className={"max-w-[750px] flex flex-col w-full"}>
                    <div className="box p-4 md:p-8 mb-4 md:mb-10  w-full">
                        <div className="text-18 mb-6">
                            New post
                        </div>
                        <div className="left flex-grow-[2] flex flex-col justify-center">
                            <div className="input-box mb-10 flex flex-col gap-3">
                                <label className="label">Title</label>
                                <input value={titleValue} onInput={(e)=>setTitleValue(e.target.value)} placeholder={"Type here"} type="text"/>
                            </div>
                            <div className="input-box flex flex-col gap-3">
                                <label className="label">Description </label>
                                <textarea className={"resize-none h-[200px]"}
                                          value={descValue}
                                          onChange={(e)=>setDescValue(e.target.value)}
                                          placeholder={"Description here"}
                                          type="text"/>
                            </div>
                        </div>
                    </div>
                    <div className="btn-wrapper ml-auto flex flex-col md:w-fit md:flex-row w-full gap-3 ">
                        <Link to={"/faq-and-resources"} className="btn dark big">Cancel</Link>
                        <button disabled={!descValue || !titleValue} onClick={handleSubmit} className="btn grey-50 big">
                           <BtnTextForMutation defaultText={"Post"} mutation={mutation} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddNewFaq;
