import axiosClient from "../Api/apiClient";

export const uploadSingleFile =(item,atachableType="events")=>{
    const formData = new FormData();
    formData.append("attachable_type", atachableType);
    formData.append("attachment", item);
    return new Promise((resolve)=>{
        console.log(`Processing item ${item}`);
        axiosClient.post(`/attachments`, formData,
            {headers: {"Content-Type": "multipart/form-data"}}
        ).then((res)=>resolve(res.data.resulting_data.id)).catch(err=>console.log(err))
    })
}
