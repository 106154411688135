import React from 'react';
import CustomSpinner from "../../../Utility/CustomSpinner";

const ChatLoading = () => {
    return (
        <div className={"w-full flex items-center bg-brown-80 justify-center flex-col gap-2 flex-grow h-full"}>
            <div className={"rounded-12 flex items-center w-16 h-16 p-3 bg-brown-40"}>
                <CustomSpinner size={8}/>
            </div>
            <div className={"text-14 text-gray-90 font-medium"}>
                Loading. Please wait!
            </div>
        </div>
    );
};

export default ChatLoading;
