import React, {useState} from 'react';
import s from "./styles.module.scss"
import {Link, useNavigate} from "react-router-dom";
import {format} from "date-fns";
import MoreBtn from "../../../Sections/MoreBtn";
import {useMutation, useQueryClient} from "react-query";
import axiosClient from "../../../Api/apiClient";
import BookmarkIcon from "../../../Components/BookmarkIcon";
import {useGetProfile} from "../../../Hooks/useGetProfile";
import AdminCanView from "../../../Components/ViewLimit/AdminCanView";

const VideoBox = ({data}) => {
    const {created_at:date,id,title,description: desc, video_url:videoUrl, owner : author} = data

    const queryClient = useQueryClient();
    const navigate =useNavigate()
    const deleteMutation =useMutation(
        (id) => axiosClient.delete(`/videos/${id}`),
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries("/videos")
            },
        })
    const deleteVideo =()=>{
        deleteMutation.mutate(id)
    }
    const editVideo =()=>{
        navigate(`/videos/edit/${id}`)
    }

    const me = useGetProfile()
    const isMine = me.id == author.id
    return (
         <div className={s.container}>
             <Link to={`/videos/${id}`} className={"w-full"}>
                <div className="video relative w-full bg-white overflow-hidden h-[200px] rounded-[6px] mb-3 lg:mb-3">
                    <img className={"w-full h-full object-cover"} src={`https://img.youtube.com/vi/${videoUrl.split("=")[1]}/sddefault.jpg`} alt=""/>
                    <div className={"absolute inset-0 m-auto w-20 h-20 rounded-full bg-brown-50 grid place-items-center"}>
                        <svg width="28" height="32" viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M25.6999 16.3499L1.6999 30.2063L1.6999 2.4935L25.6999 16.3499Z" stroke="white" stroke-width="3.2"/>
                        </svg>
                    </div>
                </div>
                <div className="title text-18 font-medium mb-0 lg:mb-1">
                    {title}
                </div>
             </Link>
            <div className={"text-gray-50 text-12 mb-0  lg:mb-3"}>
                {desc}
            </div>
            <div className={"flex items-center justify-between mt-auto w-full"}>
                <div className="time-badge text-12">
                    {format(new Date(date), 'MMMM dd, yyyy  hh:mm aaaa')}
                </div>
                <div className={"flex items-center"}>

                    <BookmarkIcon isBookmarked={data.is_bookmarked} bookmarkableId={id} bookmarkKey={"Video"} query={"/videos"}/>

                    <AdminCanView>
                        <MoreBtn options={[
                            {name: "Delete", fn: deleteVideo, mutation: deleteMutation,isHidden:!(isMine||me.isOwner)},
                            {name: "Edit", fn: editVideo , isHidden: !isMine},
                        ]}/>
                    </AdminCanView>
                </div>
            </div>
        </div>
        // </Link>
    );
};

export default VideoBox;
