import React from 'react';
import s from "./styles.module.scss"
const SwitchLogic = () => {
    return (
        <div className={s.container}>
            <div className={"circle"}></div>
        </div>
    );
};

export default SwitchLogic;
