import React from 'react';

const ReplayRectangle = ({setReplayContent,replayContent}) => {
    return (
        <div className={"w-full p-4 flex items-center relative gap-4 bg-brown-90"}>
            <div className={" p-2  bg-gray-50 rounded-8 grid place-items-center"}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.7927 10.9417C6.3897 9.10075 8.7397 8.12075 11.7837 8.02475L11.7857 5.54275C11.7867 4.98875 12.0997 4.50075 12.6027 4.26775C13.1077 4.03375 13.6827 4.11275 14.1047 4.47175L21.0007 10.3157C21.3127 10.5787 21.4947 10.9627 21.5017 11.3687C21.5087 11.7727 21.3397 12.1587 21.0397 12.4287L14.1317 18.6737C13.7137 19.0507 13.1367 19.1437 12.6217 18.9167C12.1047 18.6877 11.7837 18.1927 11.7837 17.6267V14.4727C7.6797 14.5927 5.0507 16.2217 3.5337 19.5707C3.45271 19.7517 3.2727 19.8647 3.0787 19.8647C3.0557 19.8647 3.0317 19.8627 3.0087 19.8597C2.7877 19.8277 2.6147 19.6547 2.5837 19.4357C2.5547 19.2267 1.8887 14.2897 4.7927 10.9417Z" fill="#524848"/>
                </svg>
            </div>
            <div className={"border-l-2 pl-4 flex flex-col border-gray-50"}>
                <span className={"text-[#0096D7] mr-3"}>@ {replayContent.author}</span>
                <span className={"limit-line-text "}>
                    {replayContent.text}
                </span>
            </div>

            <div onClick={()=>setReplayContent(null)} className={"bg-brown-80 p-1 right-3 rounded-full ml-auto cursor-pointer"}>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.5 4.5L13.5 13.5" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                    <path d="M13.5 4.5L4.5 13.5" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                </svg>
            </div>
        </div>

    );
};

export default ReplayRectangle;
