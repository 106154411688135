import React, {useState} from 'react';
import ThreadPopup from "./ThreadPopup";

const AddThread = () => {
    const [createThreadOpen,setCreateThreadOpen]=useState(false)

    return (
        <>
            <div onClick={()=>setCreateThreadOpen(true)} className="bg-white/80 rounded-8 cursor-pointer grid place-items-center w-10 h-10 ">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 1V11" stroke="#342E2E" stroke-width="1.5" stroke-linecap="square"/>
                    <path d="M11 6H1" stroke="#342E2E" stroke-width="1.5" stroke-linecap="square"/>
                </svg>
            </div>

            {createThreadOpen &&
                <ThreadPopup setCreateThreadOpen={setCreateThreadOpen}/>
            }
        </>
    );
};

export default AddThread;
