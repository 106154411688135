import React, {useEffect, useState} from 'react';
import BtnTextForMutation from "../../Components/BtnTextForMutation";
import {useMutation, useQueryClient} from "react-query";
import axiosClient from "../../Api/apiClient";
import CustomSpinner from "../../Utility/CustomSpinner";

const AddMentor = ({setIsAddPopOpen}) => {
    const [searchKey,setSearchKey]=useState("")

    const searchMutation = useMutation({
        mutationKey:["searchMentor" ,searchKey],
        mutationFn:()=>axiosClient.get(`/user/search/${searchKey}`)
    })
    const searchedUsers = searchMutation?.data?.data.resulting_data

    useEffect(()=>{

        if (searchKey.length != 0) {
            searchMutation.mutate()
        }
    },[searchKey])
    const UserSearchedElement=({i})=>{
        const createMutation = useMutation({
            mutationFn: (data)=>axiosClient.post(`/mentors`, data),
            onSuccess:()=>{
                setTimeout(()=>{
                    createMutation.reset()
                },2000)
            }
        })
        const handleAddMentor =()=>{

            createMutation.mutate({
                "email": i.email
            })
        }
        return(
            <div className={"flex justify-between gap-3 items-center"}>
                <div className={"flex items-center gap-4"}>
                    <div className={"rounded-full h-12 w-12 overflow-hidden"}>
                        <img className={"w-full h-full object-cover"} src={i.profile_image_url? i.profile_image_url : "/media/defaultAvatar.png"} alt=""/>
                    </div>
                    <div>
                        <div className={"flex gap-2 items-center mb-1"}>
                            <div className={"font-medium"}>{i.name}</div>
                            <div className={"font-medium text-12"}>@{i.username}</div>
                        </div>
                        <div className={"text-gray-90 text-14"}>{i.email}</div>
                    </div>

                </div>
                <button onClick={handleAddMentor} className={"ml-auto w-22 text-14 btn grey-50"}>
                    <BtnTextForMutation defaultText={"Send invite"} successText={"Sent!"} mutation={createMutation} />
                </button>
            </div>
        )
    }
    return (
        <div className={"pop-up flex-col p-6 w-full max-w-[600px]"}>
            <div className={"flex pb-6 border-brown-90 border-b-1 justify-between items-center"}>
                <div className={"text-18 font-medium"}>
                    Add new mentor
                </div>
                <div onClick={()=>{
                    setIsAddPopOpen(false)
                    setSearchKey("")
                }} className="cursor-pointer">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 6L18 18" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                        <path d="M18 6L6 18" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div className={"my-6"}>
                <input onInput={(e)=>setSearchKey(e.target.value)} value={searchKey} className={'md:w-full'} type="text" placeholder={"Search"}/>
            </div>
            <div className={"py-4"}>
                {searchMutation?.isLoading ?
                    <div className={"flex justify-center "}>
                        <CustomSpinner/>
                    </div>
                    :
                    <div className={"grid gap-3 max-h-[300px] overflow-auto"}>
                        {searchedUsers?.length == 0 && "no result"}
                        {
                            searchedUsers?.map(i=>{
                                return(
                                  <UserSearchedElement i={i}/>
                                )
                            })
                        }
                    </div>
                }
            </div>

        </div>
    );
};

export default AddMentor;
