import React, {useState,Suspense} from 'react';
import MobilePinnedMainHeader from "../../Sections/MobilePinnedMainHeader";
import s from "./styles.module.scss"
import classNames from "classnames";
import {useMutation, useQuery, useQueryClient} from "react-query";
import MoreBtn from "../../Sections/MoreBtn";
import {Link, useNavigate} from "react-router-dom";
import axiosClient from "../../Api/apiClient";
import LoadingPage, {FullPageLoading} from "../../Components/LoadingPage";
import AdminCanView from "../../Components/ViewLimit/AdminCanView";
import SuperAdminCanView from "../../Components/ViewLimit/SuperAdminCanView";
import PageTitle from "../../Components/PageTitle/PageTitle";
import {useAlert} from "react-alert";
const FAQ = () => {
    PageTitle("Frequently asked questions")
    const alert = useAlert()
    const FaqBox=({title,children,id})=>{
        const [isActive,setIsActive]=useState(false)

        const queryClient = useQueryClient();
        const navigate =useNavigate()
        const deleteMutation =useMutation(
            (id) => axiosClient.delete(`/posts/${id}`),
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("/posts")
                    alert.success("Deleted successfully!")
                },
                onError:()=>{
                    alert.success("an error occurred!")

                }
            })
        const deleteVideo =()=>{
            deleteMutation.mutate(id)
        }
        const editVideo =()=>{
            navigate(`/faq-and-resources/edit/${id}`)
        }
        return(
            <div  className={classNames("faq-box p-6",isActive && "opened")}>
                <div className={"flex "}>
                    <div onClick={()=>{setIsActive(!isActive)}} className="title cursor-pointer flex justify-between md:items-center w-full gap-4 text-18 font-medium text-gray-50">
                        {title}
                        <div className={"flex items-center gap-2"}>
                            <div className={classNames(isActive && "rotate-180"," transition-all h-fit mt-2 md:mt-0 duration-300")}>
                                <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16 1.5L9 8.5L2 1.5" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                                </svg>
                            </div>

                        </div>
                    </div>
                   <SuperAdminCanView>
                       <MoreBtn options={[
                           {name:"Delete",fn:deleteVideo,mutation:deleteMutation},
                           {name:"Edit",fn:editVideo},
                       ]}/>
                   </SuperAdminCanView>
                </div>
                <div className={classNames("desc text-gray-90 font-medium",isActive && "opened mt-2")}>
                    {children}
                </div>
            </div>
        )
    }
    const FaqList=()=>{
        const { data  } = useQuery({
            queryKey:"/posts",
        })
        const faqData = data.resulting_data
        return(
            <div className={"faq-wrapper grid gap-3"}>
                {[...faqData].reverse().map((i,index)=>
                    <FaqBox key={i.id} id={i.id}  title={i.title}>
                        <p>{i.description}</p>
                    </FaqBox>
                )}
            </div>
        )
    }

    return (
        <div className={classNames(s.container,"flex flex-col h-full")}>
            <MobilePinnedMainHeader title={"Faq and resources"}>
                <SuperAdminCanView>
                    <Link to={"add"}  className="btn grey-50">
                        Add new post
                    </Link>
                </SuperAdminCanView>
            </MobilePinnedMainHeader>
            <div className={"flex-grow overflow-auto  pad-layout py-8 "}>
              <div className={"max-w-[780px] flex flex-col h-full w-full mx-auto"}>
                  <div className={"text-24 font-semibold mb-8"}>
                      Threads
                  </div>
                  <Suspense fallback={<div className={"flex-grow grid place-items-center"}><FullPageLoading/></div>}>
                      <FaqList/>
                  </Suspense>
              </div>
            </div>
        </div>
    );


};

export default FAQ;
