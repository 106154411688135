import React from 'react';
import s from "./style.module.scss"
const LoginLayout = (props) => {


    return (
        <div className={"h-screen bg-brown-40 overflow-auto flex flex-col relative "} >
            <div className={`${s.container}  p-7   md:px-0 flex-grow w-full md:w-[450px]  mx-auto  relative  md:gap-20  flex flex-col  md:p-12`}>
                    <div className="box  md:m-auto mx-auto  md:p-0  md:bg-transparent flex-col  relative flex  w-full rounded-20    ">
                        {props.children}
                    </div>
            </div>
        </div>

    );
};

export default LoginLayout;
