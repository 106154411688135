import React, {useEffect, useState} from 'react';
import LoginLayout from "../LoginLayout";
import PasswordInput from "../../../Utility/PasswordInput";
import {Link, useLocation, useNavigate} from "react-router-dom";
import CustomSpinner from "../../../Utility/CustomSpinner";
import {useMutation} from "react-query";
import axiosClient from "../../../Api/apiClient";
import {useAlert} from "react-alert";
import PageTitle from "../../../Components/PageTitle/PageTitle";
import {useAtom} from "jotai";
import {tokenAtom} from "../../../App";
import axios from "axios";

const NewPassword = () => {
    PageTitle("New password")
    const [errorMessage,setErrorMessage] = useState("")
    const [password,setPassword]=useState("")
    const [tokenInUrl,setTokenInUrl]=useState(null)
    const [confirmPassowrd,setConfirmPassword]=useState("")
    const alert = useAlert()
    const navigate = useNavigate()
    const location = useLocation()

    const [token,setToken]=useAtom(tokenAtom)
    const SetToken =(token)=>{
        localStorage.setItem("token",token)
        setToken(token)
    }


    useEffect(()=>{
        const urlParams = new URLSearchParams(window.location.search);
        const TokenParam = urlParams.get('token')
        setTokenInUrl(TokenParam)
        SetToken(TokenParam)
    },[])

    let baseUrl = process.env.REACT_APP_BASE_URL
    const axiosForNewPassword = axios.create({
        baseURL: baseUrl,
    });

    const mutation = useMutation({
        mutationFn: (data)=>axiosForNewPassword.post('/user/new-password', data,{}),
        onSuccess: (res) => {
            alert.success("Changed successfully!")
            setTimeout(()=>{
                navigate("/login")
            },1000)
        },
        onError:(err)=>{
            alert.error(Object.values(err.response.data.error)[0])
        }
    })


    const SubmitHandler = (e) =>{
        if (password != confirmPassowrd) {
            setErrorMessage("Password doesnt match!");
            return;
        }
        const dataToPost ={
            "new_password": password,
            "confirm_new_password":confirmPassowrd,
            "token":tokenInUrl
        }
        mutation.mutate(dataToPost)

    }

    return (
        <LoginLayout>
            <div>
                <h2 className={" whitespace-nowrap md:text-center mb-2.5 text-[56px] font-medium lg:mb-5"}>New passowrd</h2>
                <p className="w-full  md:text-center mb-6 text-[#D9D9D9]">Welcome back! Please enter your new passowrd.</p>
                <div className="form">
                    <div className="input-box">
                        <div className="label" > New password</div>
                        <PasswordInput  value={password} onChange={(e)=>setPassword(e.target.value)} placeholder={"Enter Your New password"}/>
                    </div>
                    <div className="input-box">
                        <div className="label"> Confirm passowrd</div>
                        <PasswordInput  value={confirmPassowrd} onChange={(e)=>setConfirmPassword(e.target.value)} placeholder={"Confirm Your password"} />
                    </div>

                    <div className={"text-red-secondary"}>
                        {errorMessage}
                    </div>
                    <button className={"w-full submit-btn cursor-pointer"} onClick={SubmitHandler} >
                        {mutation.isLoading?
                            <div>
                                <CustomSpinner size={9}/>
                            </div>
                            :
                            "Change password"
                        }
                    </button>

                    {/*<div className="flex gap-4 items-baseline justify-center">*/}
                    {/*    <p>*/}
                    {/*        Don’t wanna an account?*/}
                    {/*    </p>*/}
                    {/*    <Link to={"/pre_register"}>*/}
                    {/*        <h6 className=" cursor-pointer text-14">*/}
                    {/*            Pre register*/}
                    {/*        </h6>*/}
                    {/*    </Link>*/}
                    {/*</div>*/}
                </div>
            </div>

        </LoginLayout>
    );
};

export default NewPassword;
