import React from 'react';
import coin from "../coin.png"
import MoreOptionsDropdown from "../../../Sections/MoreBtn/MoreOptionsDropdown";
import MoreBtn from "../../../Sections/MoreBtn";
import {useNavigate} from "react-router-dom";
import {useMutation, useQueryClient} from "react-query";
import axiosClient from "../../../Api/apiClient";
import {useUpdatePost} from "../../../Hooks/useUpdatePost";
import BookmarkIcon from "../../../Components/BookmarkIcon";
const CryptocurrencyBox = ({id,isBookmarked,authorName,authorRole,authorPic,title,coins,price}) => {
    const{handleDelete,handleEdit,deleteMutation} = useUpdatePost(id,"/bundles","/cryptocurrencies")
    return (
        <div className={"w-full p-4  rounded-10 border-1 relative border-brown-100"}>
            <div className="top flex gap-3 justify-between">
                <div className={"flex gap-3 items-center"}>
                    <div className="img grid place-items-center   w-[58px] h-[58px] overflow-hidden rounded-full bg-white">
                        <img src={authorPic?authorPic:"/media/defaultAvatar.png"} className={"w-full h-full object-cover "} alt=""/>
                    </div>
                    <div className="text">
                        <div className={"text-16 mb-1 font-bold"}>
                            {authorName}
                        </div>
                        <div className="flex gap-3 text-gray-50 text-12 items-center">
                            {authorRole}
                        </div>
                    </div>
                </div>
                <div className={"flex items-start gap-2"}>
                    <BookmarkIcon isBookmarked={isBookmarked} bookmarkKey={"Bundle"} bookmarkableId={id} query={"/bundles"}/>
                    <MoreBtn options={[
                        {name: "Delete", fn: handleDelete, mutation: deleteMutation},
                        {name: "Edit", fn: handleEdit},
                    ]}/>
                </div>

            </div>
            <div className={"text-gray-50 text-14 font-medium mt-4 pb-4 border-b-1 border-brown-110"}>
                {title}
            </div>
            <div className="coins-list mt-1  ">
                {coins.map(c=>
                    <div className="coin flex gap-2.5 items-center w-full py-4 border-b-1 border-b-brown-90">
                        <div className="icon grid place-items-center  w-[50px] aspect-square p-2 rounded-[8px] bg-brown-90">
                            <img src={c.url} alt={"coin"} className={"w-full h-full object-contain"} />
                        </div>
                        <div className="title font-medium">{c.title}</div>
                        <div className=" py-1 px-2 rounded-30 bg-brown-50 text-12 text-gray-90">{c.code}</div>
                        <div className="ml-auto text-12 text-gray-90"> ${c.price}</div>
                    </div>
                )}
                {/*<div className="coin flex gap-2.5 items-center w-full py-4 border-b-1 border-b-brown-90">*/}
                {/*    <div className="icon grid place-items-center  w-[50px] aspect-square rounded-[8px] bg-brown-90">*/}
                {/*        <img src={coin} alt={"coin"} />*/}
                {/*    </div>*/}
                {/*    <div className="title font-medium">Bitcoin</div>*/}
                {/*    <div className=" py-1 px-2 rounded-30 bg-brown-50 text-12 text-gray-90"> BTC</div>*/}
                {/*    <div className="ml-auto text-12 text-gray-90"> £13,789.36</div>*/}
                {/*</div>*/}
                {/*<div className="coin flex gap-2.5 items-center w-full py-4 border-b-1 border-b-brown-90">*/}
                {/*    <div className="icon grid place-items-center  w-[50px] aspect-square rounded-[8px] bg-brown-90">*/}
                {/*        <img src={coin} alt={"coin"}/>*/}
                {/*    </div>*/}
                {/*    <div className="title font-medium">Bitcoin</div>*/}
                {/*    <div className=" py-1 px-2 rounded-30 bg-brown-50 text-12 text-gray-90"> BTC</div>*/}
                {/*    <div className="ml-auto text-12 text-gray-90"> £13,789.36</div>*/}
                {/*</div>*/}
                {/*<div className="coin flex gap-2.5 items-center w-full py-4 border-b-1 border-b-brown-90">*/}
                {/*    <div className="icon grid place-items-center  w-[50px] aspect-square rounded-[8px] bg-brown-90">*/}
                {/*        <img src={coin} alt={"coin"}/>*/}
                {/*    </div>*/}
                {/*    <div className="title font-medium">Bitcoin</div>*/}
                {/*    <div className=" py-1 px-2 rounded-30 bg-brown-50 text-12 text-gray-90"> BTC</div>*/}
                {/*    <div className="ml-auto text-12 text-gray-90"> £13,789.36</div>*/}
                {/*</div>*/}
                {/*<div className="coin flex gap-2.5 items-center w-full py-4 ">*/}
                {/*    <div className="icon grid place-items-center  w-[50px] aspect-square rounded-[8px] bg-brown-90">*/}
                {/*        <img src={coin} alt={"coin"}/>*/}
                {/*    </div>*/}
                {/*    <div className="title font-medium">Bitcoin</div>*/}
                {/*    <div className=" py-1 px-2 rounded-30 bg-brown-50 text-12 text-gray-90"> BTC</div>*/}
                {/*    <div className="ml-auto text-12 text-gray-90"> £13,789.36</div>*/}
                {/*</div>*/}

            </div>
        </div>
    );
};

export default CryptocurrencyBox;
