import React from 'react';
import {useMutation, useQueryClient} from "react-query";
import axiosClient from "../../Api/apiClient";

const BookmarkIcon = ({isBookmarked,bookmarkableId,query,bookmarkKey}) => {
    const queryClient = useQueryClient();

    const postBookmark = useMutation({
        mutationFn: (d)=>axiosClient.post(`/bookmark`, d),
        onSuccess:()=>{
            queryClient.invalidateQueries(query)
        },
        onError:(err)=>{
        }
    })
     const deleteBookmark = useMutation({
            mutationFn: (d)=>axiosClient.delete(`/bookmark`, {data:d}),
            onSuccess:()=>{
                queryClient.invalidateQueries(query)
            },
            onError:(err)=>{
            }
        })


    const submitBookmark =()=>{
        postBookmark.mutate({
            "bookmarkable_type" : `App\\Models\\${bookmarkKey}`,
            "bookmarkable_id": bookmarkableId
         })
    }
    const submitDeleteBookmark=()=>{
        deleteBookmark.mutate({
            "bookmarkable_type" : `App\\Models\\${bookmarkKey}`,
            "bookmarkable_id": bookmarkableId
        })
    }

    return isBookmarked?
        <div className={"cursor-pointer"} onClick={submitDeleteBookmark}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.07 2C17.78 2 19.97 3.07 20 5.79V20.97C20 21.14 19.96 21.31 19.88 21.46C19.75 21.7 19.53 21.88 19.26 21.96C19 22.04 18.71 22 18.47 21.86L11.99 18.62L5.5 21.86C5.351 21.939 5.18 21.99 5.01 21.99C4.45 21.99 4 21.53 4 20.97V5.79C4 3.07 6.2 2 8.9 2H15.07ZM15.75 8.04H8.22C7.79 8.04 7.44 8.39 7.44 8.83C7.44 9.269 7.79 9.62 8.22 9.62H15.75C16.18 9.62 16.53 9.269 16.53 8.83C16.53 8.39 16.18 8.04 15.75 8.04Z" fill="#D9D9D9"/>
            </svg>
        </div>
                :
      <div className={"cursor-pointer"} onClick={submitBookmark}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M14.701 2C18.093 2 20.039 3.679 20.039 6.604V21.14C20.039 21.75 19.725 22.299 19.197 22.606C18.671 22.914 18.037 22.92 17.505 22.62L11.545 19.253L5.53 22.627C5.27 22.773 4.985 22.847 4.699 22.847C4.404 22.847 4.109 22.768 3.841 22.61C3.314 22.303 3 21.754 3 21.145V6.421C3 3.611 4.947 2 8.342 2H14.701ZM14.701 3.5H8.342C5.793 3.5 4.5 4.482 4.5 6.421V21.145C4.5 21.239 4.554 21.29 4.599 21.316C4.644 21.344 4.715 21.364 4.797 21.318L11.179 17.738C11.407 17.611 11.686 17.61 11.915 17.739L18.242 21.313C18.325 21.361 18.396 21.339 18.441 21.312C18.486 21.285 18.539 21.234 18.539 21.14L18.5386 6.49004C18.5311 5.62937 18.3645 3.5 14.701 3.5ZM15.1398 8.7285C15.5538 8.7285 15.8898 9.0645 15.8898 9.4785C15.8898 9.8925 15.5538 10.2285 15.1398 10.2285H7.8218C7.4078 10.2285 7.0718 9.8925 7.0718 9.4785C7.0718 9.0645 7.4078 8.7285 7.8218 8.7285H15.1398Z" fill="#A6A6A6"/>
          </svg>
      </div>

};

export default BookmarkIcon;
