import React, {useRef, useState} from 'react';
import CommentItem from "./CommentItem";
import classNames from "classnames";
import s from "./styles.module.scss"
import {useMutation, useQueryClient} from "react-query";
import axiosClient from "../../../Api/apiClient";
import CustomSpinner from "../../../Utility/CustomSpinner";
const Comments = ({comments,id}) => {
    const [isCommentsOpened,setIsCommentsOpened]=useState(true)

    const text_input = useRef();
    const [textBoxValue,setTextBoxValue]=useState("")
    const updateValue =(e)=>{
        setTextBoxValue(e.target.innerText)
    }
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (messageData)=>axiosClient.post(`/videos/${id}/comments`, messageData),
        onSuccess: async (res) => {
            queryClient.invalidateQueries({ queryKey: [`/videos/${id}`] });
            setTextBoxValue(null)
            text_input.current.innerText = ""
            setTimeout(()=>{
                mutation.reset()
            },2000)
        },
    })

    const handleSubmit =()=>{
        mutation.mutate({
            "content" : textBoxValue
        })
    }
    return (

        <div className={classNames("comments-dropdown ",s.container)}>
            <div onClick={()=>{setIsCommentsOpened(!isCommentsOpened)}} className="title  justify-between flex w-full border-t-1 border-b-1 border-brown-100 py-4 px-6 text-18 font-medium text-gray-90">
                <div className={"text-gray-90 font-medium text-18"}>Comments</div>
                <div className={"flex gap-3 text-18 text-gray-90 font-medium items-center"}>
                    <span>{comments.length}</span>
                    <div className={isCommentsOpened ? "rotate-180" : ""}>
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14 7.25L8.75 12.5L3.5 7.25" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        </svg>
                    </div>
                </div>
            </div>
            {isCommentsOpened &&
                <div className={"mt-8"}>
                    <div className={"relative px-6 "}>
                        <div
                            className="input !block px-6 border-1 border-brown-110 w-full text-14  py-4 text-gray-90 bg-brown-50"
                            role="textbox"
                            ref={text_input}
                            onInput={updateValue}
                            data-placeholder="Write a message  ..."
                            data-focused-advice="Write a message  ..."
                            contentEditable>
                        </div>
                        <button onClick={handleSubmit} className={"absolute right-10 w-24 !h-10 justify-center bg-brown-40 text-14 text-gray-90 font-bold  rounded-8  px-2 bottom-2 h-fit my-auto flex items-center gap-2"}>
                            {mutation.isLoading?
                                <div>
                                    <CustomSpinner/>
                                </div>
                                :
                                mutation.isError?
                                    "error"
                                    :
                                    mutation.isSuccess?
                                        "Sent!"
                                        :
                                        <>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19.2299 13.3635L6.25674 20.4751C5.08076 21.1199 3.69929 20.0618 4.00845 18.7534L5.60476 12.0008L4.00845 5.2463C3.69929 3.93794 5.08076 2.88076 6.25674 3.52556L19.2299 10.6371C20.304 11.2267 20.304 12.774 19.2299 13.3635Z" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M13.6419 11.9785L5.64166 12.0208" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            send
                                        </>
                            }
                        </button>
                    </div>
                    {comments.length !=0 ?
                        <div className={"comments mb-6 mt-6  px-6 py-4 pb-10 max-h-[300px] overflow-auto gap-10 flex flex-col"}>
                            {comments.map(item => (
                                <CommentItem id={item.id} date={item.created_at} videoId={id} key={item.id} content={item.content} author={item.owner}
                                             authorPic={item.owner.profile_image_url}/>
                            ))}
                        </div>
                        :
                        <div className={" mt-6"}></div>
                    }
                </div>
            }
        </div>
    );
};

export default Comments;
