import React, {Suspense, useState} from 'react';
import MobilePinnedMainHeader from "../../../Sections/MobilePinnedMainHeader";
import s from "./style.module.scss"
import CourseBoxDraft from "../CourseBoxDraft";
import {useQuery} from "react-query";
import CourseBox from "../CourseBox";
import PageTitle from "../../../Components/PageTitle/PageTitle";
const CoursesDraft = () => {
    PageTitle("Courses")

    const [myCoursesCount,setMyCoursesCount]=useState("...")

    const DataList =()=>{
        const {data} = useQuery({
            queryKey:"/courses",
            onSettled:(res)=>{
                setMyCoursesCount(res.resulting_data.my_courses.data.length)
            }
        })
        const myCourses =data.resulting_data.my_courses.data

        return(
            <div className="drafts-wrapper p-8 pt-20 overflow-auto">
                {
                    [...myCourses].reverse().map(item =>(
                        <CourseBox isDraft coverId={item.cover_file_id} name={item.author.name} id={item.id} key={item.id} role={item.occupation} coursePic={item.cover_file_url} title={item.title} desc={item.content} />
                    ))
                }
            </div>
        )
    }
    return (
        <div className={s.container}>
            <MobilePinnedMainHeader  title={"Courses darft"}/>
            <Suspense fallback={"Loading ...."}>
                <DataList/>
            </Suspense>
        </div>
    );
};

export default CoursesDraft;
