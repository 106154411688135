import React, {useRef, useState} from 'react';
import {format, formatDistance} from "date-fns";
import {Link} from "react-router-dom";
import {useMutation, useQueryClient} from "react-query";
import axiosClient from "../../Api/apiClient";
import {useAlert} from "react-alert";
import CustomSpinner from "../../Utility/CustomSpinner";
import PopupContainer from "../../Sections/PopupContainer";
import useOnClickOutside from "../../Hooks/useOnClickOutside";

export function ActivityBox({hasIcon=true,hasTag=false,data,hasQoute,title,desc,iconPath,mainBtnText,secondaryBtn=false,btnLink,time}){
    return (
      <div style={{boxShadow: "0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)"}} className={" w-full h-fit flex-col flex  px-6 py-4 bg-brown-50 border-b-1 lg:border-1 lg:rounded-12 border-brown-110"}>
          {hasTag && <div
              className={"flex bg-brown-90 border-brown-110 border-1 p-1 md:rounded-30 w-fit items-center gap-1 pr-2 mb-4"}>
              <div className={"border-2 bg-brown-50 w-fit rounded-full p-1  border-brown-110 "}>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                      <path d="M2 9.87009L3.25417 7.99902L5.11483 9.24622" stroke="white" stroke-width="1.125"
                            stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M14.0002 6.12744L12.746 7.99857L10.8854 6.7513" stroke="white"
                            stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
                      <path
                          d="M3.27478 8.10156C3.32817 10.6656 5.42388 12.7283 8.00071 12.7283C9.61471 12.7283 11.0401 11.9192 11.8926 10.6848"
                          stroke="white" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
                      <path
                          d="M12.7259 7.8977C12.6725 5.33362 10.5768 3.271 7.99996 3.271C6.38597 3.271 4.96059 4.08003 4.10803 5.31447"
                          stroke="white" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
              </div>
              Updated
          </div>
          }
                <div className={"flex flex-col items-center sm:flex-row gap-4 justify-between"}>
                   <div>

                       <div className="flex  gap-3 items-center mb-6 lg:mb-0 ">
                           {hasIcon &&
                               <div
                               className="svg rounded-12 w-12 h-12 bg-brown-100 grid p-2 place-items-center border-2 border-brown-100  ">
                               <img src={iconPath} className={"w-full h-full object-contain"} alt=""/>
                              </div>
                           }
                           <div className={"text-18 font-medium"}>
                               {title}
                               <div className={"text-gray-90 text-14 mt-1"}>
                                   {desc}
                               </div>
                           </div>
                       </div>
                       {time &&
                           <div className={"text-12 text-gray-90 mt-3"}>
                               {formatDistance(new Date(time),new Date())} ago
                           </div>
                       }
                   </div>

                    <div className={"flex flex-col w-full sm:w-fit md:flex-row md:items-center gap-4"}>
                        {secondaryBtn &&
                            <a href={btnLink} className={"btn w-full mt-auto transparent sm:w-[160px] whitespace-nowrap"}>
                            Secondary
                        </a>
                        }
                        {mainBtnText &&
                            <Link to={btnLink} className={"btn w-full mt-auto grey-50 transparent md:w-[160px] whitespace-nowrap"}>
                                {mainBtnText}
                           </Link>
                        }

                    </div>
                </div>
          {hasQoute &&
              <div className={"bg-brown-100 mt-4 w-full flex  gap-4 py-4 px-3 rounded-[6px]"}>
                  <div className={"w-6 h-6 flex-shrink-0 bg-black rounded-full overflow-hidden"}>
                      <img className={"w-full h-full object-cover"} src={data.author.profile_image_url || "/media/defaultAvatar.png"} alt="profile img"/>
                  </div>
                  <div>
                      <div className="name text-14 font-bold grey-50">{data.author.name}</div>
                      <div className="text text-gray-90 text-14 font-medium">
                          {data.text}
                      </div>
                  </div>
              </div>
          }

      </div>
    );
}



export const AdminSessionNotif = ({data}) => {
    const alert = useAlert();
    const {notifiable:sessionData} =  data
    const time = sessionData.session_date.split(" ")[1].slice(0,10)
    const utcDate = sessionData.session_date.split(" ")[0]+"T"+time+"Z"
    const [meetLink,setMeetLink]=useState()
    const [note,setNote]=useState()

    const [isRejectPopOpen,setIsRejectPopOpen]=useState(false)

    const rejectBox = useRef()

    const queryClient = useQueryClient();
    useOnClickOutside(rejectBox,()=>setIsRejectPopOpen(false))
    const acceptMutation = useMutation({
        mutationFn:(data)=>axiosClient.put(`/sessions/${sessionData.id}`,data),
        onSuccess:(res)=>{
            alert.success("Session accepted!")
            queryClient.invalidateQueries("/activities")
        },
        onError:(err)=>{
            alert.error(Object.values(err.response.data.error)[0])
        }
    })
    const rejectMutation = useMutation({
        mutationFn:(data)=>axiosClient.put(`/sessions/${sessionData.id}`,data),
        onSuccess:(res)=>{
            alert.success("Session declined!")
            queryClient.invalidateQueries("/activities")
            setIsRejectPopOpen(false)
        },
       onError:(err)=>{
            alert.error(Object.values(err.response.data.error)[0])
       }
    })

    const [meetLinkError,setMeetLinkError]=useState(false)

    function isUrlValid(userInput) {
        let res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        if(res == null)
            return false;
        else
            return true;
    }

    const handleAccept =()=>{
        if (meetLink){
            if (!isUrlValid(meetLink))
                {
                    setMeetLinkError(true)
                    alert.error("This is not a meeting link!")
                }
            else{
                setMeetLinkError(false)
                acceptMutation.mutate({
                    "title": sessionData.title,
                    "description": sessionData.description,
                    "session_date": sessionData.session_date,
                    "duration" : sessionData.duration,
                    "mentor_id" : sessionData.mentor.id,
                    "status":"accepted",
                    "note":"",
                    "link":meetLink
                })
            }
        }else{
            setMeetLinkError(true)
            alert.error("Please insert meeting link!")
        }

    }

    const handleReject =()=>{
        rejectMutation.mutate({
            "title": sessionData.title,
            "description": sessionData.description,
            "session_date": sessionData.session_date,
            "duration" : sessionData.duration,
            "mentor_id" : sessionData.mentor.id,
            "status":"rejected",
            "note":note,
            "link":""
        })
    }

    const File =()=>{
        return(
            <div className={"flex justify-between w-[300px] items-center rounded-8 border-brown-90 bg-brown-80 py-2.5 px-4"}>
                <div>
                    <div className={"text-14 text-gray-50 font-medium"}>
                        Tech design.pdf
                    </div>
                    <div className={"text-12 text-gray-90"}>
                        200 KB
                    </div>
                </div>
                <div>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.88086 20.8811H20.6409V3.12109H2.88086V20.8811Z" stroke="#A6A6A6" stroke-width="1.44" stroke-linecap="round"/>
                        <path d="M11.7598 15.2054V8.07812" stroke="#A6A6A6" stroke-width="1.44" stroke-linecap="square"/>
                        <path d="M15.3583 12.3086L11.7602 15.922L8.16211 12.3086" stroke="#A6A6A6" stroke-width="1.44" stroke-linecap="square"/>
                    </svg>
                </div>
            </div>
        )
    }
    return(
        <div className={"sessionBox"}>
            <div className="profile mb-6 flex gap-2 items-center">
                <div className="circle h-8 w-8 aspect-square rounded-full overflow-hidden bg-white">
                    <img className={"w-full h-full object-cover"} src={sessionData.requester.profile_image_url? sessionData.requester.profile_image_url : "/media/defaultAvatar.png"} alt=""/>
                </div>
                <div className="name text-12 ">
                    {sessionData.requester.name}

                </div>
            </div>
            <div className={"text-18 flex justify-between itemce font-semibold"}>
                <div>
                    {sessionData.title}
                </div>

            </div>
            <div className={"my-4 text-gray-90 text-14 font-medium"}>
                {sessionData.description}
            </div>
            <div className={"mb-4 flex gap-3"}>

                <div className={"w-fit flex items-center  gap-2 rounded-8 border-brown-90 border-1 p-2"}>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.88721 7.29639H14.758" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        <path d="M11.7778 9.89746H11.7842" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        <path d="M8.82275 9.89746H8.8292" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        <path d="M5.86108 9.89746H5.8675" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        <path d="M11.7778 12.4858H11.7842" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        <path d="M8.82275 12.4858H8.8292" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        <path d="M5.86108 12.4858H5.8675" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        <path d="M11.5129 2.36475V4.55641" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        <path d="M6.13281 2.36475V4.55641" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8139 4.41651C14.8139 3.86422 14.3662 3.4165 13.8139 3.4165H3.82593C3.27364 3.4165 2.82593 3.86422 2.82593 4.4165V14.6848C2.82593 15.2371 3.27364 15.6848 3.82593 15.6848H13.8139C14.3662 15.6848 14.8139 15.2371 14.8139 14.6848V4.41651Z" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                    </svg>

                    <div className={"bg-brown-40 py-1 px-2 text-14 rounded-[4px]"}>
                        {format(new Date(utcDate),"EEEE,MMMM dd,yyyy / HH:mm")}
                    </div>

                </div>
            </div>

            {
                sessionData.attachments.length >0 &&
                <div className="files mb-5 flex flex-wrap gap-3">
                    {sessionData.attachments.map(i=>(
                        <a target={"_blank"} href={i.url} className="file p-1 flex w-full md:w-[300px] items-center  bg-brown-100 w-fit rounded-8">
                            <div className="icon h-8 w-8 rounded-8 grid place-items-center mr-2">
                                <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7379 0.761747H5.08492C3.00493 0.753776 1.29993 2.41178 1.25093 4.49078V15.2038C1.20493 17.3168 2.87993 19.0678 4.99293 19.1148C5.02393 19.1148 5.05392 19.1158 5.08492 19.1148H13.0739C15.1679 19.0298 16.8179 17.2998 16.803 15.2038V6.03778L11.7379 0.761747Z" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M11.4756 0.75V3.659C11.4756 5.079 12.6236 6.23 14.0436 6.234H16.7986" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M11.2887 13.3594H5.88867" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M9.24272 9.60547H5.88672" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <div className="text text-12">
                                <div className="name mr-2">
                                    {i.name}
                                </div>
                            </div>
                            <div className="icon-download ml-auto mr-2">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.5637 17.36C20.0008 16.711 21 15.2651 21 13.5858C21 10.9899 19.1698 9.44677 16.897 9.44579C16.897 7.81315 15.6165 4.54688 12 4.54688C8.38346 4.54688 7.10303 7.81315 7.10303 9.44579C4.83308 9.46525 3 10.9899 3 13.5858C3 15.2651 3.99924 16.711 5.43632 17.36" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12.0003 19.4516L12 12.457" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M9.22852 16.6797L11.9995 19.4507L14.7706 16.6797" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>

                            </div>
                        </a>
                    ))
                    }
                </div>
            }


            <div className={"flex flex-col w-full md:flex-row gap-4 justify-between items-end"}>
                <div className={"w-full md:w-fit"}>
                    <div className={"mb-2 text-14 font-medium"}>
                        Meet Link :
                    </div>
                    <div className="input-box w-full">
                        <input value={meetLink} onChange={(e)=>setMeetLink(e.target.value)} type="text" className={`w-full md:w-[400px] bg-brown-40 ${meetLinkError && "border-red-main"}`} placeholder={"Paste your link here"}/>
                    </div>
                </div>
                <div className={"btn-wrapper w-full md:w-fit gap-2 flex "}>
                    {/*{state===0 &&*/}
                        <>
                            <button onClick={()=>setIsRejectPopOpen(true)} className="btn md:flex-grow-0 flex-grow dark">
                                Decline

                            </button>
                            <button onClick={handleAccept} className="btn flex-grow md:flex-grow-0 !font-medium grey-50">
                                {acceptMutation.isLoading?
                                        <CustomSpinner/>
                                    :
                                    "Accept"
                                }
                            </button>
                        </>
                    {/*}*/}
                    {/*{state===1 &&*/}
                    {/*    <>*/}
                    {/*        <div className="btn dark">*/}
                    {/*            Cancel*/}
                    {/*        </div>*/}

                    {/*    </>*/}
                    {/*}*/}
                </div>
            </div>
            {isRejectPopOpen &&
                <PopupContainer>
                    <div ref={rejectBox} className={"sessionBox w-full max-w-[1000px]  !bg-brown-40"}>
                        <div className={"font-medium text-18 mb-4"}>
                            Let meeting requester know why you declined.
                        </div>
                        <textarea value={note} onChange={(e)=>setNote(e.target.value)} className={"w-full mb-4 min-h-[150px]"}/>
                        <div className={"btn-wrapper justify-end gap-2 flex "}>
                                <button onClick={()=>setIsRejectPopOpen(false)} className="btn dark">
                                    Cancel
                                </button>
                                <button disabled={!note} onClick={handleReject} className="btn !font-medium grey-50">
                                    {rejectMutation.isLoading?
                                        <CustomSpinner/>:
                                        "Decline and send"
                                    }
                                </button>
                        </div>
                    </div>
                </PopupContainer>
            }
        </div>
    )
};



export const SessionStatusNotif=({data,status})=>{
    const alert = useAlert()
    const {notifiable:sessionData} =  data
    const time = sessionData.session_date.split(" ")[1].slice(0,10)
    const utcDate = sessionData.session_date.split(" ")[0]+"T"+time+"Z"
    return(
        <div className={"sessionBox"}>
            <div className={`badge text-12 mb-4 rounded-[4px] w-fit py-2 px-3 ${status ? "bg-green/10 text-[#2BAC76]":"bg-red-main/10 text-[#F04438]"}`}>
                {status?
                "Your meeting request with details below is accepted."
                    :
                "Your meeting request with details below is declined."
                }
            </div>
            <div className="profile mb-5 flex gap-2 items-center">
                <div className="circle h-8 w-8 aspect-square rounded-full overflow-hidden bg-white">
                    <img className={"w-full h-full object-cover"} src={sessionData.requester.profile_image_url? sessionData.requester.profile_image_url : "/media/defaultAvatar.png"} alt=""/>

                </div>
                <div className="name text-12 ">
                    {sessionData.requester.name}

                </div>
            </div>
            <div className={"mb-4 text-18 font-medium"}>
                {sessionData.title}
            </div>
            <div className={"text-14 font-medium mb-4 text-gray-90"}>
                {sessionData.description}
            </div>
            <div className={" flex flex-col md:flex-row gap-5  md:items-center"}>

                <div className={"w-fit flex items-center  gap-2 rounded-8 border-brown-90 border-1 p-2"}>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.88721 7.29639H14.758" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        <path d="M11.7778 9.89746H11.7842" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        <path d="M8.82275 9.89746H8.8292" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        <path d="M5.86108 9.89746H5.8675" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        <path d="M11.7778 12.4858H11.7842" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        <path d="M8.82275 12.4858H8.8292" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        <path d="M5.86108 12.4858H5.8675" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        <path d="M11.5129 2.36475V4.55641" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        <path d="M6.13281 2.36475V4.55641" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8139 4.41651C14.8139 3.86422 14.3662 3.4165 13.8139 3.4165H3.82593C3.27364 3.4165 2.82593 3.86422 2.82593 4.4165V14.6848C2.82593 15.2371 3.27364 15.6848 3.82593 15.6848H13.8139C14.3662 15.6848 14.8139 15.2371 14.8139 14.6848V4.41651Z" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                    </svg>

                    <div className={"bg-brown-40 py-1 px-2 text-14 rounded-[4px]"}>
                        {format(new Date(utcDate),"EEEE,MMMM dd,yyyy / HH:mm")}
                    </div>

                </div>
                <div className="profile  flex gap-2 items-center">
                    <div className="circle h-8 w-8 aspect-square rounded-full overflow-hidden bg-white">
                        <img className={"w-full h-full object-cover"} src={sessionData.mentor.profile_image_url? sessionData.mentor.profile_image_url : "/media/defaultAvatar.png"} alt=""/>
                    </div>
                    <div className="name text-12 ">
                        {sessionData.mentor.name}
                    </div>
                </div>
            </div>
            {status?
               <div className={"flex flex-col gap-3 w-full justify-between mt-5  md:items-end"}>
                   {sessionData.link&&
                       <div className={"w-full"}>
                           <div className={"mb-2 text-14 font-medium"}>
                               Meet Link :
                           </div>
                           <div className="input-box relative">
                           <input value={sessionData.link} type="text" className={"w-full md:w-[400px] pr-10"}/>
                           <div className={"absolute right-4 top-0 bottom-0 h-fit my-auto  ml-2 cursor-pointer"} onClick={()=>{
                               navigator.clipboard.writeText(sessionData.link)
                               alert.success("Copied successfully!")
                           }}>
                               <svg className={"inline-block"} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                   <path d="M8.36193 5.83286C8.62458 5.55997 8.98653 5.40527 9.36521 5.40527H12.9998C14.689 5.40527 15.7485 6.60122 15.7485 8.2941V12.8619C15.7485 14.5555 14.6941 15.7514 12.9998 15.7514H8.15178C6.45673 15.7514 5.40234 14.5555 5.40234 12.8619V9.47468C5.40234 9.11573 5.54171 8.77125 5.7898 8.51228L8.36193 5.83286Z" stroke="#A6A6A6" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
                                   <path d="M9.28804 5.44629V7.5441C9.28879 8.56785 8.45989 9.399 7.43614 9.40117H5.44922" stroke="#A6A6A6" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
                                   <path d="M5.4044 12.602H5.00016C3.30366 12.602 2.25 11.4038 2.25 9.70732V6.32161C2.25 5.96772 2.39448 5.62112 2.63965 5.36135L5.20958 2.68343C5.47664 2.40907 5.83783 2.25 6.21289 2.25H9.8511C11.5402 2.25 12.6013 3.44886 12.6013 5.14537V5.40514" stroke="#A6A6A6" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
                                   <path d="M6.13572 2.29395V4.39178C6.13645 5.41552 5.30753 6.24663 4.28379 6.24882H2.29688" stroke="#A6A6A6" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
                               </svg>
                           </div>
                       </div>
                   </div>
                   }
                   <Link to={"/calendar-and-ideas"}  className="btn md:ml-auto !font-medium grey-50">
                       View Calendar
                   </Link>
               </div>
            :
                sessionData.note && <div className={"flex w-full justify-between mt-5 items-end"}>
                    <div className={"w-full"}>
                        <div className={"mb-2 text-14 font-medium"}>
                            Mentor note:
                        </div>
                        <div className="w-full input-box p-4 w-full bg-brown-40 rounded-[8px] ">
                            {sessionData.note}
                        </div>
                    </div>

                </div>

            }

        </div>
    )
}
export const AdminSessionStatusNotif=({data,status})=>{
    const alert = useAlert()
    const {notifiable:sessionData} =  data
    const time = sessionData.session_date.split(" ")[1].slice(0,10)
    const utcDate = sessionData.session_date.split(" ")[0]+"T"+time+"Z"
    return(
        <div className={"sessionBox"}>
            <div className={`badge text-12 mb-4 rounded-[4px] w-fit py-2 px-3 ${status ? "bg-green/10 text-[#2BAC76]":"bg-red-main/10 text-[#F04438]"}`}>
                {status?
                "You accepted this meeting request with details below."
                    :
                "Your declined meeting request with details below."
                }
            </div>
            <div className="profile mb-5 flex gap-2 items-center">
                <div className="circle h-8 w-8 aspect-square rounded-full overflow-hidden bg-white">
                    <img className={"w-full h-full object-cover"} src={sessionData.requester.profile_image_url? sessionData.requester.profile_image_url : "/media/defaultAvatar.png"} alt=""/>

                </div>
                <div className="name text-12 ">
                    {sessionData.requester.name}

                </div>
            </div>
            <div className={"mb-4 text-18 font-medium"}>
                {sessionData.title}
            </div>
            <div className={"text-14 font-medium mb-4 text-gray-90"}>
                {sessionData.description}
            </div>
            <div className={" flex flex-col md:flex-row  gap-5  md:items-center"}>

                <div className={"w-fit flex items-center  gap-2 rounded-8 border-brown-90 border-1 p-2"}>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.88721 7.29639H14.758" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        <path d="M11.7778 9.89746H11.7842" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        <path d="M8.82275 9.89746H8.8292" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        <path d="M5.86108 9.89746H5.8675" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        <path d="M11.7778 12.4858H11.7842" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        <path d="M8.82275 12.4858H8.8292" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        <path d="M5.86108 12.4858H5.8675" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        <path d="M11.5129 2.36475V4.55641" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        <path d="M6.13281 2.36475V4.55641" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8139 4.41651C14.8139 3.86422 14.3662 3.4165 13.8139 3.4165H3.82593C3.27364 3.4165 2.82593 3.86422 2.82593 4.4165V14.6848C2.82593 15.2371 3.27364 15.6848 3.82593 15.6848H13.8139C14.3662 15.6848 14.8139 15.2371 14.8139 14.6848V4.41651Z" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                    </svg>

                    <div className={"bg-brown-40 py-1 px-2 text-12  md:text-14 rounded-[4px]"}>
                        {format(new Date(utcDate),"EEEE,MMMM dd,yyyy / HH:mm")}
                    </div>

                </div>
                <div className="profile flex gap-2 items-center">
                    <div className="circle h-8 w-8 aspect-square rounded-full overflow-hidden bg-white">
                        <img className={"w-full h-full object-cover"} src={sessionData.mentor.profile_image_url? sessionData.mentor.profile_image_url : "/media/defaultAvatar.png"} alt=""/>
                    </div>
                    <div className="name text-12 ">
                        {sessionData.mentor.name}
                    </div>
                </div>
            </div>
            {status?
               <div className={"flex w-full flex-col md:flex-row gap-5 justify-between mt-5 md:items-end"}>
                   {sessionData.link&&
                       <div className={"w-full"}>
                           <div className={"mb-2 text-14 font-medium"}>
                               Meet Link :
                           </div>
                           <div className="input-box gap-4 relative w-full md:w-fit">
                              <input value={sessionData.link} type="text" className={"w-full md:w-[400px] pr-10"}/>
                               <div className={"absolute right-4 top-0 bottom-0 h-fit my-auto  ml-2 cursor-pointer"} onClick={()=>{
                                   navigator.clipboard.writeText(sessionData.link)
                                   alert.success("Copied successfully!")
                               }}>
                               <svg className={"inline-block"} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                   <path d="M8.36193 5.83286C8.62458 5.55997 8.98653 5.40527 9.36521 5.40527H12.9998C14.689 5.40527 15.7485 6.60122 15.7485 8.2941V12.8619C15.7485 14.5555 14.6941 15.7514 12.9998 15.7514H8.15178C6.45673 15.7514 5.40234 14.5555 5.40234 12.8619V9.47468C5.40234 9.11573 5.54171 8.77125 5.7898 8.51228L8.36193 5.83286Z" stroke="#A6A6A6" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
                                   <path d="M9.28804 5.44629V7.5441C9.28879 8.56785 8.45989 9.399 7.43614 9.40117H5.44922" stroke="#A6A6A6" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
                                   <path d="M5.4044 12.602H5.00016C3.30366 12.602 2.25 11.4038 2.25 9.70732V6.32161C2.25 5.96772 2.39448 5.62112 2.63965 5.36135L5.20958 2.68343C5.47664 2.40907 5.83783 2.25 6.21289 2.25H9.8511C11.5402 2.25 12.6013 3.44886 12.6013 5.14537V5.40514" stroke="#A6A6A6" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
                                   <path d="M6.13572 2.29395V4.39178C6.13645 5.41552 5.30753 6.24663 4.28379 6.24882H2.29688" stroke="#A6A6A6" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
                               </svg>
                           </div>
                       </div>
                        </div>
                   }
                   <Link to={"/calendar-and-ideas"}  className="btn md:ml-auto !font-medium grey-50">
                       View Calendar
                   </Link>
               </div>
            :
                sessionData.note && <div className={"flex w-full justify-between mt-5 items-end"}>
                    <div className={"w-full"}>
                        <div className={"mb-2 text-14 font-medium"}>
                            Mentor note:
                        </div>
                        <div className="w-full input-box p-4 w-full bg-brown-40 rounded-[8px] ">
                            {sessionData.note}
                        </div>
                    </div>

                </div>

            }

        </div>
    )
}
