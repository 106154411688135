import React from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const CustomSkeleton = ({width,baseColor="#453F3F",height,borderRadius=8,highlightColor="#423A3A"}) => {
    return (
        <Skeleton width={width} height={height} borderRadius={borderRadius} baseColor={baseColor} highlightColor={highlightColor}/>
    );
};

export default CustomSkeleton;
