import React, {useState} from 'react';
import s from "./styles.module.scss"
import classNames from "classnames";
import bgImg from "./Background pattern.png"
import PopupContainer from "../../../Sections/PopupContainer";
import BookSessionBox from "./BookSessionBox";
import {format} from "date-fns";
import BookmarkIcon from "../../../Components/BookmarkIcon";
import AdminCanView from "../../../Components/ViewLimit/AdminCanView";
import MoreBtn from "../../../Sections/MoreBtn";
import {useMutation, useQueryClient} from "react-query";
import {useGetProfile} from "../../../Hooks/useGetProfile";
import SuperAdminCanView from "../../../Components/ViewLimit/SuperAdminCanView";
import axiosClient from "../../../Api/apiClient";
import {useAlert} from "react-alert";
const MentorBox = ({name,isOwner,role,img,id,about}) => {
    const alert = useAlert()
    const [isPopOpen,setIsPopOpen]=useState(false)
    const [isSubmitPopOpen,setIsSubmitPopOpen]=useState(true)
    const [requestSentData,setRequestSentData]=useState()
    const queryClient = useQueryClient()
    const deleteMentorMutation = useMutation({
        mutationFn:()=>axiosClient.delete(`/mentors/${id}`),
        onSuccess:()=>{
            alert.success("User removed from mentors successfully!")
            queryClient.invalidateQueries("/mentors")
        },
        onError:(error)=>{
            alert.error(error.response.data.resulting_data.error.error)
        }
    })
    const deleteMentor =()=>{
        deleteMentorMutation.mutate()
    }
    const me = useGetProfile()
    return (
        <div className={classNames(s.container,"p-6 relative flex flex-col overflow-hidden")}>
            <img src={bgImg} className={"absolute right-0 z-[0]  top-0 w-1/2"} alt=""/>
            <div className={"items-center absolute right-6 z-[2] top-6 flex gap-2"}>
                <SuperAdminCanView>
                    <MoreBtn options={[
                        {name: "Delete", fn: deleteMentor, mutation: deleteMentorMutation,isHidden:!(me.isOwner && !isOwner)},
                    ]}/>
                </SuperAdminCanView>
            </div>
            <div className="profile relative border-b-1 border-[#524848] pb-4 gap-6 flex">
                <div className="avatar-wrapper">
                    <img src={img?img:"/media/defaultAvatar.png"} className={"w-full h-full object-center object-cover"} alt="profile_img"/>
                </div>
                <div className="content flex flex-col justify-center">
                    <div className="name">
                        {name}
                    </div>
                    {role &&
                        <div className="role my-2">
                            {role}
                        </div>
                    }
                    {/*<div className="text-gray-50 text-12">*/}
                    {/*    {desc}*/}
                    {/*</div>*/}
                </div>
            </div>
            <div className="text text-gray-50 relative py-4">
                <div className="text-title font-medium mb-2">
                    About
                </div>
                <div className="text-content text-14 ">
                    {about || 'there is no information for this mentor'}
                </div>
            </div>
            <button onClick={()=>{setIsPopOpen(true)}} className={"btn  w-fit mt-auto normal"}>
                Book sessions
            </button>


                {isPopOpen &&
                    <PopupContainer>
                        <div className={"w-full h-full md:items-center flex max-h-[90vh] overflow-hidden"}>
                            <BookSessionBox setRequestSentData={setRequestSentData} setIsOpenPop={setIsPopOpen} id={id}/>
                        </div>
                    </PopupContainer>
                }
                {requestSentData &&
                    <PopupContainer>
                        <div className={"pop-up font-medium  items-center flex justify-center flex-col p-6 py-14 md:p-16"}>
                            <div onClick={()=>{setRequestSentData(null)}} className="close  right-4 top-4 ">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 6L18 18" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                                    <path d="M18 6L6 18" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                                </svg>
                            </div>


                            <div className="text-32 max-w-[440px] text-center font-bold  mb-3">
                                Thank you, your request has been received
                            </div>
                            <div className={"w-20 h-20 rounded-full overflow-hidden bg-white mb-6"}>
                                <img src={requestSentData.mentor.profile_image_url || "/media/defaultAvatar.png"} className={"w-full object-fit h-full"} alt=""/>
                            </div>
                            <div className={"font-medium text-18 mb-2"}>
                                {requestSentData.mentor.name}
                            </div>
                            <div className="role mb-4">
                                {requestSentData.mentor.occupation}
                            </div>
                            <div className={"flex text-14 role !bg-brown-40 mb-4 gap-2 items-center"}>
                                {/*Tuesday - 2023 10 October*/}

                                {format(new Date(requestSentData.session_date),"EEEE - yyyy dd LLLL")}
                                <span>
                                    {format(new Date(requestSentData.session_date),"HH:MM")}
                                </span>
                            </div>

                        </div>
                    </PopupContainer>
                }

        </div>
    );
};

export default MentorBox;
