import React, {useRef, useState,Suspense} from 'react';
import EmojiPicker from "emoji-picker-react";
import useOnClickOutside from "../../../../Hooks/useOnClickOutside";
import {MainThreadsName} from "../../MainThreadsName";
import {useMutation, useQuery, useQueryClient} from "react-query";
import axiosClient from "../../../../Api/apiClient";
import CustomSpinner from "../../../../Utility/CustomSpinner";
import {FullPageLoading} from "../../../../Components/LoadingPage";


const ThreadPopup = ({setIsEditOpen,id}) => {
    const titleInput = useRef()



    const Tab =({title,isActive,onClick})=>{
        return(
            <div onClick={onClick} className={`text-14 lg:text-16 whitespace-nowrap px-2 cursor-pointer py-1.5 bg-brown-100 border-brown-90 border-2 rounded-8 ${isActive && "text-gray-100 bg-gray-50"}`}>
                {title}
            </div>
        )
    }












    const [isEmojyPickerOpened,setIsEmojyPickerOpened]=useState(false)




    const LoadedContent =()=>{
        const {data} = useQuery(`/threads/${id}`)
        const loadedData = data.resulting_data

        const [desc,setDesc]=useState(loadedData.description)
        const [title,setTitle]=useState(loadedData.title)
        const [tab,setTab]=useState(loadedData.category);
        const [emojiValue,setEmojiValue]=useState(loadedData.emoji)

        const emojiPicker = useRef()
        const emojyAdd = (e)=>{
            setEmojiValue(e.emoji)
            setIsEmojyPickerOpened(false)
        }
        useOnClickOutside(emojiPicker,()=>{
            setIsEmojyPickerOpened(false)
        })



        const [titleLetters,setTitleLetters]=useState(0);
        const checkTitleLimit =(e)=>{
            setTitleLetters(e.target.value.length)
            setTitle(e.target.value)

        }

        const [textLetters,setTextLetters]=useState(0);

        const checkTextLimit =(e)=>{
            setDesc(e.target.value)
            setTextLetters(e.target.value.length)
        }

        const dataToPost ={
            title:title,
            description:desc,
            category:tab,
            emoji:emojiValue
        }

        const queryClient = useQueryClient();

        const mutation = useMutation({
            mutationFn: (threadData)=>axiosClient.put(`/threads/${id}`, threadData),
            onSuccess: async (res) => {
                queryClient.invalidateQueries({ queryKey: ['/threads'] });
                setIsEditOpen(false)
            },
        })

        const handleSubmit =()=>{
            mutation.mutate(dataToPost)
        }
        return(
            <>
                <h3 className={"text-24 mb-8 text-gray-50"}>
                    Edit thread
                </h3>
                <div className={"text-gray-90 text-18 font-medium mb-6"}>
                    Choose Main Threads
                </div>
                <div className="tabs flex-wrap flex gap-2 mb-8">
                    {MainThreadsName.map((item)=>
                        <Tab onClick={()=>setTab(item)} isActive={tab===item} title={item}/>
                    )}

                </div>
                <div className="input-box mb-8 ">
                    <div className={"hidden lg:block text-18 text-gray-90 mb-1"}>Title</div>

                    <div className={"flex flex-col lg:w-fit lg:flex-row gap-4 items-start lg:items-end"}>
                        <div className={"block lg:hidden text-18 text-gray-90 mb-1"}>Title</div>

                        <div className={"relative flex  w-full lg:w-fit gap-4  items-center"}>
                            <input value={title} onInput={checkTitleLimit} ref={titleInput} maxLength="30" placeholder={"Title "}
                                   className={"w-full lg:w-[600px]"} type="text"/>

                            <div className={"absolute right-4 top-0 bottom-0 flex items-center text-12 text-gray-90 m-auto z-20"}>
                                {titleLetters}/30
                            </div>
                        </div>
                        <div  className={" block relative  -order-10 lg:order-1  h-[60px]"}>
                            <img className={"h-full "} src="/media/Frame 45024.png" alt=""/>
                            <input
                                   value={emojiValue}
                                  onClick={()=> {setIsEmojyPickerOpened(true)}}
                                  className={"p-2 text-[22px] text-center bg-brown-90 absolute inset-1.5 grid place-item-center rounded-8"}>
                            </input>
                            {isEmojyPickerOpened &&
                                <div ref={emojiPicker}  className={"absolute  left-0 lg:right-0 lg:-bottom-40 z-30"}>
                                    <EmojiPicker onEmojiClick={emojyAdd} theme={"dark"}/>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="input-box mb-8">
                    <div className={"text-18 text-gray-90 mb-3"}>Description</div>
                    <div className={"relative flex w-full lg:w-fit"}>
                        <textarea value={desc} maxLength={"1200"} onInput={checkTextLimit} className={"resize-none max-w-full w-full lg:w-[800px] h-[280px] lg:h-[190px]"} placeholder={"Text"} type="text"/>
                        <div className={"absolute right-4 top-2 flex items-center text-12 text-gray-90  z-20"}>
                            {textLetters}/1200
                        </div>
                    </div>
                </div>
                <div className={"flex flex-col lg:flex-row justify-end gap-6"}>
                    <div onClick={()=>setIsEditOpen(false)} className={"cursor-pointer border-2 border-brown-110 rounded-8 w-full lg:w-[160px] grid place-items-center h-[48px]"}>
                        Cancel
                    </div>
                    <div onClick={handleSubmit} className={"cursor-pointer border-2 bg-brown-110 border-brown-110 rounded-8 w-full lg:w-[300px] flex justify-center items-center h-[48px]"}>
                        {mutation.isLoading?
                            <CustomSpinner size={11}/>
                            :
                            mutation.isError?
                                <div>
                                    error occurred
                                </div>
                                :
                                "Edit"
                        }
                    </div>
                </div>
            </>
        )
    }
    return (
        <div className={"bg-black/60 z-30  fixed inset-0 flex"}>
            <Suspense fallback={<FullPageLoading/>}>

            <div className="w-full h-full min-h-[500px] min-w-[500px] lg:h-fit lg:w-fit bg-brown-80 relative lg:rounded-12 px-6 py-8 lg:py-12 lg:px-8 m-auto">

                <div onClick={()=>setIsEditOpen(false)} className="close cursor-pointer absolute right-4 top-4">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 6L18 18" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                        <path d="M18 6L6 18" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                    </svg>
                </div>
                    <LoadedContent/>
            </div>
            </Suspense>

        </div>
    );
};

export default ThreadPopup;
