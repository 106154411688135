import {useMutation, useQuery} from "react-query";
import axiosClient from "../Api/apiClient";
import {useLocation, useNavigate} from "react-router-dom";
import {useAlert} from "react-alert";

export const useEditGetAndPost =(query,customNavigate=query)=>{
    const location = useLocation();
    const id = location.pathname.split("/")[3]
    const navigate = useNavigate();
    const alert = useAlert()
    const postMutation = useMutation({
        mutationFn: (d)=>axiosClient.put(`${query}/${id}`, d),
        onSuccess:()=>{
            alert.success("Edited successfully!")
            navigate(customNavigate)
            console.log("fuck uawd ")
        },
        onError:(err)=>{
            err.response.data.error.error? alert.error(err.response.data.error.error) : alert.error("an error happened!")
        },

    })
    const {data} = useQuery(`${query}/${id}`)
    const fetchedData = data.resulting_data

    const handleSubmitEdit =(dataToPost)=>{
        postMutation.mutate(dataToPost)
    }

    return {
        fetchedData,handleSubmitEdit,postMutation
    }

}
