import React from 'react';
import {formatInTimeZone, fromZonedTime} from 'date-fns-tz'
import { toZonedTime } from 'date-fns-tz'
import {format, parseISO} from "date-fns";

const EventBox = ({id,type,title,desc,user_id,date,isMeet = false,selectedDate}) => {


    const time = date.split(" ")[1].slice(0,10)
    const hour =time.slice(0,2)
    const utcDate = date.split(" ")[0]+"T"+time+"Z"
    const utcDateFormated = new Date(utcDate)

    const localDate = parseISO(`${format(utcDate,"yyyy-MM-dd HH:mm")}`)

    const localSessionDate =format(localDate,"yyyy-MM-dd")
    return (
        <div className={`p-6 bg-brown-50 rounded-8 ${isMeet && localSessionDate != selectedDate && "hidden"}`}>
            <div className={"flex justify-between items-center mb-4"}>
                <div className="p-1 flex gap-2 items-center text-12 text-gray-50  bg-brown-90 rounded-[4px] border-1 border-brown-110">
                    {isMeet ?
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_8227_1507)">
                                <path d="M12 4.5H2.25C1.83579 4.5 1.5 4.83579 1.5 5.25V13.5C1.5 13.9142 1.83579 14.25 2.25 14.25H12C12.4142 14.25 12.75 13.9142 12.75 13.5V5.25C12.75 4.83579 12.4142 4.5 12 4.5Z" stroke="#D9D9D9" stroke-width="1.08" stroke-linecap="square"/>
                                <path d="M13.125 7.5L16.5 5.25V14.25L13.125 11.625" stroke="#D9D9D9" stroke-width="1.08" stroke-linecap="square"/>
                                <path d="M8.25 7.5H10.5574" stroke="#D9D9D9" stroke-width="1.08" stroke-linecap="square"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_8227_1507">
                                    <rect width="18" height="18" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    :
                    ""
                    }

                    {isMeet? "Meeting" : type}
                </div>
                {/*<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                {/*    <path d="M12.7041 2.93365C13.0888 2.54835 13.7131 2.54813 14.0982 2.93316L21.0673 9.90226C21.4523 10.2873 21.4522 10.9117 21.067 11.2966C20.682 11.6814 20.058 11.6812 19.6732 11.2964L15.4923 15.4772L14.8854 18.5126C14.8268 18.8058 14.6826 19.0751 14.4711 19.2864C13.88 19.8771 12.922 19.8769 12.3311 19.2859L9.22001 16.174L4.03815 21.3559L2.64453 19.9623L7.82638 14.7804L4.7145 11.6693C4.12353 11.0784 4.12331 10.1204 4.714 9.52934C4.92536 9.31783 5.19463 9.17367 5.48784 9.11507L8.52418 8.50814L12.705 4.32727C12.3204 3.94263 12.3197 3.31857 12.7041 2.93365ZM14.0987 5.7209L9.49498 10.3236L6.71365 10.8805L13.12 17.2868L13.6759 14.5054L18.2795 9.90275L14.0987 5.7209Z" fill="#D9D9D9"/>*/}
                {/*</svg>*/}

            </div>
            {isMeet &&
                <div className={"font-bold text-32"}>
                      {format(new Date (localDate),"HH:mm ")}
                    <span className={"text-16 ml-1"}>
                      {format(new Date (localDate),"a ")}
                    </span>
                </div>
            }
            <div className={"mt-3 text-18 font-medium"}>
                {title}
            </div>
            <div className={"mt-3 text-14 text-gray-90 font-medium line-clamp-3 origin-top overflow-hidden"}>
                {desc}
            </div>
        </div>
    );
};

export default EventBox;
