import {useMutation} from "react-query";
import axiosClient from "../Api/apiClient";
import {useRef, useState} from "react";
import {useAlert} from "react-alert";

function useUploadAttachment (){
    const alert = useAlert()
    const id =useRef(null)
    const mutation = useMutation({
        mutationFn: handleMutate,
        onError:(e)=>{
            id.current = null
            alert.error(e.response.data.error.error)
        }
    })

   async function handleMutate({attach_type, file}){
        const formData = new FormData();
        formData.append("attachable_type", attach_type);
        formData.append("attachment", file);

        const result = await axiosClient.post(`/attachments`, formData,{
            headers:{
                "Content-Type":"multipart/form-data"
            }
        })
        id.current =result.data.resulting_data.id
        return result
    }


    return {id:id.current,mutation};
}
export default useUploadAttachment;
