import React, {useRef, useState,Suspense} from 'react';
import classNames from "classnames";
import s from "./styles.module.scss"
import MobilePinnedMainHeader from "../../Sections/MobilePinnedMainHeader";
import {DayPicker} from "react-day-picker";
import {format, parse, parseISO, toDate} from "date-fns";

import {useDropzone} from 'react-dropzone'
import SessionListSidebar from "./SessionListSidebar";
import axiosClient from "../../Api/apiClient";
import {uploadSingleFile} from "../../Functions/UploadSingleFilePromise";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {useNavigate} from "react-router-dom";
import CustomSpinner from "../../Utility/CustomSpinner";
import useOnClickOutside from "../../Hooks/useOnClickOutside";
import PageTitle from "../../Components/PageTitle/PageTitle";
import {useAlert} from "react-alert";



const Session = () => {
    PageTitle("Sessions")

    const [selectedDate, setSelectedDate] = useState();
    const [title,setTitle]=useState()
    const [desc,setDesc]=useState()
    const [btnLoading,setBtnLoading]=useState(false)
    const [time,setTime]=useState(null)

    let footer = <p className={"mt-3 text-center"}>Please select a date</p>;
    if (selectedDate) {
        footer = <p className={"mt-3 text-center"}>You picked {format(selectedDate, 'PP')}.</p>;
    }

    const [step,setStep]=useState(0)
    const [addedFiles,setAddedFiles] = useState([])

    const { getRootProps, getInputProps} = useDropzone({
        // multiple:true,
        onDrop: files => {
            setAddedFiles(prev=>[...prev,...files])
        }
    });






    const files = addedFiles.map((file,index) => {
        const removeFile=()=>{
            setAddedFiles( prevState=>
                [...prevState.slice(0,index), ...prevState.slice(index+1)]
            )
        }
         return   <li key={file.path} className={"py-2 rounded-8 flex justify-between items-center font-medium text-gray-50 text-14  px-4 bg-brown-50 border-1 border-brown-90"}>
            <div className={""}>
                <div>
                    {file.path}
                </div>
                <div className={"text-12  text-gray-90"}>
                    {file.size/1000} KB
                </div>
            </div>
            <div className={"cursor-pointer"} onClick={removeFile}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.19531 6.32422H19.3261L17.9654 20.5313H5.55602L4.19531 6.32422Z" stroke="#A6A6A6" stroke-width="1.44" stroke-linecap="square"/>
                    <path d="M16.0571 5.89088L15.0312 2.77344H8.48887L7.46289 5.89088" stroke="#A6A6A6" stroke-width="1.44" stroke-linecap="square"/>
                    <path d="M11.7617 11.043V15.817" stroke="#A6A6A6" stroke-width="1.44" stroke-linecap="square"/>
                </svg>
            </div>
        </li>

    });

    const [isSideOpen,setIsSideOpen]=useState(false)

    const navigate = useNavigate();
    const sessionMutation = useMutation({
        mutationFn:(d)=>axiosClient.post("/sessions",d),
        onSuccess:()=>{
            setTime(null)
            setSelectedDate(null)
            setTitle(null)
            setDesc(null)
            setAddedFiles([])
            setStep(2)
        }
    })
    const alert = useAlert()
    const handleSubmit = async ()=>{
        if (!title || !desc || !time || !selectedDate){
            alert.error("Please fill out all the fields")
            return
        }
        if(!sessionMutation.isLoading) {

            const date = parseISO(`${format(selectedDate, "yyyy-MM-dd")}T${time}:00`)
            const isoDate = date.toISOString();
            setBtnLoading(true)
            const arr = [];
            for (const file of addedFiles) {
                await uploadSingleFile(file, "sessions").then((res) => arr.push(res))
            }
            sessionMutation.mutateAsync({
                "title": title,
                "description": desc,
                "session_date": isoDate,
                "duration": 30,
                "mentor_id": 1,
                "file_ids": arr
            }).then((res) => {
                console.log(res)
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setBtnLoading(false)
            })
        }
    }
    const dropdownTime =useRef();
    const [isDropdownTimeOpen,setIsDropdownTimeOpen]=useState(false)
    useOnClickOutside(dropdownTime,()=>setIsDropdownTimeOpen(false))

    const arr = ["00:00","01:00","02:00","03:00","04:00","05:00","06:00","07:00","08:00","09:00","10:00","11:00","12:00","13:00","14:00","16:00","17:00","18:00","19:00","20:00","21:00","22:00","23:00"]

    const handleSetTime =(i)=>{
        setTime(i)
    }

    const LeftSideOwnerData=()=>{
        const {data}=useQuery("/user/owner")
        const ownerData = data.resulting_data
        return(
            <div className="left">
                <div className="circle w-20 h-20 rounded-full overflow-hidden mb-8 bg-white">
                    <img src={ownerData.profile_image_url || "/media/defaultAvatar.png"} className={"w-full h-full object-cover"} alt=""/>
                </div>
                <div className={"text-14 text-gray-50"}>
                    {ownerData.name}
                </div>
                <div className="text-24 font-semibold">
                    Meet with us
                </div>
                <div className={"py-1 px-3 flex items-center gap-3 rounded-20 bg-brown-90 w-fit font-semibold mt-6"}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.25 11.998C21.25 17.107 17.109 21.248 12 21.248C6.891 21.248 2.75 17.107 2.75 11.998C2.75 6.88905 6.891 2.74805 12 2.74805C17.109 2.74805 21.25 6.88905 21.25 11.998Z" stroke="#A6A6A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M15.4302 14.9417L11.6602 12.6927V7.8457" stroke="#A6A6A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    30 Min
                </div>

            </div>
        )
    }
    return (
        <div className={classNames(s.container,"flex overflow-hidden relative  flex-col h-full")}>
            <MobilePinnedMainHeader title={"Online sessions"}>
                {/*<button onClick={() => setIsSideOpen(true)} className="!px-2 lg:!px-6 btn grey-50">*/}
                {/*    Sessions list*/}
                {/*</button>*/}
            </MobilePinnedMainHeader>
            {step ===0 ?
                <div  className={"flex-grow flex flex-col overflow-auto md:p-8 py-6 pad-layout  font-medium"}>
                    <div className="title hidden md:block text-24 mb-8 font-semibold text-gray-50">
                        Booking session
                    </div>
                    <div className="box flex-grow  md:!border-none flex flex-col md:flex-row gap-10 justify-around md:items-center p-0 md:p-12 mb-8">
                        <Suspense fallback={
                            <div className="left">
                                <div className="circle w-20 flex justify-center items-center bg-brown-90 h-20 rounded-full overflow-hidden mb-8 ">
                                    <CustomSpinner/>
                                </div>
                                <div className={"text-14 text-gray-50"}>
                                   name loading...
                                </div>
                                <div className="text-24 font-semibold">
                                    Meet with us
                                </div>
                                <div className={"py-1 px-3 flex items-center gap-3 rounded-20 bg-brown-90 w-fit font-semibold mt-6"}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.25 11.998C21.25 17.107 17.109 21.248 12 21.248C6.891 21.248 2.75 17.107 2.75 11.998C2.75 6.88905 6.891 2.74805 12 2.74805C17.109 2.74805 21.25 6.88905 21.25 11.998Z" stroke="#A6A6A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M15.4302 14.9417L11.6602 12.6927V7.8457" stroke="#A6A6A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    1 hour
                                </div>

                            </div>
                        }>
                          <LeftSideOwnerData/>
                        </Suspense>
                        <div className={"center"}>
                            <div className={"md:hidden text-24 font-semibold mb-6"}>
                                Select a Date & Time
                            </div>
                            <div className=" bg-brown-50 flex items-center justify-center w-full border-1 border-brown-110 rounded-12">
                                <DayPicker
                                    showOutsideDays
                                    fixedWeeks
                                    mode="single"
                                    className={""}
                                    selected={selectedDate}
                                    onSelect={setSelectedDate}
                                    footer={footer}
                                />
                            </div>
                        </div>

                        <div className=" py-6 pb-0">
                            <div className="title text-18 mb-4" >
                                {selectedDate && format(selectedDate,"EEEE,LLLL d,yyyy")}
                            </div>

                            <div className="bg-brown-40 mb-4 rounded-12 text-12 font-normal w-fit px-2.5 py-2 flex gap-2 items-center">

                                <div className="circle shrink-0 w-3 h-3 rounded-full bg-green">

                                </div>
                                +GMT
                                <span className={"mx-1"}>
                                    {
                                        new Date().toLocaleTimeString() // displays date and time
                                    }
                                </span>
                                 Local time
                            </div>
                            <div ref={dropdownTime} className={"relative"}>
                                <div onClick={()=>setIsDropdownTimeOpen(!isDropdownTimeOpen)} className={"box p-3 h-12 items-center flex rounded-[4px] border-1 border-brown-110 bg-brown-50 text-gray-90 text-14 font-medium relative"}>
                                    {time ?
                                        format(parseISO(`2012-04-05T${time}:00`),"hh:mm bbbb")
                                        :"Select time"
                                    }
                                    <div className={classNames("absolute right-4 shrink-0 top-0 bottom-0 flex items-center",!isDropdownTimeOpen && "rotate-180")}>
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4 11.25L9.25 6L14.5 11.25" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                                        </svg>
                                    </div>
                                </div>
                                {isDropdownTimeOpen &&
                                    <div  className={"absolute right-0 z-20  top-[110%] h-[250px] overflow-auto text-12 w-[180px] border-brown-90 border-1 rounded-8 p-3 bg-brown-40"}>
                                    {arr.map((i) => {
                                        return (
                                            <div onClick={() => handleSetTime(i)}
                                                 className={classNames(" rounded-8 p-1 cursor-pointer w-full", time == i && "bg-brown-90")}>
                                                {i}
                                            </div>
                                        )
                                    })
                                    }
                                </div>}
                            </div>
                            {/*<div>*/}
                            {/*    {timeValue}*/}
                            {/*    awd*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className="btn-wrapper">
                        <button disabled={!(selectedDate && time )} onClick={()=> {
                            setStep(1)
                        }} className="btn big ml-auto grey-50">
                            Next step
                        </button>
                    </div>
                </div>
                :
                step === 1 ?
                    <div className={"flex-grow flex flex-col overflow-auto md:p-8 py-6 pad-layout  font-medium"}>
                        <div className="title hidden md:block text-24 mb-8 font-semibold text-gray-50">
                            New Sessions
                        </div>
                        <div className="box flex-grow items-center md:overflow-hidden md:!border-none flex flex-col md:flex-row gap-10 justify-between  p-0 md:p-12 mb-8">
                            <div className="left flex-grow-[2] flex flex-col">
                                <div className={"font-medium text-18 mb-2"}>
                                    information
                                </div>
                                <div className="text-gray-90 text-14 font-medium mb-8">
                                    New Sessions detail
                                </div>
                                <div className="input-box mb-10 flex flex-col gap-3">
                                    <label className="label">Title (required)</label>
                                    <input value={title} onChange={(e)=>setTitle(e.target.value)} placeholder={"Type here"} type="text"/>
                                </div>
                                <div className="input-box flex flex-col gap-3">
                                    <label className="label">Description</label>
                                    <textarea value={desc} onChange={(e)=>setDesc(e.target.value)} className={"resize-none h-[230px]"} placeholder={"Enter a description..."} type="text"/>
                                </div>
                            </div>
                            <div className="right md:h-fit md:overflow-auto md:max-h-[100%] p-6 pb-0 flex flex-col flex-grow">
                                <div className={"text-18 font-medium mb-6 flex items-center gap-2"}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.7598 3.58203V15.8487" stroke="white" stroke-width="1.44" stroke-linecap="square"/>
                                        <path d="M8.61719 6.02127L11.7612 2.86328L14.9061 6.02127" stroke="white" stroke-width="1.44" stroke-linecap="square"/>
                                        <path d="M16.1989 11.0352H20.6389V21.1382H2.87891V11.0352H7.31891" stroke="white" stroke-width="1.44" stroke-linecap="square"/>
                                    </svg>

                                    Upload Files
                                </div>

                                <section className=" flex-col container">
                                    <div {...getRootProps({className: 'upload-area mb-6 dropzone'})}>
                                        <input {...getInputProps()} />
                                        <div className={"text-gray-90 text-center w-full text-14 flex items-center flex-col p-10 gap-2 font-medium "}>
                                            <svg width="43" height="40" viewBox="0 0 43 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.5 28L21.5 20M21.5 20L29.5 28M21.5 20V38M37.5 29.4857C39.943 27.4681 41.5 24.4159 41.5 21C41.5 14.9249 36.5751 10 30.5 10C30.063 10 29.6541 9.77199 29.4322 9.39548C26.8241 4.96967 22.0089 2 16.5 2C8.21573 2 1.5 8.71573 1.5 17C1.5 21.1322 3.17089 24.8742 5.8739 27.5871" stroke="url(#paint0_linear_7712_4811)" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                                <defs>
                                                    <linearGradient id="paint0_linear_7712_4811" x1="21.5" y1="-18.3684" x2="21.5" y2="20" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="white"/>
                                                        <stop offset="1" stop-color="#D9D9D9"/>
                                                    </linearGradient>
                                                </defs>
                                            </svg>

                                            <div className={"mt-1"}>
                                                <span>Click to upload</span>or drag and drop
                                            </div>

                                        </div>

                                    </div>
                                    {files.length ?
                                        <aside>
                                            <h4 className={"mb-4"}>Files</h4>
                                            <ul className={"flex flex-col gap-4 mb-6"}>{files}</ul>

                                        </aside>
                                        :""
                                    }
                                </section>
                            </div>

                        </div>
                        <div className="btn-wrapper flex-col ml-auto md:w-fit md:flex-row w-full flex gap-3 ml-auto">
                            <button className={"btn dark "} onClick={()=>setStep(0)}>
                                Back
                            </button>
                            <button  onClick={handleSubmit} className="btn w-40 big dark grey-50">
                                {btnLoading ?
                                    <div>
                                        <CustomSpinner/>
                                    </div>
                                    :
                                    "Request session"
                                }
                            </button>

                        </div>
                    </div>
                    :
                    <div className={"flex-grow text-center px-4 flex flex-col items-center justify-center"}>
                        <svg width="94" height="93" viewBox="0 0 94 93" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M32.5703 43.8724C40.3415 48.7854 46.1481 57.5646 46.1481 57.5646H46.2645C46.2645 57.5646 58.6004 35.7362 81.5174 22.3115" stroke="#A6A6A6" stroke-width="5.58" stroke-linecap="square"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M45.033 81.0354C64.037 81.0354 79.443 65.6294 79.443 46.6254C79.443 27.6212 64.037 12.2153 45.033 12.2153C26.0289 12.2153 10.623 27.6212 10.623 46.6254C10.623 65.6294 26.0289 81.0354 45.033 81.0354Z" stroke="#A6A6A6" stroke-width="5.58" stroke-linecap="round"/>
                        </svg>
                        <div className={"font-bold my-2 text-24 md:text-32"}>
                            Your request has been sent
                        </div>
                        <div className={"text-gray-50 text-14 md:text-16 font-medium  mb-6 md:mb-12 max-w-[390px] text-center "}>
                            You will be notified of the result in the Activity page.
                        </div>
                        {/*<div>*/}
                        {/*    <div onClick={()=>setStep(0)} className="btn big grey-50">*/}
                        {/*        Request list*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                    </div>
            }

            <SessionListSidebar isOpened={isSideOpen} setIsOpened={setIsSideOpen}/>

        </div>
    );
};

export default Session;


