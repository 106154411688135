import React from 'react';
import {useNavigate} from "react-router-dom";
import classNames from "classnames";
const MobilePinnedMainHeader = ({title="Activities",hasBackBtn,children}) => {
    const navigate = useNavigate()
    const handleNavigate =()=>{
        if (hasBackBtn)
            navigate(-1)
    }
    return (
        <div className={""}>
            <div className={classNames("g-page-title px-6 h-24 flex items-center gap-3",hasBackBtn && "cursor-pointer")}>
               <div onClick={handleNavigate} className={"flex items-center gap-1"}>
                   {hasBackBtn &&
                       <span >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.5 19L8.5 12L15.5 5" stroke="#D9D9D9" stroke-width="1.5" stroke-linecap="square"/>
                        </svg>
                    </span>
                   }
                   {title}
               </div>

                <div className={"ml-auto"}>
                    {children}
                </div>
            </div>
            {/*<div className={s.container}>*/}
            {/*    <PinnedBox noSvg name={"All"} isActive={true}/>*/}
            {/*    <PinnedBox noSvg name={"Mentions"} number={12}/>*/}
            {/*    <PinnedBox noSvg name={"My threads"}/>*/}
            {/*    <PinnedBox noSvg name={"Sessions"}/>*/}
            {/*</div>*/}
        </div>
    );
};

export default MobilePinnedMainHeader;
