import axios from "axios";
import {useAtom} from "jotai";
import {tokenAtom} from "../App";


let tempToken = localStorage.getItem("token")


let baseUrl = process.env.REACT_APP_BASE_URL
 const axiosClient = axios.create({
    baseURL: baseUrl,
});
export default axiosClient;

axiosClient.interceptors.request.use(
    (config)=>{
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }else {
            if (config.url != "/auth/login" )
                if (config.url != "/pre-register"){
                    window.location.href = `/login`
                }
        }
        return config;

    }
)
axiosClient.interceptors.response.use(

    function (response) {
        return response;

    },
    function (error) {


        let res = error.response;

        if (res.data.error.error =="Unauthenticated" && !window.location.href == "/login"){
            window.location.href = "/login";
        }

        if  (res.config.url != "/auth/login"){
            if (res.status == 401) {
                window.location.href = "/login";
            }
        }
        return Promise.reject(error);
    }
);

