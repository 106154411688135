import React, {useState} from 'react';
import DropdownContainer from "../../Utility/DropdownContainer";
import CustomSpinner from "../../Utility/CustomSpinner";
import classNames from "classnames";

const MoreBtn = ({options,size=24,isTop,lowerHight}) => {
    const [isDropOpen,setIsDropOpen]=useState(false)

    //for counting if all elements are hidden or not
    let hiddenCount = 0
    options.map(item=>item.isHidden && hiddenCount++)

    return (
        <div className={classNames(
            "more-btn flex gap-1 relative ",
            hiddenCount == options.length ? "hidden" :""
        )}>
            <div className={"pl-2 cursor-pointer"} onClick={()=> {
                setIsDropOpen(true)
            }}>
                <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 17C10.897 17 10 17.897 10 19C10 20.103 10.897 21 12 21C13.103 21 14 20.103 14 19C14 17.897 13.103 17 12 17Z" fill="#D9D9D9"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 7C13.103 7 14 6.103 14 5C14 3.897 13.103 3 12 3C10.897 3 10 3.897 10 5C10 6.103 10.897 7 12 7Z" fill="#D9D9D9"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 10C10.897 10 10 10.897 10 12C10 13.103 10.897 14 12 14C13.103 14 14 13.103 14 12C14 10.897 13.103 10 12 10Z" fill="#D9D9D9"/>
                </svg>
            </div>
            {isDropOpen &&
                <DropdownContainer className={classNames("w-[150px]  !right-2 !left-[unset]",isTop?"!top-[-70px]":"!top-[130%]", lowerHight && "!py-0")} setIsDropOpen={setIsDropOpen}>
                    {options?.map((item,index)=>(
                        !item.isHidden &&
                        <button key={index} onClick={item.fn} className={`filter-item `}>
                            {
                                item.mutation?
                                    item.mutation.isLoading?
                                        <div>
                                            <CustomSpinner size={9}/>
                                        </div>
                                        :
                                         item.mutation.isError?
                                             "error"
                                             :
                                             item.mutation.isSuccess?
                                                 setIsDropOpen(false)
                                                 :
                                                 item.name
                                    :
                                    item.name
                            }
                        </button>
                    ))}
                </DropdownContainer>
            }
        </div>
    );
};

export default MoreBtn;
