import React, {useEffect, useRef, useState} from 'react';

const PasswordInput = ({placeholder,value , onChange,name }) => {
    const input = useRef()
    const [eyenOn ,setEyeOn] = useState(false)

    const TogglePasswordType = () =>{
        setEyeOn(!eyenOn)
        eyenOn? input.current.setAttribute('type', "password") :  input.current.setAttribute('type', "text");
    }
    return (
            <div className={"w-full relative"}>
                <input value={value} name={name} onChange={onChange} className={"w-full"} ref={input} placeholder={placeholder}  type="password"/>
                <div onClick={TogglePasswordType} className="eye absolute p-4 right-0 top-0 bottom-0 flex items-center">
                    {      eyenOn  ?
                        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.13314 9.97435C6.65397 9.49602 6.3623 8.84602 6.3623 8.11685C6.3623 6.65602 7.53897 5.47852 8.99897 5.47852C9.72147 5.47852 10.3865 5.77102 10.8573 6.24935" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M11.5868 8.58398C11.3935 9.65898 10.5468 10.5073 9.47266 10.7023" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M4.54518 12.561C3.22268 11.5226 2.10268 10.006 1.29102 8.11513C2.11102 6.21596 3.23852 4.69096 4.56935 3.6443C5.89185 2.59763 7.41768 2.0293 8.99935 2.0293C10.5902 2.0293 12.1152 2.60596 13.446 3.66013" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M15.2053 5.49219C15.7787 6.25385 16.2828 7.13302 16.707 8.11385C15.0678 11.9114 12.1712 14.1989 8.99867 14.1989C8.27951 14.1989 7.57034 14.0822 6.88867 13.8547" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M15.5718 1.54297L2.42676 14.688" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        :
                        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M11.6352 7.04362C11.6352 7.38965 11.567 7.73229 11.4345 8.05196C11.302 8.37163 11.1079 8.66208 10.8632 8.90672C10.6184 9.15137 10.3279 9.34541 10.0082 9.47779C9.68851 9.61016 9.34585 9.67826 8.99982 9.6782C8.65378 9.67815 8.31115 9.60994 7.99148 9.47747C7.67181 9.34499 7.38136 9.15086 7.13671 8.90614C6.64263 8.4119 6.36512 7.74163 6.36523 7.04279C6.36534 6.34394 6.64307 5.67376 7.1373 5.17968C7.63154 4.6856 8.30181 4.40809 9.00065 4.4082C9.6995 4.40831 10.3697 4.68604 10.8638 5.18027C11.3578 5.67451 11.6353 6.34477 11.6352 7.04362V7.04362Z" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.99768 13.129C12.171 13.129 15.0735 10.8473 16.7077 7.04398C15.0735 3.24065 12.171 0.958984 8.99768 0.958984H9.00102C5.82768 0.958984 2.92518 3.24065 1.29102 7.04398C2.92518 10.8473 5.82768 13.129 9.00102 13.129H8.99768Z" stroke="#A6A6A6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    }
                </div>
            </div>
    );
};

export default PasswordInput;
