import React, {useEffect, useState} from "react";
import axios from "axios";

function LinkPreview({ url }) {
    // State to hold the link preview data and loading status
    const [previewData, setPreviewData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(url);
                const data = await response.text();


                const parser = new DOMParser();
                const doc = parser.parseFromString(data, 'text/html');
                const title = doc.querySelector('title')?.textContent || '';
                const description = doc.querySelector('meta[name="description"]')?.getAttribute('content') || '';
                const image = doc.querySelector('meta[property="og:image"]')?.getAttribute('content') || '';

                setPreviewData({ title, description, image });


                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };

        fetchData();
    }, [url]);


    return (
        <div className="box flex flex-col gap-2 md:flex-row md:justify-between md:items-center  p-6 my-3  md:my-6">
            <div className="content grid gap-2 flex-grow">
                {
                    previewData?.title &&
                        <div className={"text-18 font-medium   truncate w-[80%] overflow-hidden "}>
                            {previewData?.title}
                        </div>
                }
                {previewData?.description &&
                    <div className={"text-gray-50 font-medium  truncate w-[80%] overflow-hidden "}>
                        {previewData?.description}
                    </div>
                }

                <div className={"flex gap-2 items-center "}>
                    <span>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.131 7.55469H8.70999C5.83999 7.55469 3.5 9.89469 3.5 12.7647C3.5 15.6257 5.83999 17.9737 8.70999 17.9737H10.131" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M14.869 7.55469H16.29C19.16 7.55469 21.5 9.89469 21.5 12.7647C21.5 15.6257 19.16 17.9737 16.29 17.9737H14.869" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M9.2207 12.7656H15.7801" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </span>
                    <span className={"text-gray-90 text-12"}>
                        {url}
                    </span>
                </div>
            </div>
            {previewData?.image &&
            <div className={"pic mt-4 md:mt-0 rounded-8 w-[159px] h-[87px] shrink-0 bg-white overflow-hidden"}>
                 <img src={previewData.image} className={"w-full h-full object-cover"} alt="Link Preview" />
            </div>
            }
        </div>
    );
}

export default LinkPreview;
