import React, {useState} from 'react';
import classNames from "classnames";
import s from "./styles.module.scss"
import AddNoteContainer from "./AddNoteContainer";
import {useMutation, useQuery} from "react-query";
import axiosClient from "../../../Api/apiClient";
import {useUpdatePost} from "../../../Hooks/useUpdatePost";
import CustomSpinner from "../../../Utility/CustomSpinner";
import img from "./Frame 46484.png";
const NoteContainer = ({isAddNewNoteOpened,setIsAddNewNoteOpened}) => {
    const {data} = useQuery("/notes")
    const notesList=data.resulting_data.data

    const Note = ({text,number,id})=>{
        const {handleDelete,deleteMutation} = useUpdatePost(id,"/notes")


        return(
            <div className={classNames("note_container py-2 px-3 rounded-12   bg-white aspect-square relative",
                s.container)}>
                <div className={"flex justify-between items-center"}>
                    <div className="text-14 !text-gray-100">
                        Note {number}
                    </div>

                    <button onClick={handleDelete}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 6L18 18" stroke="#0D0807" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                            <path d="M18 6L6 18" stroke="#0D0807" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                        </svg>
                    </button>
                </div>
                <div className={"text-black whitespace-pre-wrap mt-5 h-full overflow-auto"}>
                    {deleteMutation.isLoading?
                        <div className={"w-full h-full flex justify-center items-center"}>
                            <CustomSpinner/>
                        </div>
                        :
                        text
                    }
                </div>

                <div className={"fliped-corner bg-gray-90 z-20 absolute w-[10%] h-[10%] bg-black left-0 -bottom-0"}>

                </div>
            </div>
        )
    }

    return (
        <div className={"h-full overflow-auto"}>
            {
                isAddNewNoteOpened || notesList.length  ?
                    <div className="grid overflow-y-auto auto-rows-min overflow-x-hidden  pr-1 pb-4 lg:grid-cols-2 gap-4">
                        {isAddNewNoteOpened &&
                    <AddNoteContainer number={notesList.length + 1} setIsAddNewNoteOpened={setIsAddNewNoteOpened}/>
                    }
                    {[...notesList].reverse().map((note,index)=>{
                        return(
                            <Note key={note.id} id={note.id} number={notesList.length - index} text={note.note}/>
                        )
                    })}

                </div>
                    :
                    <div className={"text-gray-90 flex items-center w-full justify-center flex-col h-full gap-4 font-medium text-18 text-center"}>
                        <img src={img} alt=""/>
                        <div className={"max-w-[450px]"}>To add a new note <br/>
                            please press the "Add New Note"button.
                        </div>
                    </div>
            }


        </div>


    );
};

export default NoteContainer;
