import axiosClient from "../../../../Api/apiClient";

export const uploadSingleFile =(item)=>{

        const formData = new FormData();
        formData.append("attachable_type", "messages");
        formData.append("attachment", item);
        return new Promise((resolve,reject)=>{
            axiosClient.post(`/attachments`, formData,
                {headers: {"Content-Type": "multipart/form-data"}}
            ).then((res)=>resolve(res.data.resulting_data.id)).catch((err)=>{
               reject(err)
            })
        })

}
