import React from 'react';
import {Link, NavLink} from "react-router-dom";
import classNames from "classnames";

const MoreLink = ({title,children,href}) => {
    return (
        <NavLink to={href}  className={({isActive})=>(classNames(
            "p-2 gap-3 flex items-center rounded-[4px]",
            isActive && "active-link"
        ))}>
            {children}
            {title}
        </NavLink>
    );
};

export default MoreLink;
