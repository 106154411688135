import React from 'react';
import {useMutation, useQueryClient} from "react-query";
import axiosClient from "../Api/apiClient";

const UsePinThread = () => {
    const queryClient = useQueryClient()
    const pinMutation = useMutation({
        mutationFn:(id)=>axiosClient.patch(`/threads/${id}/pin`),
        onSuccess:()=>{
            queryClient.invalidateQueries({ queryKey: ['/threads'] });
        }
    })
    const unpinMutation = useMutation({
        mutationFn:(id)=>axiosClient.patch(`/threads/${id}/unpin`),
        onSuccess:()=>{
            queryClient.invalidateQueries({ queryKey: ['/threads'] });
        }
    })

    return {pinMutation,unpinMutation}
};

export default UsePinThread;
