import React,{Suspense} from 'react';
import classNames from "classnames";
import s from "./styles.module.scss"
import MobilePinnedMainHeader from "../../../Sections/MobilePinnedMainHeader";
import {useLocation} from "react-router-dom";
import {useQuery} from "react-query";
import {format} from "date-fns";
import PageTitle from "../../../Components/PageTitle/PageTitle";
const TradingStrategyDetails = () => {
    PageTitle("Trading strategie")

    const location = useLocation()

    const InfoContent=()=>{
        const {data} = useQuery(location.pathname)
        const infoData = data.resulting_data
        return(
            <>
                <div className="first-line pb-6 border-b-1 border-brown-100 flex flex-col md:flex-row md:justify-between md:items-center">
                    <h3 className={"text-32 font-bold"}>
                        {infoData.title}
                    </h3>

                    <div className={"mt-4 md:mt-0"}>
                        <div className={"flex gap-3 items-center"}>
                            <div className="circle w-8 h-8 rounded-full bg-white overflow-hidden">
                                <img src={infoData.author.profile_image_url? infoData.author.profile_image_url : "/media/defaultAvatar.png"} alt=""/>
                            </div>
                            <div className="content">
                                {infoData.author.name}
                            </div>
                        </div>
                        <div className={"mt-4 text-gray-90 text-12"}>
                            {format(new Date(infoData.created_at), 'MMMM dd, yyyy  hh:mm aaaa')}

                        </div>
                    </div>
                </div>
                <div className={"content py-6"}>
                    <div className={"max-w-[580px] w-full bg-white rounded-8 aspect-[1.5] overflow-hidden"}>
                        <img src={infoData.cover_file_url} className={"w-full h-full object-cover"} alt=""/>
                    </div>
                    <div className={"text-gray-50 text-14 mt-8"}>
                        {infoData.description}
                    </div>
                </div>
            </>
        )
    }
    return (
        <div className={classNames(s.container,"flex flex-col h-full")}>
            <MobilePinnedMainHeader title={"Trading strategy"} hasBackBtn/>
            <div className={"flex-grow pad-layout py-8 overflow-auto"}>
                <Suspense fallback={"Loading..."}>
                    <InfoContent/>
                </Suspense>
            </div>
        </div>
    );
};

export default TradingStrategyDetails;
