import {useMutation, useQueryClient} from "react-query";
import axios from "axios";
import axiosClient from "../Api/apiClient";

export  const useEmojiAddAndRemover =(messageId,threadId)=>{
    const queryClient= useQueryClient();
    const reactMutation = useMutation({
        mutationFn : (emoji)=> axiosClient.post(`/messages/${messageId}/reacts`,{
            "emoji" : emoji
        }),
        onSuccess:()=>{
            queryClient.invalidateQueries({ queryKey: [`/threads/${threadId}/messages`] });
        }
    })
    const submitEmoji =(emoji)=>{
        reactMutation.mutate(`${emoji}`)
    }

    return {reactMutation,submitEmoji};
}
