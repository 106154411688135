import React, {useState} from 'react';
import classNames from "classnames";

const SearchThreadInput = ({value,setValue}) => {
    const [isOpen,setIsOpen]=useState(false)
    return (
        <div className={classNames("input-box   w-10 !bg-brown-90 relative h-10 border-2 border-brown-110 rounded-8 overflow-hidden cursor-pointer",
            isOpen && "opened")}>
            <div onClick={()=>setIsOpen(true)} className={isOpen? "":"w-full h-full"}>
                <svg  className={"absolute left-2 top-0 bottom-0 m-auto"} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.7981 18.5551C15.06 18.5551 18.5151 15.1001 18.5151 10.8381C18.5151 6.57611 15.06 3.12109 10.7981 3.12109C6.53607 3.12109 3.08105 6.57611 3.08105 10.8381C3.08105 15.1001 6.53607 18.5551 10.7981 18.5551Z" stroke="#A6A6A6" stroke-width="1.44" stroke-linecap="square"/>
                    <path d="M16.0674 16.5195L20.4387 20.8795" stroke="#A6A6A6" stroke-width="1.44" stroke-linecap="square"/>
                </svg>
            </div>
            <>
                <input value={value} onInput={(e)=>{setValue(e.target.value)}} className={"input-search w-full h-full bg-transparent px-10 border-none rounded-none"} placeholder={"Search here..."} type="text"/>
                <button onClick={()=>{
                    setValue("")
                    setIsOpen(false)}
                } className={"cansel absolute flex right-3 top-0 bottom-0 items-center text-gray-50 opacity-60 text-12 m-auto"}>
                    Cancel
                </button>
            </>
        </div>

    );
};

export default SearchThreadInput;
