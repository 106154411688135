import React from 'react';
import s from "./styles.module.scss"

const PopupContainer = ({children}) => {
    return (
        <div className={s.container}>
            <div className="white"></div>
            <div className={"bg-black/80 flex justify-center items-center p-8 h-full w-full "} >
                {children}
            </div>
        </div>

    );
};

export default PopupContainer;
