import React from 'react';
import {
    ActivityBox,
    AdminSessionNotif, AdminSessionStatusNotif,
    OwnerSessionActivityBox,
    RejectedSessionNotif,
    SessionStatusNotif
} from "./ActivityBoxes";
import courseIcon from "./Icons/Courses.svg";
import movieIcon from "./Icons/movie-2-line 1.svg"
import profileIcon from "./Icons/Profile 1.svg"
import starIcon from "./Icons/Star 1.svg"
import usdtIcon from "./Icons/USDC.svg"
import tikIcon from "./Icons/Tick Square 1.svg"
import mentionIcon from "./Icons/mention.svg"
import {formatDistance} from "date-fns";
import {Link} from "react-router-dom";
import {useGetProfile} from "../../Hooks/useGetProfile";
const RenderNotifsByType = ({data:i}) => {
    const me = useGetProfile()
    switch (i.type){
        case "NEW_SESSION":
            return ""
            break;
        case "OWNER_NEW_SESSION":
            return <AdminSessionNotif data={i}/>
              break;
        case "SESSION_REJECTED":
            return <SessionStatusNotif status={0} data={i}/>
              break;
        case "OWNER_SESSION_REJECTED":
            return <AdminSessionStatusNotif status={0} data={i}/>
              break;
        case "SESSION_ACCEPTED":
            return  <SessionStatusNotif status={1} data={i}/>
              break;
        case "OWNER_SESSION_ACCEPTED":
            return  <AdminSessionStatusNotif status={1} data={i}/>
              break;
        case "NEW_COURSE":
            if (i.notifiable.author.id != me.id){
                return (
                    <ActivityBox title={"New courses!"} desc={"A new course has been added to the interface. "} time={i.created_at} iconPath={courseIcon} mainBtnText={"Read course"}  btnLink={`/courses/${i.notifiable.id}`}/>
                )
            }
              break;
        case "ADMIN_NEW_COURSE":
            return <ActivityBox title={" Your course uploaded."} desc={"Your course has  been successfully uploaded to the interface. "} time={i.created_at} iconPath={courseIcon} mainBtnText={"View your course"}  btnLink={`/courses/${i.notifiable.id}`}/>

              break;
        case "NEW_MENTOR":
            return <ActivityBox title={"New mentor!"} desc={"A new mentor has been onboarded to the interface."}  time={i.created_at} iconPath={profileIcon} mainBtnText={"View mentors"}  btnLink={`/mentors`}/>
              break;
        case "ADMIN_NEW_MENTOR":
            return <ActivityBox title={"You are a mentor now."} desc={"Great news! You have been promoted to be a mentor."}  time={i.created_at} iconPath={tikIcon}  />
              break;
        case "NEW_VIDEO":
            if (i.notifiable.owner.id != me.id){
                return (
                    <ActivityBox title={"New videos uploaded to the interface."} desc={"A new mentor has been onboarded to the interface. "}  time={i.created_at} iconPath={movieIcon} mainBtnText={"Watch video"}  btnLink={`/videos/${i.notifiable.id}`}/>
                )
            }
              break;
        case "ADMIN_NEW_VIDEO":
            return <ActivityBox title={"Your video uploaded."} desc={"Your video has  been successfully uploaded to the interface."}  time={i.created_at} iconPath={movieIcon} mainBtnText={"Watch your video"}  btnLink={`/videos/${i.notifiable.id}`}/>
              break;
        case "NEW_STRATEGY":
            if (i.notifiable.author.id != me.id){
                return (
                    <ActivityBox title={"New strategy!"} desc={"A new mentor has been onboarded to the interface."}  time={i.created_at} iconPath={starIcon} mainBtnText={"Read more"}  btnLink={`/trading-strategies/${i.notifiable.id}`}/>
                )
            }
              break;
        case "ADMIN_NEW_STRATEGY":
            return <ActivityBox title={"Your strategy uploaded."} desc={"Your strategy has  been successfully uploaded to the interface."}  time={i.created_at} iconPath={starIcon} mainBtnText={"Read more"}  btnLink={`/trading-strategies/${i.notifiable.id}`}/>
              break;
        case "NEW_BUNDLE":
            if (i.notifiable.owner.id != me.id){
                return (
                    <ActivityBox title={"New cryptos bundle!"} desc={"A new bundle has been added to the interface."}  time={i.created_at} iconPath={usdtIcon} mainBtnText={"View bundles"}  btnLink={`/cryptocurrencies`}/>
                )
            }
              break;
        case "ADMIN_NEW_BUNDLE":
            return <ActivityBox title={"Your bundle uploaded."} desc={"Your bundle  has  been successfully uploaded to the interface."}  time={i.created_at} iconPath={usdtIcon} mainBtnText={"View  bundles"}  btnLink={`/cryptocurrencies`}/>
              break;
        case "REPLY_MESSAGE":
            return <ActivityBox title={"Someone replied your message."} desc={`@${i.notifiable.author.username} has replied to your message.`}  time={i.created_at} iconPath={mentionIcon} mainBtnText={"Open thread"} hasQoute={true} data={i.notifiable} btnLink={`/chat/`}/>
              break;
        default:
            return <div>No notif in this type. contact developers</div>
    }

};

export default RenderNotifsByType;
