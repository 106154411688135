import React from 'react';
import s from "./styles.module.scss"
import classNames from "classnames";
const SessionListSidebar = ({setIsOpened,isOpened}) => {
    // const [tabs,setTabs]=useState("all")

    const SessionBox = ({state= "accepted"})=>{

        return(
            <div className={"sessionBox "}>
                <div className={"font-semibold mb-5"}>
                    Advice on buying bitcoins
                </div>
                <div className={"session-time mb-4 flex text-gray-90 text-12  items-center gap-1 w-fit"}>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.06641 7.271H14.9372" stroke="#A6A6A6" stroke-width="1.275" stroke-linecap="square"/>
                        <path d="M11.6914 2.33984V4.53151" stroke="#A6A6A6" stroke-width="1.275" stroke-linecap="square"/>
                        <path d="M6.3125 2.33984V4.53151" stroke="#A6A6A6" stroke-width="1.275" stroke-linecap="square"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9958 4.1416C14.9958 3.72739 14.66 3.3916 14.2458 3.3916H3.75781C3.3436 3.3916 3.00781 3.72739 3.00781 4.1416V14.9099C3.00781 15.3241 3.3436 15.6599 3.75781 15.6599H14.2458C14.66 15.6599 14.9958 15.3241 14.9958 14.9099V4.1416Z" stroke="#A6A6A6" stroke-width="1.275" stroke-linecap="square"/>
                    </svg>
                    <div>
                        1:30pm – 2:30pm /  2023 19 December
                    </div>
                </div>
                {state===0 &&
                    <div className={"statusBtn pending"}>
                        Pending
                    </div>
                }
                {state===1 &&
                    <div className={"statusBtn accepted"}>
                        Accepted
                    </div>
                }
                {state===2 &&
                    <div className={"statusBtn rejected"}>
                        Rejected
                    </div>
                }

            </div>
        )
    }
    return (
        <div className={classNames(s.container,"absolute flex flex-col z-[1000] -right-full md:-right-[500px] top-0 transition-all duration-500 w-full  md:w-[500px] pt-10 px-4 h-full",isOpened && "!right-0")}>
            <div className="first flex px-4 justify-between items-center w-full mb-9">
                <div className="title text-24 font-semibold text-gray-50 ">
                    Sessions
                </div>
               <div className={"cursor-pointer"} onClick={()=>setIsOpened(false)}>
                   <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <path d="M6 6L18 18" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                       <path d="M18 6L6 18" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                   </svg>
               </div>

            </div>
            <div className="tabs flex px-4 gap-2 mb-6">
                <div className="tab active">
                    All
                </div>
                <div className="tab">
                    Pending
                </div>
                <div className="tab">
                    Accepted
                </div>
                <div className="tab">
                    Rejected
                </div>
            </div>

            <div className="session-list overflow-auto px-4 pb-10 flex-grow gap-3 flex flex-col">
                <SessionBox state={1}/>
                <SessionBox state={0}/>
                <SessionBox state={2}/>
                <SessionBox state={1}/>
                <SessionBox state={0}/>
                <SessionBox state={1}/>
                <SessionBox state={0}/>

            </div>
        </div>
    );
};

export default SessionListSidebar;
