import React, {useEffect, useRef, useState} from 'react';
import ChatBox from "../Chatbox";
import {useInfiniteQuery, useQuery, useQueryClient} from "react-query";
import {useLocation} from "react-router-dom";
import ChatInputBox from "./ChatInputBox";
import {formatDistance} from "date-fns";
import edit from "../../Cryptocurrency/Edit";
import axiosClient from "../../../Api/apiClient";
import {useInView} from "react-intersection-observer";
import {BeatLoader} from "react-spinners";
const ChatArea = () => {

    const threadId = window.location.pathname.split("/")[2]

    const queryClient = useQueryClient();

    const { data:fetchedDataForThread  } = useQuery({
        queryKey:[`/threads/${threadId}`],
        staleTime:100000,
    })
    const threadData = fetchedDataForThread.resulting_data




    const { data : fetchedData ,fetchNextPage,hasNextPage,isFetchingNextPage} = useInfiniteQuery({
        queryKey:[`/threads/${threadId}/messages`],
        queryFn: async ({ pageParam =1 }) => {
            const res = await axiosClient.get(`/threads/${threadId}/messages?page=${pageParam}`)
            return {
                data: [...res.data.resulting_data.data] ,
                page:pageParam,
                last_page:res.data.resulting_data.meta.last_page
            }
        },
        getNextPageParam:(lastPage)=>{
            if (lastPage.page < lastPage.last_page){
                return lastPage.page + 1
            }
        }
    })



    const messages = []

    fetchedData.pages.map(i=>
        i.data?.map(item=>{
            messages.push(item)
        })
    )

    const { ref, inView } = useInView();
    useEffect(()=>{
        if(inView && hasNextPage){
            fetchNextPage()
        }
    },[inView,hasNextPage ])



    const [replayContent,setReplayContent]=useState(null)
    const [editContent,setEditContent]=useState(null)

    useEffect(()=>{
        setReplayContent(null)
        setEditContent(null)
    },[threadId])
    useEffect(()=>{
        if (editContent){
            setReplayContent(null)
        }
    },[editContent])
    useEffect(()=>{
        if (replayContent){
            setEditContent(null)
        }
    },[replayContent])
    return (
        <div className="chat-area-wrapper h-full flex flex-col overflow-hidden  bg-brown-80  ">
            <div className="chat-area h-full overflow-x-hidden flex w-full flex-col-reverse  gap-8 overflow-auto">
                <div className={"w-full order-5   bg-brown-50 px-4 py-6 border-b-1 border-brown-90 flex-col flex gap-4"}>
                    <p className={"text-gray-90 font-medium"}>
                        {threadData.description}
                    </p>
                    <div className={"flex justify-between text-gray-90 opacity-60 text-12 items-center"}>
                        <div>
                            Created by

                            {threadData.author.name}
                        </div>
                        <div>
                            {formatDistance(new Date(threadData.created_at),new Date())} ago
                        </div>
                    </div>
                </div>
                <div className={"pr-5  pb-10 py-8 px-4 flex w-full flex-col-reverse  gap-8 flex-grow "}>

                    {   messages.length == 0 ?
                            <div className={"w-full h-full flex justify-center items-center flex-col"}>
                                <h2>
                                    No messages yet
                                </h2>
                            </div>
                        :
                        messages.map((message,index) =>{
                            return(
                                <ChatBox  setEditContnet={setEditContent} reactions={message.reactions} setReplayContent={setReplayContent} isLast={(index==0)} author_email={message.author.email} username={message.author.username} threadId={threadId} id={message.id}  attachments={message.attachments} imgProfSrc={message.author.profile_image_url} author={message.author} date={message.created_at} msgContent={message.text} parent={message.parent}/>
                            )
                        })
                    }
                    <div className={"h-[1px]"} ref={ref}>
                        {
                          hasNextPage &&  isFetchingNextPage &&
                            <div className={"w-full  flex justify-center pb-8 "}>
                                <BeatLoader color={"#ffffff50"}/>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <ChatInputBox editContent={editContent} setEditContnet={setEditContent} replayContent={replayContent} setReplayContent={setReplayContent} threadId={threadId}/>
        </div>
    );
};

export default ChatArea;
