import React from 'react';
import s from "./styles.module.scss"
import classNames from "classnames";
import emptyImg from "./Frame 46492.png"
import MobilePinnedMainHeader from "../../../Sections/MobilePinnedMainHeader";
import PageTitle from "../../../Components/PageTitle/PageTitle";
const SessionAdmin = () => {
    PageTitle("Sessions")

    const DropDown =()=>{
        return (
            <div>
                <div className={"gap-2 px-3 py-2 w-fit flex items-center text-14 font-medium text-gray-90 rounded-8 border-1 border-brown-110 bg-brown-50"}>
                    Recently

                    <div className={""}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 11.25L9.25 6L14.5 11.25" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        </svg>
                    </div>

                </div>
            </div>
        )
    }

    const SessionBox =({state = 0})=>{
        const File =()=>{
            return(
                <div className={"flex justify-between w-[300px] items-center rounded-8 border-brown-90 bg-brown-80 py-2.5 px-4"}>
                    <div>
                        <div className={"text-14 text-gray-50 font-medium"}>
                            Tech design.pdf
                        </div>
                        <div className={"text-12 text-gray-90"}>
                            200 KB
                        </div>
                    </div>
                    <div>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.88086 20.8811H20.6409V3.12109H2.88086V20.8811Z" stroke="#A6A6A6" stroke-width="1.44" stroke-linecap="round"/>
                            <path d="M11.7598 15.2054V8.07812" stroke="#A6A6A6" stroke-width="1.44" stroke-linecap="square"/>
                            <path d="M15.3583 12.3086L11.7602 15.922L8.16211 12.3086" stroke="#A6A6A6" stroke-width="1.44" stroke-linecap="square"/>
                        </svg>
                    </div>
                </div>
            )
        }
        return(
            <div className={"sessionBox"}>
                <div className={"text-18 flex justify-between itemce pb-4 border-brown-110 border-b-1 font-semibold"}>
                    <div>
                        Advice on buying bitcoins
                    </div>

                    <div>
                        {state===0 &&
                            <div className={"statusBtn pending"}>
                                Pending
                            </div>
                        }
                        {state===1 &&
                            <div className={"statusBtn accepted"}>
                                Accepted
                            </div>
                        }
                        {state===2 &&
                            <div className={"statusBtn rejected"}>
                                Rejected
                            </div>
                        }
                    </div>
                </div>
                <div className={"my-4 text-gray-90 text-14 font-medium"}>
                    Hey ! This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for. You can also click here to go directly to our table of contents.
                </div>
                <div className="files flex gap-3">
                    <File/>
                    <File/>
                </div>
                <div className={"my-4 flex gap-3"}>

                    <div className={"w-fit flex items-center  gap-2 rounded-8 border-brown-90 bg-brown-40 border-1 p-2"}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4802 8.99997C15.4802 12.6784 12.4986 15.66 8.82016 15.66C5.14168 15.66 2.16016 12.6784 2.16016 8.99997C2.16016 5.32149 5.14168 2.33997 8.82016 2.33997C12.4986 2.33997 15.4802 5.32149 15.4802 8.99997Z" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                            <path d="M11.2906 11.1184L8.57617 9.49912V6.00928" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        </svg>
                        <div className={"time"}>
                            12:00 am
                        </div>
                        -

                        <div className={"time"}>
                            12:30 am
                        </div>

                    </div>
                    <div className={"w-fit flex items-center  gap-2 rounded-8 border-brown-90 bg-brown-40 border-1 p-2"}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.88672 7.29614H14.7575" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                            <path d="M11.7773 9.89722H11.7837" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                            <path d="M8.82227 9.89722H8.82872" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                            <path d="M5.86133 9.89722H5.86775" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                            <path d="M11.7773 12.4857H11.7837" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                            <path d="M8.82227 12.4857H8.82872" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                            <path d="M5.86133 12.4857H5.86775" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                            <path d="M11.5137 2.36499V4.55666" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                            <path d="M6.13281 2.36499V4.55666" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8142 4.41663C14.8142 3.86434 14.3665 3.41663 13.8142 3.41663H3.82617C3.27389 3.41663 2.82617 3.86434 2.82617 4.41663V14.6849C2.82617 15.2372 3.27389 15.685 3.82617 15.685H13.8142C14.3665 15.685 14.8142 15.2372 14.8142 14.685V4.41663Z" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        </svg>

                        <div className={"time"}>
                            Aug 5- 22, 2024
                        </div>

                    </div>
                </div>

                <div className={"flex justify-between items-center"}>
                    <div>
                        <div className={"mb-2 text-14 font-medium"}>
                            Meet Link :
                        </div>
                        <div className="input-box">
                            <input type="text" className={"w-[400px]"} placeholder={"Pase your link here"}/>
                        </div>
                    </div>
                    <div className={"btn-wrapper gap-2 flex "}>
                        {state===0 &&
                        <>
                            <div className="btn dark">
                                Reject
                            </div>
                            <div className="btn !font-medium grey-50">
                                Accept
                            </div>
                        </>
                        }
                        {state===1 &&
                        <>
                            <div className="btn dark">
                                Cancel
                            </div>

                        </>
                        }
                    </div>
                </div>
            </div>
        )
    }

    const isEmpty = false;
    return (
        <div className={classNames(s.container,"flex flex-col h-full ")}>
            <MobilePinnedMainHeader title={"Sessions"}/>
            <div className={"flex-grow overflow-hidden flex flex-col pad-layout pt-8"}>
                <div className={"flex mb-6 justify-between items-center"}>
                    <div className={"text-24 font-semibold"}>
                        Request list
                    </div>
                    <DropDown/>
                </div>

                <div className="flex-grow gap-3 pb-6 flex-col pr-1 overflow-auto flex ">
                    {isEmpty ?

                        <div className={"w-full h-full items-center flex flex-col gap-2 text-center justify-center pb-40"}>
                            <img src={emptyImg} className={"w-[130px]"} alt=""/>
                            <div className={"text-gray-90 text-18 font-medium "}>
                                You have no session
                                <br/>
                                found.
                            </div>
                        </div>

                        :
                      <>
                          <SessionBox state={1}/>
                          <SessionBox state={0}/>
                          <SessionBox state={2}/>
                      </>
                    }
                </div>

            </div>

        </div>
    );
};

export default SessionAdmin;
