
export const RoutesSubscriptionText ={
     "sessions": `Streamline your experience with our seamless session management. Our platform offers clear terms on duration, cost, and cancellation, ensuring hassle-free interactions tailored to your needs.`,
     "courses": `Explore our curated collection of courses, designed to elevate your skills and knowledge. From beginner basics to advanced topics, each course offers clarity, depth, and practical insights to fuel your growth.`,
     "mentors": `Connect with experienced mentors who provide personalized guidance and support. Gain valuable advice, insights, and encouragement to navigate your professional journey with confidence.`,
     "videos":`Dive into our library of engaging videos covering diverse topics. From informative tutorials to inspiring talks, each video is crafted to inform and entertain, enhancing your learning experience.`,

    "calendar": `Stay organized and inspired with our intuitive calendar and idea tools. Plan your schedule efficiently and explore creative concepts to spark strategies and productivity.`,

    "news": `Stay informed with our curated news and trends section. Get updates on the latest developments in the crypto industry, empowering you to stay ahead of the curve.`,

    "trading": `Access proven trading strategies to optimize your investment decisions. Our resources provide clarity and guidance, helping you navigate the complexities of financial markets with confidence.`,

    "cryptocurrencies": `Explore different cryptocurrencies with our user-friendly resources. Learn about blockchain technology and digital assets, empowering you to make informed decisions in this evolving landscape.`,

    "faq": `Find answers to common questions and access valuable resources. Our Fsection and resource library offer clarity and support to enhance your learning journey.`
}
