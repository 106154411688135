import React, {useState} from 'react';
import s from "./styles.module.scss";
import MobilePinnedMainHeader from "../../../Sections/MobilePinnedMainHeader";
import useUploadAttachment from "../../../Hooks/useUploadAttachment";
import {useDropzone} from "react-dropzone";
import {useMutation} from "react-query";
import axiosClient from "../../../Api/apiClient";
import CustomSpinner from "../../../Utility/CustomSpinner";
import {Link, useNavigate} from "react-router-dom";
import BtnTextForMutation from "../../../Components/BtnTextForMutation";
import PageTitle from "../../../Components/PageTitle/PageTitle";

const AddStrategy = () => {
    PageTitle("Add trading strategies")

    const [title,setTitle]=useState(null)
    const [desc,setDesc]=useState(null)
    const navigate =useNavigate()

    const {id,mutation:uploadImgMutation} = useUploadAttachment()
    const uploadFile =(file)=>{
        return uploadImgMutation.mutateAsync({attach_type:"trading_strategies",file: file})
    }
    const [addedFile,setAddedFile] = useState(null)
    const { getRootProps, getInputProps} = useDropzone({
        multiple:false,
        onDrop: async files => {
            setAddedFile(files[0])
            await uploadFile(files[0])
        }
    });


    const postMutation = useMutation({
        mutationFn: (data)=>axiosClient.post(`/trading-strategies`, data),
        onSuccess:()=>{
            setTimeout(()=>{
                navigate("/trading-strategies")
            },2000)
        }
    })
    const handleSubmit= async ()=>{
        if(!postMutation.isLoading && !postMutation.isSuccess) {

            const dataToPost = {
                "title": title,
                "description": desc,
                "cover_file_id": id
            }
            await postMutation.mutate(dataToPost)
        }
    }
    return (
        <div className={`${s.container} flex flex-col h-full`}>
            <MobilePinnedMainHeader title={"Trading strategies"}/>
            <div className={"flex-grow  overflow-auto lg:justify-around flex flex-col p-8 py-12"}>
                <div className="title mb-10 text-24 font-semibold">
                    Adding strategy
                </div>
                <div className={"box "}>
                    <div className="left flex-grow-[2] flex flex-col justify-center">
                        <div className="input-box mb-10 flex flex-col gap-3">
                            <label className="label">Title </label>
                            <input value={title} onChange={(e)=>setTitle(e.target.value)} placeholder={"Type here"} type="text"/>
                        </div>
                        <div className="input-box flex flex-col gap-3">
                            <label className="label">Description </label>
                            <textarea value={desc} onChange={(e)=>setDesc(e.target.value)} className={"resize-none h-[200px]"} placeholder={"Enter a description..."} type="text"/>
                        </div>
                    </div>
                    <div className="right flex flex-col h-full justify-center w-full  lg:w-1/2">

                        {
                            addedFile?
                                <div className=" w-[80%] relative ml-auto h-[70%] flex flex-col">
                                    <div className={"box-title font-medium mb-4 "}>Selected cover:</div>
                                    <button onClick={()=>setAddedFile(null)} className={"absolute right-0 top-0 -translate-y-1/2 font-medium text-gray-50 bg-brown-90 rounded-8 px-4 p-2"}> Remove cover </button>

                                    <div className={"h-full overflow-hidden rounded-12 overflow-hidden"}>
                                        <img className={"h-full w-full object-cover "} src={URL.createObjectURL(addedFile)} alt=""/>
                                    </div>
                                </div>
                                    :
                                <div className=" ">
                                    <div className={"box-title font-medium mb-6"}>Upload Cover</div>
                                    <form className="mt-4 w-full">
                                        <div {...getRootProps({className: ' w-full  mb-4 dropzone'})}>
                                            <input {...getInputProps()} />
                                            <div className="upload-box w-full">
                                                <div>
                                                    <svg width="43" height="39" viewBox="0 0 43 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13.5 27.5L21.5 19.5M21.5 19.5L29.5 27.5M21.5 19.5V37.5M37.5 28.9857C39.943 26.9681 41.5 23.9159 41.5 20.5C41.5 14.4249 36.5751 9.5 30.5 9.5C30.063 9.5 29.6541 9.27199 29.4322 8.89548C26.8241 4.46967 22.0089 1.5 16.5 1.5C8.21573 1.5 1.5 8.21573 1.5 16.5C1.5 20.6322 3.17089 24.3742 5.8739 27.0871" stroke="url(#paint0_linear_6438_5903)" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <defs>
                                                            <linearGradient id="paint0_linear_6438_5903" x1="21.5" y1="-18.8684" x2="21.5" y2="19.5" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="white"/>
                                                                <stop offset="1" stop-color="#D9D9D9"/>
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                </div>
                                                <div>
                                                    Click to upload or drag and drop
                                                </div>
                                                <div>
                                                    SVG, PNG, JPG or GIF (max. 800x400px)
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                        }

                    </div>
                </div>
                <div className={"flex flex-col-reverse w-full lg:w-fit lg:flex-row gap-4 ml-auto"}>
                    <Link to={"/trading-strategies"} className="btn w-full lg:w-[170px]">Cancel</Link>
                    <button onClick={handleSubmit} disabled={!id} className="btn grey-50 w-full  lg:w-[200px]">
                        <BtnTextForMutation successText={"Published!"} defaultText={"Publish"} mutation={postMutation}/>
                    </button>
                </div>
            </div>

        </div>
    );
};

export default AddStrategy;
